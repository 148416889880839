import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Typography,
  Row,
  Col,
  Divider,
  message,
  notification,
  Space,
  Input,
  Statistic,
} from "antd";
import { DownloadOutlined, PlusCircleOutlined } from "@ant-design/icons";
import CreateProductModal from "./CreateProductModal";
import { TableActions } from "components/common";
import {
  useInventoryServiceDeleteProduct,
  useInventoryServiceDownloadProductList,
  useInventoryServiceGetProducts,
  useInventoryServiceGetProductsKey,
} from "api/queries";
import queryString from "qs";
import { useQueryClient } from "@tanstack/react-query";
import { ColumnType } from "antd/es/table";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import EditProductModal from "./EditProductModal";
import useFilters, {
  FilterTemplate,
  FilterType,
  IFilterItem,
} from "hooks/UseFilter";
import ability, { Can } from "utils/abilit";
import { useSelector } from "react-redux";

const { Title } = Typography;

const ProductListing: React.FC = () => {
  const [createProductOpen, setCreateProductOpen] = React.useState(false);
  const [editProductOpen, setEditProductOpen] = React.useState(false);
  const [selectedProduct, setSelectedProduct] = React.useState<any>(null);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [sortQuery, setSortQuery] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const nav = useNavigate();
  const company = useSelector((state: any) => state.company);

  const queryClient = useQueryClient();

  const filtersDef: IFilterItem[] = [
    {
      key: "brand",
      label: "Brand",
      type: FilterType.template,
      template: FilterTemplate.BrandSelect,
    },
    {
      key: "productName",
      label: "Product Model Name",
      type: FilterType.Text,
    },
    {
      key: "productCategory",
      label: "Category",
      type: FilterType.template,
      template: FilterTemplate.ProductCategorySelect,
    },
    {
      key: "productSubcategory",
      label: "Sub Category",
      type: FilterType.template,
      template: FilterTemplate.ProductSubcategorySelect,
    },
    {
      key: "productSku",
      label: "SKU",
      type: FilterType.Text,
    },
    {
      key: "models",
      label: "Model Compatibility",
      type: FilterType.template,
      template: FilterTemplate.ProductModelCompatibilitySelect,
    },
    {
      key: "stockLocations",
      label: "Location",
      type: FilterType.template,
      template: FilterTemplate.LocationSelect,
    },
    {
      key: "productBarcode",
      label: "Barcode",
      type: FilterType.Text,
    },
    {
      key: "productWeight",
      label: "Weight",
      type: FilterType.Text,
    },
    {
      key: "productWeightUnit",
      label: "Weight Unit",
      type: FilterType.Text,
    },
    {
      key: "productDimensionUnit",
      label: "Dimension Unit",
      type: FilterType.Text,
    },
    {
      key: "productLength",
      label: "Length",
      type: FilterType.Text,
    },
    {
      key: "productWidth",
      label: "Width",
      type: FilterType.Text,
    },
    {
      key: "productHeight",
      label: "Height",
      type: FilterType.Text,
    },
    {
      key: "productDimension",
      label: "Dimension",
      type: FilterType.Text,
    },
    {
      key: "productPrice",
      label: "Selling Price",
      type: FilterType.Text,
    },
    {
      key: "productCost",
      label: "Buying Price",
      type: FilterType.Text,
    },
    {
      key: "productLowStockAlert",
      label: "Low Stock Alert",
      type: FilterType.Text,
    },
    {
      key: "productIdealStock",
      label: "Ideal Stock",
      type: FilterType.Text,
    },
    {
      key: "productDescription",
      label: "Description",
      type: FilterType.Text,
    },
    {
      key: "productVariants",
      label: "Variants",
      type: FilterType.template,
      template: FilterTemplate.ProductVariantSelect,
    },
    {
      key: "productGrade",
      label: "Product Grade",
      type: FilterType.Select,
      options: [
        { label: "Service Pack", key: "SERVICE_PACK", value: "SERVICE_PACK" },
        { label: "Original", key: "ORIGINAL", value: "ORIGINAL" },
        { label: "Copy A", key: "COPY_A", value: "COPY_A" },
        { label: "Copy AAA", key: "COPY_AAA", value: "COPY_AAA" },
        { label: "Diamond", key: "DIAMOND", value: "DIAMOND" },
        { label: "Platinum", value: "PLATINUM", key: "PLATINUM" },
        { label: "Premium", key: "PREMIUM", value: "PREMIUM" },
      ],
    },
    {
      key: "touchScreenCapability",
      label: "Touch Screen Capability",
      type: FilterType.Select,
      options: [
        { label: "With touch", key: "WITH_TOUCH", value: "WITH_TOUCH" },
        { label: "Without touch", key: "WITHOUT_TOUCH", value: "WITHOUT_TOUCH" },
      ]
    }
  ];

  const { filtersQuery, FilterButton, Filters } = useFilters(filtersDef);

  const {
    data: products,
    isLoading,
    isRefetching,
    isPending,
    refetch,
  } = useInventoryServiceGetProducts(
    {
      pagination: queryString.stringify({ limit, offset: offset * limit }),
      filters: queryString.stringify(filtersQuery),
      sort: sortQuery,
      searchQuery,
    },
    [useInventoryServiceGetProductsKey]
  );

  const { mutate: getDownloadLink, isPending: isGeneratingExcel } =
    useInventoryServiceDownloadProductList({
      onSuccess: (response) => {
        window.open(response.downloadUrl, "_blank");
      },
    });

  const { mutate: deleteProductMutation, isPending: isDeletePending } =
    useInventoryServiceDeleteProduct({
      onSuccess: () => {
        message.success("Product deleted successfully");
        refetch();
      },
      onError: (error: any) => {
        notification.error({
          description:
            error?.body?.message ||
            error?.response?.data?.message ||
            error?.message,
          type: "error",
          message: "Failed to delete product",
        });
      },
    });

  const hadnleOpenCreateProduct = () => {
    setCreateProductOpen(true);
  };

  const hadnleCloseCreateProduct = async (reload?: boolean) => {
    if (reload) {
      await queryClient.invalidateQueries({
        queryKey: [useInventoryServiceGetProductsKey],
      });
    }
    setCreateProductOpen(false);
  };

  const handleOpenEditProductModal = (product: any) => {
    setEditProductOpen(true);
    setSelectedProduct(product);
  };

  const handleCloseEditProductModal = () => {
    setEditProductOpen(false);
    setSelectedProduct(null);
  };

  const handleDeleteProduct = async (id: string) => {
    await deleteProductMutation({
      idProduct: +id,
    });
    refetch();
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (pagination && pagination.current && pagination.pageSize) {
      setOffset((pagination.current || 1) - 1);
      setLimit(pagination.pageSize || 10);
    }

    if (sorter) {
      if (sorter.order) {
        const s: any = {};
        s[sorter.field] = sorter.order === "ascend" ? "asc" : "desc";
        setSortQuery(s);
      } else {
        setSortQuery("");
      }
    }
  };

  const columns: ColumnType<any>[] = [
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
      render: (brand: any) => brand?.brandName || "-",
      sorter: true,
      width: "auto",
    },
    {
      title: "Product Model Name ",
      dataIndex: "productName",
      key: "productName",
      sorter: true,
      width: "auto",
    },
    {
      title: "Category",
      dataIndex: "productCategory",
      key: "productCategory",
      render: (category: any) => category?.productCategoryName || "-",
      sorter: true,
      width: "auto",
    },
    {
      title: "Barcode",
      dataIndex: "productBarcode",
      key: "productBarcode",
      sorter: true,
      width: "auto",
    },
    {
      title: "Sub Category",
      dataIndex: "productSubcategory",
      key: "productSubcategory",
      render: (category: any) => category?.productCategoryName || "-",
      sorter: true,
      width: "auto",
    },
    {
      title: "Buying Price",
      dataIndex: "productCost",
      key: "productCost",
      render: (cost: number) =>
        `${company.currencySymbol} ${(cost || 0).toFixed(2)}`,
      sorter: true,
      width: "auto",
    },
    {
      title: "Selling Price",
      dataIndex: "productPrice",
      key: "productPrice",
      render: (price: number) =>
        `${company.currencySymbol} ${(price || 0).toFixed(2)}`,
      sorter: true,
      width: "auto",
    },
    {
      title: "SKU",
      dataIndex: "productSku",
      key: "productSku",
      sorter: true,
      width: "auto",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_v, _r) => (
        <TableActions
          onView={() => nav(`/inventory/products/${_r.idProduct}`)}
          onEdit={
            ability.can("UPDATE", "PRODUCTS")
              ? () => handleOpenEditProductModal(_r)
              : undefined
          }
          onDeletePopConfirm={
            ability.can("DELETE", "PRODUCTS")
              ? () => handleDeleteProduct(_r.idProduct.toString())
              : undefined
          }
          onDeletePopConfirmMessage="Are you sure?"
          onDeletePopConfirmDescription="This product will be deleted permanently"
          onDeleteLoading={isDeletePending}
        />
      ),
    },
  ];

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: [useInventoryServiceGetProductsKey],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, limit, sortQuery, filtersQuery, searchQuery]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5, delay: 0 }}
      style={{ position: "relative", height: "100%" }}
    >
      <Row gutter={16} style={{ marginBottom: 20 }}>
        <Col span={8}>
          <Statistic
            title="Total Number of Products"
            value={products?.totalProducts || 0}
            loading={isLoading}
            // formatter={formatter}
          />
        </Col>
        <Col span={8}>
          <Statistic
            title="Total Number of Variants"
            value={products?.totalVariants || 0}
            loading={isLoading}
            // formatter={formatter}
          />
        </Col>
      </Row>
      <Row>
        <Col
          xs={24}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title level={2} style={{ margin: 0 }}>
            Products
          </Title>

          <Space>
            {FilterButton}

            <Can I="CREATE" a="PRODUCTS">
              <Button
                type="primary"
                icon={<PlusCircleOutlined />}
                onClick={hadnleOpenCreateProduct}
              >
                Add New
              </Button>
            </Can>
          </Space>
        </Col>

        <Col xs={24}>{Filters}</Col>

        <Col xs={24}>
          <Divider style={{ opacity: 0.5 }} />
        </Col>

        <Col xs={22} style={{ marginBottom: 15 }}>
          <Input.Search
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search"
            style={{ flex: 1 }}
          />
        </Col>

        <Col xs={2} style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            type="primary"
            onClick={() => getDownloadLink()}
            loading={isGeneratingExcel}
            icon={<DownloadOutlined />}
          />
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={products?.items || []}
        loading={isLoading || isPending || isRefetching}
        rowKey={(item) => item.idProduct}
        key={"product-list"}
        pagination={{
          total: products?.total || 0,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: ["10", "20", "30", "40", "50"],
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
        }}
        // pagination={{
        //   current: page,
        //   pageSize: limit,
        //   total: stockData?.total,
        //   onChange: (page) => setPage(page),
        //   onShowSizeChange: (_, limit) => setLimit(limit),
        //   pageSizeOptions: [10, 20, 50, 100],
        //   showSizeChanger: true,
        //   showTotal: (total, range) =>
        //     `${range[0]}-${range[1]} of ${total} items`,
        // }}
        onChange={handleTableChange}
        scroll={{ x: "max-content" }}
      />

      <CreateProductModal
        isOpen={createProductOpen}
        onClose={hadnleCloseCreateProduct}
      />

      <EditProductModal
        isOpen={editProductOpen}
        onClose={handleCloseEditProductModal}
        queryClient={queryClient}
        defaultValues={selectedProduct}
      />
    </motion.div>
  );
};

export default ProductListing;

/*
	•	Inventory Valuation Report: Current value of inventory across locations, considering purchase orders and stock movements.
	•	Stock Movement Report: Track inventory movements between locations to identify usage patterns, replenishment needs, or discrepancies.
	•	Low Stock Alerts: Identify items at or below reorder levels for proactive procurement.
  */
