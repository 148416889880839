import React, { useState } from "react";
import {
  List,
  Typography,
  Row,
  Col,
  Modal,
  Spin,
  DatePicker,
  Input,
  notification,
} from "antd";
import {
  ShoppingCartOutlined,
  DollarCircleOutlined,
  TeamOutlined,
  BarChartOutlined,
  FileOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  useInventoryReportsServiceDownloadCustomerReport,
  useInventoryReportsServiceDownloadInventoryAgingReport,
  useInventoryReportsServiceDownloadInventoryLowStockAlertReport,
  useInventoryReportsServiceDownloadInventoryMovementReport,
  useInventoryReportsServiceDownloadInventoryValuationReport,
  useInventoryReportsServiceDownloadPurchaseOrdersReport,
  useInventoryReportsServiceDownloadVendorSummaryReport,
} from "api/queries";
import dayjs, { Dayjs } from "dayjs";
import VendorSelect from "components/common/VendorSelect";
import CustomerSelect from "components/common/CustomerSelect";

const { RangePicker } = DatePicker;
const { Title, Text } = Typography;

// Define the report categories and their reports.
// For vendor reports, we attach an onClick callback so we can trigger our modal.
const reportCategories = ({
  onClick,
}: {
  onClick: (reportType: string) => void;
}) => ({
  inventory: {
    reports: [
      {
        title: "Inventory Aging Report",
        description:
          "Categorize products based on how long they have been in stock.",
        icon: BarChartOutlined,
        onClick: () => onClick("Inventory Aging Report"),
      },
      {
        title: "Inventory Valuation Report",
        description:
          "Calculate the current value of inventory, including pending purchase orders.",
        icon: DollarCircleOutlined,
        onClick: () => onClick("Inventory Valuation Report"),
      },
      {
        title: "Stock Movement Report",
        description:
          "Track inventory movements between locations to identify usage patterns or discrepancies.",
        icon: ShoppingCartOutlined,
        onClick: () => onClick("Stock Movement Report"),
      },
      {
        title: "Low Stock Alert Report",
        description:
          "Identify items at or below reorder levels for proactive procurement.",
        icon: FileOutlined,
        onClick: () => onClick("Low Stock Alerts"),
      },
    ],
  },
  accounting: {
    reports: [
      {
        title: "Profit and Loss",
        description: "Detailed Profit and Loss statement",
        icon: DollarCircleOutlined,
        // onClick can be added when API is available
        onClick: () => onClick("Profit and Loss"),
      },
      {
        title: "Balance Sheet",
        description: "Overview of company assets and liabilities",
        icon: FileOutlined,
        onClick: () => onClick("Balance Sheet"),
      },
    ],
  },
  vendor: {
    reports: [
      {
        title: "Vendor Summary",
        description: "Overview of vendor purchases",
        icon: TeamOutlined,
        onClick: () => onClick("Vendor Summary"),
      },
      {
        title: "Purchase Orders",
        description: "Track vendor purchase orders",
        icon: ShoppingCartOutlined,
        onClick: () => onClick("Purchase Orders"),
      },
    ],
  },
  customer: {
    reports: [
      {
        title: "Customer Overview",
        description: "Detailed report on customer data",
        icon: UserOutlined,
        onClick: () => onClick("Customer Overview"),
      },
      // {
      //   title: "Sales by Customer",
      //   description: "Track sales per customer",
      //   icon: BarChartOutlined,
      //   onClick: () => onClick("Sales by Customer"),
      // },
    ],
  },
});

const ReportDashboard = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedReport, setSelectedReport] = useState("");
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState<[Dayjs, Dayjs] | null>(null);
  // Extra filter states for vendor reports
  const [vendorIdFilter, setVendorIdFilter] = useState("");
  const [orderStatusFilter, setOrderStatusFilter] = useState("");
  // Extra filter states for customer reports
  const [customerIdFilter, setCustomerIdFilter] = useState("");

  // Inventory report hooks (unchanged)
  const { mutate: downloadInventoryAgingReport } =
    useInventoryReportsServiceDownloadInventoryAgingReport();
  const { mutate: downloadInventoryValuationReport } =
    useInventoryReportsServiceDownloadInventoryValuationReport();
  const { mutate: downloadStockMovementReport } =
    useInventoryReportsServiceDownloadInventoryMovementReport();
  const { mutate: downloadLowStockAlertsReport } =
    useInventoryReportsServiceDownloadInventoryLowStockAlertReport();

  // New vendor report hooks
  const { mutate: downloadVendorSummaryReport } =
    useInventoryReportsServiceDownloadVendorSummaryReport();
  const { mutate: downloadPurchaseOrdersReport } =
    useInventoryReportsServiceDownloadPurchaseOrdersReport();

  //New customer report hooks
  const { mutate: downloadCustomerReport } =
    useInventoryReportsServiceDownloadCustomerReport();

  // General handler for all report clicks.
  // Depending on the report type, we either open the modal (for reports requiring filters)
  // or immediately trigger a download (if no extra filters are needed).
  const handleReportClick = (reportType: string) => {
    switch (reportType) {
      case "Inventory Aging Report":
      case "Inventory Valuation Report":
      case "Stock Movement Report":
        setSelectedReport(reportType);
        setIsModalVisible(true);
        break;
      case "Low Stock Alerts":
        // Immediately trigger download without modal.
        setLoading(true);
        downloadLowStockAlertsReport(undefined, {
          onSettled: () => setLoading(false),
          onSuccess: (data) => {
            if (data?.downloadUrl) {
              window.open(data.downloadUrl, "_blank");
            }
          },
          onError: (error?: any) => {
            notification.error({
              description:
                error?.body?.message ||
                error?.response?.data?.message ||
                error?.message,
              message: "Failed to download report",
            });
          },
        });
        break;
      case "Vendor Summary":
      case "Purchase Orders":
        // For vendor reports, open modal with extra filter inputs.
        setSelectedReport(reportType);
        setIsModalVisible(true);
        break;
      default:
        // For other reports, you can add additional handling as needed.
        setSelectedReport(reportType);
        setIsModalVisible(true);
        break;
    }
  };

  const handleDateRangeChange = (dates: any) => {
    setDateRange(dates);
  };

  const handleModalOk = () => {
    if (!dateRange) {
      notification.error({
        message: "Date range required",
        description: "Please select a valid date range.",
      });
      return;
    }
    setIsModalVisible(false);
    const startDate = dateRange[0].format("YYYY-MM-DD");
    const endDate = dateRange[1].format("YYYY-MM-DD");
    setLoading(true);

    // Dispatch the download based on the selected report.
    switch (selectedReport) {
      case "Inventory Aging Report":
        downloadInventoryAgingReport(
          { startDate, endDate },
          {
            onSettled: () => setLoading(false),
            onSuccess: (data) => {
              if (data?.downloadUrl) {
                window.open(data.downloadUrl, "_blank");
              }
            },
            onError: (error?: any) => {
              notification.error({
                description:
                  error?.body?.message ||
                  error?.response?.data?.message ||
                  error?.message,
                message: "Failed to download report",
              });
            },
          }
        );
        break;
      case "Inventory Valuation Report":
        downloadInventoryValuationReport(
          { startDate, endDate },
          {
            onSettled: () => setLoading(false),
            onSuccess: (data) => {
              if (data?.downloadUrl) {
                window.open(data.downloadUrl, "_blank");
              }
            },
            onError: (error?: any) => {
              notification.error({
                description:
                  error?.body?.message ||
                  error?.response?.data?.message ||
                  error?.message,
                message: "Failed to download report",
              });
            },
          }
        );
        break;
      case "Stock Movement Report":
        downloadStockMovementReport(
          { startDate, endDate },
          {
            onSettled: () => setLoading(false),
            onSuccess: (data) => {
              if (data?.downloadUrl) {
                window.open(data.downloadUrl, "_blank");
              }
            },
            onError: (error?: any) => {
              notification.error({
                description:
                  error?.body?.message ||
                  error?.response?.data?.message ||
                  error?.message,
                message: "Failed to download report",
              });
            },
          }
        );
        break;
      case "Vendor Summary":
        downloadVendorSummaryReport(
          { startDate, endDate, vendorId: vendorIdFilter },
          {
            onSettled: () => setLoading(false),
            onSuccess: (data: any) => {
              if (data?.downloadUrl) {
                window.open(data.downloadUrl, "_blank");
              }
            },
            onError: (error?: any) => {
              notification.error({
                description:
                  error?.body?.message ||
                  error?.response?.data?.message ||
                  error?.message,
                message: "Failed to download vendor summary report",
              });
            },
          }
        );
        break;
      case "Purchase Orders":
        downloadPurchaseOrdersReport(
          {
            startDate,
            endDate,
            vendorId: vendorIdFilter,
            status: orderStatusFilter,
          },
          {
            onSettled: () => setLoading(false),
            onSuccess: (data: any) => {
              if (data?.downloadUrl) {
                window.open(data.downloadUrl, "_blank");
              }
            },
            onError: (error?: any) => {
              notification.error({
                description:
                  error?.body?.message ||
                  error?.response?.data?.message ||
                  error?.message,
                message: "Failed to download purchase orders report",
              });
            },
          }
        );
        break;
      case "Customer Overview":
        downloadCustomerReport(
          { startDate, endDate, customerId: customerIdFilter },
          {
            onSettled: () => setLoading(false),
            onSuccess: (data: any) => {
              if (data?.downloadUrl) {
                window.open(data.downloadUrl, "_blank");
              }
            },
            onError: (error?: any) => {
              notification.error({
                description:
                  error?.body?.message ||
                  error?.response?.data?.message ||
                  error?.message,
                message: "Failed to download customer report",
              });
            },
          }
        );
        break;
      default:
        setLoading(false);
        break;
    }
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div style={{ padding: "40px" }}>
      <Title level={2} style={{ marginBottom: "20px" }}>
        Reports Overview
      </Title>

      {/* Inventory Reports Section */}
      <Spin spinning={loading} tip="Downloading report...">
        <section style={{ marginBottom: "40px" }}>
          <Title level={3}>Inventory Reports</Title>
          <List
            itemLayout="horizontal"
            dataSource={
              reportCategories({ onClick: handleReportClick }).inventory.reports
            }
            renderItem={(report) => (
              <List.Item
                style={{
                  padding: "10px 0",
                  transition: "background 0.3s",
                  cursor: "pointer",
                }}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.background = "#f0f0f0")
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.background = "transparent")
                }
                onClick={report.onClick}
              >
                <List.Item.Meta
                  avatar={
                    <report.icon
                      style={{ fontSize: "24px", color: "#1890ff" }}
                    />
                  }
                  title={
                    <Text style={{ fontSize: "16px" }}>{report.title}</Text>
                  }
                  description={
                    <Text style={{ fontSize: "14px" }}>
                      {report.description}
                    </Text>
                  }
                />
              </List.Item>
            )}
          />
        </section>
      </Spin>

      {/* Accounting Reports Section */}
      {/* <section style={{ marginBottom: "40px" }}>
        <Title level={3}>Accounting Reports</Title>
        <List
          itemLayout="horizontal"
          dataSource={
            reportCategories({ onClick: handleReportClick }).accounting.reports
          }
          renderItem={(report) => (
            <List.Item
              style={{
                padding: "10px 0",
                transition: "background 0.3s",
                cursor: "pointer",
              }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.background = "#f0f0f0")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.background = "transparent")
              }
              onClick={report.onClick}
            >
              <List.Item.Meta
                avatar={
                  <report.icon style={{ fontSize: "24px", color: "#52c41a" }} />
                }
                title={<Text style={{ fontSize: "16px" }}>{report.title}</Text>}
                description={
                  <Text style={{ fontSize: "14px" }}>{report.description}</Text>
                }
              />
            </List.Item>
          )}
        />
      </section> */}

      {/* Vendor Reports Section */}
      <section style={{ marginBottom: "40px" }}>
        <Title level={3}>Vendor/Supplier Reports</Title>
        <List
          itemLayout="horizontal"
          dataSource={
            reportCategories({ onClick: handleReportClick }).vendor.reports
          }
          renderItem={(report) => (
            <List.Item
              style={{
                padding: "10px 0",
                transition: "background 0.3s",
                cursor: "pointer",
              }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.background = "#f0f0f0")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.background = "transparent")
              }
              onClick={report.onClick}
            >
              <List.Item.Meta
                avatar={
                  <report.icon style={{ fontSize: "24px", color: "#faad14" }} />
                }
                title={<Text style={{ fontSize: "16px" }}>{report.title}</Text>}
                description={
                  <Text style={{ fontSize: "14px" }}>{report.description}</Text>
                }
              />
            </List.Item>
          )}
        />
      </section>

      {/* Customer Reports Section */}
      <section style={{ marginBottom: "40px" }}>
        <Title level={3}>Customer Reports</Title>
        <List
          itemLayout="horizontal"
          dataSource={
            reportCategories({ onClick: handleReportClick }).customer.reports
          }
          renderItem={(report) => (
            <List.Item
              style={{
                padding: "10px 0",
                transition: "background 0.3s",
                cursor: "pointer",
              }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.background = "#f0f0f0")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.background = "transparent")
              }
              onClick={report.onClick}
            >
              <List.Item.Meta
                avatar={
                  <report.icon style={{ fontSize: "24px", color: "#eb2f96" }} />
                }
                title={<Text style={{ fontSize: "16px" }}>{report.title}</Text>}
                description={
                  <Text style={{ fontSize: "14px" }}>{report.description}</Text>
                }
              />
            </List.Item>
          )}
        />
      </section>

      {/* Date Range & Filter Modal */}
      <Modal
        title={`Select Date Range for ${selectedReport}`}
        visible={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        okText="Download"
        cancelText="Cancel"
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <RangePicker
              onChange={handleDateRangeChange}
              style={{ width: "100%" }}
            />
          </Col>
          {/* For vendor reports, add extra filter fields */}
          {selectedReport === "Vendor Summary" && (
            <Col span={24}>
              <VendorSelect
                isForm={false}
                onChange={(e) => setVendorIdFilter(e)}
                selectProps={{
                  style: { width: "100%" },
                  placeholder: "Select a vendor (optional)",
                }}
              />
            </Col>
          )}
          {selectedReport === "Purchase Orders" && (
            <>
              <Col span={24}>
                <VendorSelect
                  isForm={false}
                  onChange={(e) => setVendorIdFilter(e)}
                  selectProps={{
                    style: { width: "100%" },
                    placeholder: "Select a vendor (optional)",
                  }}
                />
              </Col>
            </>
          )}

          {selectedReport === "Customer Overview" && (
            <Col span={24}>
              <CustomerSelect
                isForm={false}
                onChange={(e) => setCustomerIdFilter(e)}
                selectProps={{
                  style: { width: "100%" },
                  placeholder: "Select a customer (optional)",
                }}
              />
            </Col>
          )}
        </Row>
      </Modal>
    </div>
  );
};

export default ReportDashboard;
