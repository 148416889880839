import React, { useEffect, useState } from "react";
import { Form, message, Modal, notification } from "antd";
import ClientInformation from "./ClientInformation";
import DeviceInformation from "./DeviceInformation";
import DeviceStatusProblems from "./DeviceStatusProplems";
import {
  useReparationServiceCreateReparationCase,
  useReparationServiceGetChecklistItems,
  useReparationServiceGetChecklistItemsKey,
  useReparationServiceGetReparationCasesKey,
} from "api/queries";
import { useQueryClient } from "@tanstack/react-query";

export interface RepairCaseFormProps {
  isOpen: boolean;
  onClose: () => void;
}

const RepairCaseForm: React.FC<RepairCaseFormProps> = ({ isOpen, onClose }) => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const { data: reparationItemsResponse, isPending: reparationItemsPending } =
    useReparationServiceGetChecklistItems([
      useReparationServiceGetChecklistItemsKey,
    ]);

  const { mutate } = useReparationServiceCreateReparationCase({
    onError: (err: any) => {
      notification.error({
        description:
          err?.body?.message || err?.response?.data?.message || err?.message,
        type: "error",
        message: "Failed to create reparation case",
      });
    },
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: [useReparationServiceGetReparationCasesKey],
      });
      await form.resetFields();
      message.success({
        type: "success",
        content: "Reparation case created successfully",
        duration: 2,
      });
      onClose();
    },
  });

  // Initialize the issues array with state management
  const [issues, setIssues] = useState<any[]>([]);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      mutate({
        formData: {
          firstName: values.firstName,
          lastName: values.lastName,
          primaryPhone: values.primaryPhone,
          secondaryPhone: values.secondaryPhone,
          email: values.email,
          address: values?.address,
          brandId: values.brand,
          model: values.model,
          imeiNumber: values.imeiNumber,
          deviceLockCode: values.deviceLockCode,
          devicePattern: values.devicePattern,
          frontView: values?.frontView?.file,
          backView: values?.backView?.file,
          leftSide: values?.leftSide?.file,
          rightSide: values?.rightSide?.file,
          devicePowerStatus: values.devicePowerStatus,
          deviceOffReason: values.reasonDeviceIsOff,
          testFee: values.testFee,
          testIsRequiredBeforeRepair: values.testRequiredBeforeRepair,
          additionalNotes: values.additionalNotes,
          issueChecklistItems: JSON.stringify(
            issues?.map((issue) => ({
              idChecklistItem: issue.idReparationCaseChecklistItem,
              hasIssue: issue.hasIssue,
              details: issue.details,
            })) || []
          ),
          reportedCaseIssue: "",
        },
      });
    } catch (errorInfo) {
      console.log("Validation failed:", errorInfo);
    }
  };

  useEffect(() => {
    setIssues(
      reparationItemsResponse?.items?.map((issue) => ({
        ...issue,
        hasIssue: false,
        details: "",
      })) || []
    );
  }, [reparationItemsResponse?.success]);

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      width={800}
      okText="Submit"
      cancelText="Cancel"
      onOk={form.submit}
      okButtonProps={{ loading: reparationItemsPending }}
      cancelButtonProps={{ disabled: reparationItemsPending }}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        onFinishFailed={(err) => console.log(err)}
      >
        <ClientInformation form={form} />
        <DeviceInformation form={form} />
        <DeviceStatusProblems
          form={form}
          issues={issues}
          setIssues={setIssues}
        />
      </Form>
    </Modal>
  );
};

export default RepairCaseForm;
