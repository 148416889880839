import React, { useState, useMemo } from "react";
import dayjs from "dayjs";
import {
  Button,
  Card,
  Table,
  Typography,
  Space,
  Row,
  Col,
  DatePicker,
  Segmented,
  Input,
  Collapse,
} from "antd";
import {
  DollarCircleOutlined,
  SearchOutlined,
  PlusOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { useAccountingServiceGetChartOfAccounts, useAccountingServiceGetChartOfAccountsKey } from "api/queries";
import CreateAccountModal from "./CreateAccountModal"; // Import the modal
import EditAccountModal from "./EditAccountModal"; // Import the Edit modal
import QueryString from "qs"; // Import qs for query string serialization
import { useAbility } from "@casl/react";
import { AbilityContext } from "utils/abilit";
import { SubjectKey, PermissionType } from "utils/enums";

const { RangePicker } = DatePicker;
const { Title, Text } = Typography;
const { Panel } = Collapse;

const ChartOfAccountsV2 = () => {
  const [dateRange, setDateRange] = useState<[dayjs.Dayjs, dayjs.Dayjs] | null>(
    null
  );
  const [selectedType, setSelectedType] = useState("ASSET");
  const [searchQuery, setSearchQuery] = useState("");
  const [filters, setFilters] = useState<any>({});
  const [isCreateAccountModalOpen, setIsCreateAccountModalOpen] =
    useState(false); // State for modal visibility
  const [isEditAccountModalOpen, setIsEditAccountModalOpen] = useState(false); // State for edit modal visibility
  const [selectedAccount, setSelectedAccount] = useState<any>(null); // Selected account for editing

  const ability = useAbility(AbilityContext);

  // Date filter handlers
  const handleDateFilter = (range: string) => {
    let startDate: dayjs.Dayjs;
    let endDate: dayjs.Dayjs;

    switch (range) {
      case "today":
        startDate = dayjs().startOf("day");
        endDate = dayjs().endOf("day");
        break;
      case "this_week":
        startDate = dayjs().startOf("week");
        endDate = dayjs().endOf("week");
        break;
      case "this_month":
        startDate = dayjs().startOf("month");
        endDate = dayjs().endOf("month");
        break;
      case "this_year":
        startDate = dayjs().startOf("year");
        endDate = dayjs().endOf("year");
        break;
      default:
        return;
    }

    updateFilters(startDate, endDate);
    setDateRange([startDate, endDate]); // Update the RangePicker value
  };

  const handleCustomDateChange = (dates: [dayjs.Dayjs, dayjs.Dayjs]) => {
    if (dates) {
      const [start, end] = dates.map((d) => d.startOf("day"));
      updateFilters(start, end.endOf("day"));
      setDateRange(dates); // Update the RangePicker value
    }
  };

  const updateFilters = (start: dayjs.Dayjs, end: dayjs.Dayjs) => {
    const newFilters = {
      ...filters,
      createDate: [start.toISOString(), end.toISOString()],
    };
    setFilters(newFilters); // Update the filters state
  };

  const clearFilters = () => {
    setDateRange(null); // Reset the RangePicker
    setFilters({}); // Reset the filters state
  };

  const { data, isLoading } = useAccountingServiceGetChartOfAccounts(
    {
      filters: QueryString.stringify(filters), // Use QueryString.stringify instead of JSON.stringify
      pagination: QueryString.stringify({
        offset: 0,
        limit: 100,
      }), // Example pagination
      sort: QueryString.stringify({}), // Example sort
    },
    [useAccountingServiceGetChartOfAccountsKey]
  );

  const formatCurrency = (value: number) =>
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "CFA",
    }).format(value);

  const filteredData = useMemo(() => {
    if (!data) return null;

    // Flatten all accounts from all segments into a single array
    const allAccounts = Object.values(data).flatMap((segment: any) =>
      Object.values(segment.groups).flatMap((group: any) => group.accounts)
    );

    // Filter accounts based on search query
    return allAccounts.filter((account: any) =>
      account.accountName.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [data, searchQuery]);

  const renderAccountRow = (account: any) => (
    <Table
      key={account.idAccount}
      dataSource={[account]}
      pagination={false}
      showHeader={false}
      rowKey="idAccount"
      style={{ marginBottom: 8 }}
      onRow={() => ({
        style: {
          cursor: "pointer",
          transition: "background-color 0.2s",
        },
        onMouseEnter: (e: any) =>
          (e.currentTarget.style.backgroundColor = "#f0f8ff"),
        onMouseLeave: (e: any) =>
          (e.currentTarget.style.backgroundColor = "transparent"),
      })}
    >
      <Table.Column
        title="Account Name"
        dataIndex="accountName"
        key="accountName"
        fixed="left"
        width={250}
        render={(text: string) => (
          <Space>
            <Text strong>{text}</Text>
            {!account.isDefault && (
              <Button
                type="text"
                icon={<EditOutlined />}
                onClick={() => {
                  setSelectedAccount(account);
                  setIsEditAccountModalOpen(true);
                }}
                disabled={
                  !ability.can(PermissionType.UPDATE, SubjectKey.ACCOUNT)
                }
              />
            )}
          </Space>
        )}
      />
      <Table.Column
        title="Account Number"
        dataIndex="accountNumber"
        key="accountNumber"
        width={150}
        render={(text: string) => <Text type="secondary">{text || "N/A"}</Text>}
      />
      <Table.Column
        title="Balance"
        dataIndex="accountBalance"
        key="accountBalance"
        align="right"
        width={150}
        render={(balance: number) => (
          <Text type={balance < 0 ? "danger" : "success"}>
            {formatCurrency(balance)}
          </Text>
        )}
      />
    </Table>
  );

  const renderCollapsibleAccountGroup = (group: any, groupName: string) => (
    <Panel
      key={groupName}
      header={
        <Space>
          <DollarCircleOutlined style={{ color: "#1890ff" }} />
          <Text strong>{groupName}</Text>
          <Text type="secondary">({group.accounts.length} accounts)</Text>
        </Space>
      }
      extra={<Text strong>{formatCurrency(group.totalBalance)}</Text>}
    >
      {group.accounts.map((account: any) => renderAccountRow(account))}
    </Panel>
  );

  return (
    <div style={{ padding: "24px", maxWidth: "1200px", margin: "0 auto" }}>
      {/* Title */}
      <Title level={2} style={{ textAlign: "center", marginBottom: 24 }}>
        Chart of Accounts
      </Title>

      {/* Account Type Filter */}
      <Row justify="center" style={{ marginBottom: 24 }}>
        <Col xs={24} sm={18} md={12} lg={10}>
          <Segmented
            options={[
              { label: "Assets", value: "ASSET" },
              { label: "Liabilities", value: "LIABILITY" },
              { label: "Equity", value: "EQUITY" },
              { label: "Revenue", value: "REVENUE" },
              { label: "Expenses", value: "EXPENSE" },
            ]}
            value={selectedType}
            onChange={(value) => setSelectedType(value as string)}
            block
            style={{
              backgroundColor: "#f5f5f5",
              borderRadius: 8,
              padding: 8,
            }}
          />
        </Col>
      </Row>

      {/* Search Bar and Date Filters */}
      <Collapse
        bordered={false}
        defaultActiveKey={["1"]} // Start with the filters open
        style={{
          marginBottom: 24,
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
          borderRadius: 8,
        }}
      >
        <Panel header="Search and Filters" key="1">
          <Row gutter={16}>
            {/* Search Bar */}
            <Col span={24} style={{ marginBottom: 16 }}>
              <Input.Search
                placeholder="Search accounts..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                prefix={<SearchOutlined />}
                allowClear
                style={{ width: "100%" }}
              />
            </Col>
            {/* Date Filters */}
            <Col span={24}>
              <Space wrap>
                {/* Predefined Date Ranges */}
                <Button onClick={() => handleDateFilter("today")}>Today</Button>
                <Button onClick={() => handleDateFilter("this_week")}>
                  This Week
                </Button>
                <Button onClick={() => handleDateFilter("this_month")}>
                  This Month
                </Button>
                <Button onClick={() => handleDateFilter("this_year")}>
                  This Year
                </Button>
                {/* Custom Date Range Picker */}
                <RangePicker
                  showTime
                  value={dateRange}
                  onChange={(dates) =>
                    dates &&
                    handleCustomDateChange(dates as [dayjs.Dayjs, dayjs.Dayjs])
                  }
                />
                {/* Clear Filters Button */}
                <Button danger onClick={clearFilters}>
                  Clear Filters
                </Button>
              </Space>
            </Col>
          </Row>
        </Panel>
      </Collapse>

      {/* Add Account Button */}
      <Row justify="end" style={{ marginBottom: 16 }}>
        <Col>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setIsCreateAccountModalOpen(true)}
            disabled={!ability.can(PermissionType.CREATE, SubjectKey.ACCOUNT)}
          >
            Create Account
          </Button>
        </Col>
      </Row>

      {/* Accounts Display */}
      {isLoading ? (
        <Text>Loading...</Text>
      ) : (
        <>
          {searchQuery && filteredData ? (
            // Show filtered results
            filteredData?.length > 0 ? (
              <Card
                title="Search Results"
                style={{
                  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                  borderRadius: 8,
                }}
              >
                {filteredData.map((account: any) => renderAccountRow(account))}
              </Card>
            ) : (
              <Text>No results found for "{searchQuery}"</Text>
            )
          ) : // Show grouped accounts
          data?.[selectedType]?.groups ? (
            <Collapse
              bordered={false}
              defaultActiveKey={Object.keys(data[selectedType].groups)} // Open all panels by default
              style={{
                background: "#fff",
                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              {Object.entries(data[selectedType].groups).map(
                ([groupName, group]) =>
                  renderCollapsibleAccountGroup(group, groupName)
              )}
            </Collapse>
          ) : (
            <Text>No data available for the selected filters</Text>
          )}
        </>
      )}

      {/* Modals */}
      <CreateAccountModal
        isOpen={isCreateAccountModalOpen}
        onClose={() => setIsCreateAccountModalOpen(false)}
      />
      <EditAccountModal
        isOpen={isEditAccountModalOpen}
        onClose={() => setIsEditAccountModalOpen(false)}
        accountName={selectedAccount?.accountName || ""}
        accountId={selectedAccount?.idAccount || ""}
      />
    </div>
  );
};

export default ChartOfAccountsV2;
