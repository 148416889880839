import { FC, useEffect, useLayoutEffect, useState } from "react";
import {
  Modal,
  Row,
  Col,
  Form,
  Typography,
  InputNumber,
  notification,
} from "antd";
import { useForm } from "antd/es/form/Form";
import PaymentAccountSelect from "components/common/PaymentAccountSelect";
import {
  useReparationServiceCreateRcPayment,
  useReparationServiceGetReparationCaseKey,
} from "api/queries";
import { useQueryClient } from "@tanstack/react-query";
import UserPaymentAccountSelect from "components/common/UserPaymentAccountSelect";

interface RecordRCPaymentProps {
  open: boolean;
  onCancel: () => void;
  onOk: () => void;
  onClose: () => void;
  remainingAmount: number;
  paidAmount: number;
  invoiceTotal: number;
  caseId: number;
}

export const RecordRCPayment: FC<RecordRCPaymentProps> = ({
  open,
  onCancel,
  onClose,
  invoiceTotal,
  paidAmount,
  remainingAmount,
  caseId,
}) => {
  const [calculateRemainingAmount, setCalculateRemainingAmount] =
    useState(remainingAmount);
  const [form] = useForm();
  const { mutate, isPending } = useReparationServiceCreateRcPayment();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!open) {
      form.resetFields();
    }
  }, [open]);

  const handleSubmit = (values: any) => {
    mutate(
      { id: caseId, requestBody: { ...values } },
      {
        onSuccess: () => {
          notification.success({
            message: "Payment Recorded",
            description: "The payment was successfully recorded.",
          });
          queryClient.invalidateQueries({
            queryKey: [useReparationServiceGetReparationCaseKey],
          });
          onClose();
        },
        onError: (error: any) => {
          notification.error({
            description:
              error?.body?.message ||
              error?.response?.data?.message ||
              error?.message ||
              "An unexpected error occurred.",
            type: "error",
            message: "Failed to record payment",
          });
        },
      }
    );
  };

  const handleRemainingAmountChange = (value: any) => {
    let val = remainingAmount - value;
    setCalculateRemainingAmount(val);
  };

  return (
    <Modal
      title="Record Payment"
      open={open}
      onOk={form.submit}
      okButtonProps={{ loading: isPending }}
      cancelButtonProps={{ disabled: isPending }}
      onCancel={onClose}
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        disabled={isPending}
      >
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Typography.Title level={5}>Invoice Total</Typography.Title>
            <Typography.Paragraph underline style={{ marginTop: 0 }}>
              {invoiceTotal}
            </Typography.Paragraph>
          </Col>

          <Col span={8}>
            <Typography.Title level={5}>Paid Amount</Typography.Title>
            <Typography.Paragraph underline style={{ marginTop: 0 }}>
              {paidAmount}
            </Typography.Paragraph>
          </Col>

          <Col span={8}>
            <Typography.Title level={5}>Remaining Amount</Typography.Title>
            <Typography.Paragraph underline style={{ marginTop: 0 }}>
              {calculateRemainingAmount}
            </Typography.Paragraph>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item name="account" required label="Payment Account">
              <UserPaymentAccountSelect isForm={true} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="amount"
              label="Amount"
              rules={[{ required: true }]}
              initialValue={1}
            >
              <InputNumber
                min={0.01}
                max={remainingAmount}
                style={{ width: "100%" }}
                onInput={handleRemainingAmountChange}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
