import { IconTrash } from "@tabler/icons-react";
import { Button, ButtonProps, Col, Popconfirm, Row } from "antd";
import React from "react";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";

interface ITableActions {
  onDelete?: (d?: any) => void;
  onEdit?: (d?: any) => void;
  onView?: (d?: any) => void;
  viewProps?: ButtonProps;
  showLabel?: boolean;
  onDeletePopConfirm?: (d?: any) => void;
  onDeleteLoading?: boolean;
  onDeletePopConfirmMessage?: string;
  onDeletePopConfirmDescription?: string;
  otherActions?: {
    icon?: any;
    onClick?: (d?: any) => void;
    buttonProps?: ButtonProps;
    label?: string;
    display?: boolean;
  }[];
}

export const TableActions: React.FC<ITableActions> = ({
  onDelete,
  onEdit,
  onView,
  viewProps,
  showLabel,
  onDeletePopConfirm,
  onDeleteLoading,
  onDeletePopConfirmMessage,
  onDeletePopConfirmDescription,
  otherActions,
}) => {
  return (
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      {onView && (
        <Col>
          <Button onClick={onView} icon={<EyeOutlined />}>
            {showLabel ? "Open" : ""}
          </Button>
        </Col>
      )}

      {onEdit && (
        <Col>
          <Button onClick={onEdit} icon={<EditOutlined />}>
            {showLabel ? "Edit" : ""}
          </Button>
        </Col>
      )}

      {onDelete && (
        <Col onClick={onDelete}>
          <Button type="primary" danger>
            Delete
          </Button>
        </Col>
      )}

      {onDeletePopConfirm !== undefined && (
        <Col>
          <Popconfirm
            title={onDeletePopConfirmMessage || "Are you sure?"}
            description={
              onDeletePopConfirmDescription || "This will delete permanently"
            }
            onConfirm={() => onDeletePopConfirm()}
            okButtonProps={{ loading: onDeleteLoading }}
            cancelButtonProps={{ disabled: onDeleteLoading }}
          >
            <Button danger icon={<IconTrash className="anticon" size={16} />}>
              {showLabel ? "Delete" : ""}
            </Button>
          </Popconfirm>
        </Col>
      )}

      {otherActions?.filter((btn) => btn.display)?.map((action, index) => {
        return (
          <Col key={index}>
            <Button
              onClick={action.onClick}
              icon={action.icon}
              {...action.buttonProps}
            >
              {action.label}
            </Button>
          </Col>
        );
      })}
    </Row>
  );
};

export default TableActions;
