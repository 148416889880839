import { EyeOutlined } from "@ant-design/icons";
import { Column, Line, Pie } from "@ant-design/plots";
import { Icon } from "@iconify/react";
import { Button, Card, Col, Flex, List, Table, Typography } from "antd";
import { useInventoryReportsServiceGetDashboardOverview } from "api/queries";
import { useEffect } from "react";
import { OverviewTopCards } from "./OverviewTopCards";
import OverviewSalesVsPurchases from "./OverviewSalesVsPurchases";
import OverviewExpensesPie from "./OverviewExpensesPie";
import { useNavigate } from "react-router-dom";

export const DashboardOverView = ({
  range,
}: {
  range: "TODAY" | "THIS_WEEK" | "THIS_MONTH" | "THIS_YEAR";
}) => {
  const { data, isLoading, refetch } =
    useInventoryReportsServiceGetDashboardOverview({
      range,
    });

  useEffect(() => {
    refetch();
  }, [range]);

  const nav = useNavigate();

  return (
    <>
      <OverviewTopCards data={data} isLoading={isLoading} />

      <OverviewSalesVsPurchases
        purchaseVsSales={data?.purchaseVsSales}
        isLoading={isLoading}
        range={range}
      />

      {/* <Col xs={16}>
        <Card>
          <Typography.Title>Purchase vs. Sale</Typography.Title>
          <Line
            data={[
              {
                month: "may",
                category: "Sale",
                value: 1200,
              },
              {
                month: "june",
                category: "Sale",
                value: 1500,
              },
              {
                month: "july",
                category: "Sale",
                value: 1800,
              },
              {
                month: "august",
                category: "Sale",
                value: 2000,
              },
              {
                month: "september",
                category: "Sale",
                value: 2500,
              },
              {
                month: "may",
                category: "Purchase",
                value: 2000,
              },
              {
                month: "june",
                category: "Purchase",
                value: 300,
              },
              {
                month: "july",
                category: "Purchase",
                value: 2150,
              },
              {
                month: "august",
                category: "Purchase",
                value: 3200,
              },
              {
                month: "september",
                category: "Purchase",
                value: 1200,
              },
            ]}
            yField="value"
            sizeField="value"
            shapeField="smooth"
            legend={{ size: false }}
            colorField="category"
            xField={"month"}
            height={400}
          />
        </Card>
      </Col> */}

      <Col xs={8}>
        <Card>
          <Typography.Title level={4} style={{ marginTop: 0 }}>
            Upcoming payments
          </Typography.Title>
          <List
            style={{ height: 400, maxHeight: 400, overflowY: "auto" }}
            className="demo-loadmore-list"
            itemLayout="horizontal"
            dataSource={data?.upcomingPayments}
            loading={isLoading}
            renderItem={(item) => (
              <List.Item
                actions={[
                  <Button
                    onClick={() => {
                      nav(`/purchases/purchase-orders/${item.purchaseOrderId}`);
                    }}
                    icon={<EyeOutlined />}
                  >
                    View
                  </Button>,
                ]}
              >
                <List.Item.Meta
                  title={item.purchaseOrderNumber}
                  description={item.dueDate}
                />
              </List.Item>
            )}
          />
        </Card>
      </Col>

      <Col xs={10}>
        <OverviewExpensesPie data={data?.expenses} />
      </Col>

      <Col xs={14}>
        <Card>
          <Flex justify="flex-start" align="center">
            <Typography.Title level={4} style={{ marginTop: 0 }}>
              Cashflow
            </Typography.Title>
          </Flex>
          <Column
            data={data?.cashFlow || []}
            xField="label"
            yField="value"
            stack={true}
            colorField="type"
            height={300}
            label={{
              text: "type",
              textBaseline: "bottom",
              position: "inside",
            }}
          />
        </Card>
      </Col>

      <Col xs={12}>
        <Card>
          <Flex justify="flex-start" align="center">
            <Typography.Title level={4} style={{ marginTop: 0 }}>
              Low quantity products
            </Typography.Title>
          </Flex>
          <Table
            key={"low-quantity-products"}
            rowKey={(item) => item.idProduct}
            scroll={{ x: "max-content", y: 400 }}
            columns={[
              { title: "Brand", dataIndex: "brand", width: 100 },
              { title: "Name", dataIndex: "productName", width: 150 },
              { title: "Category", dataIndex: "productCategory", width: 150 },
              {
                title: "Subcategory",
                dataIndex: "productSubcategory",
                width: 150,
              },
              { title: "SKU", dataIndex: "productSku", width: 150 },
              { title: "Quantity", dataIndex: "totalQuantity", width: 100 },
              {
                title: "Action",
                dataIndex: "action",
                render: (key, item) => (
                  <Button
                    type="link"
                    onClick={() => nav(`/inventory/products/${item.idProduct}`)}
                  >
                    View
                  </Button>
                ),
              },
            ]}
            dataSource={data?.lowQuantityProducts || []}
            pagination={false}
          />
        </Card>
      </Col>

      <Col xs={12}>
        <Card>
          <Flex justify="flex-start" align="center">
            <Typography.Title level={4} style={{ marginTop: 0 }}>
              No movement products
            </Typography.Title>
          </Flex>
          <Table
            key={"no-movement-products"}
            rowKey={(item) => item.idProduct}
            scroll={{ x: "max-content", y: 400 }}
            columns={[
              { title: "Brand", dataIndex: "brand", width: 100 },
              { title: "Name", dataIndex: "productName", width: 150 },
              { title: "Category", dataIndex: "productCategory", width: 150 },
              {
                title: "Subcategory",
                dataIndex: "productSubcategory",
                width: 150,
              },
              { title: "SKU", dataIndex: "productSku", width: 150 },
              { title: "Quantity", dataIndex: "totalQuantity", width: 100 },
              {
                title: "Action",
                dataIndex: "action",
                render: (key, item) => (
                  <Button
                    type="link"
                    onClick={() => nav(`/inventory/products/${item.idProduct}`)}
                  >
                    View
                  </Button>
                ),
              },
            ]}
            dataSource={data?.noMovementProducts || []}
            pagination={false}
          />
        </Card>
      </Col>
    </>
  );
};
