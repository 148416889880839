import React, { useRef, useState } from "react";
import { Drawer, Typography, Divider, Row, Col, Button } from "antd";
import { useReactToPrint } from "react-to-print";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
//@ts-ignore
import n2words from "n2words";
import akLogo from "../../../../assets/images/ak-logo.png";
import "./invoice.css";
import { CloseCircleOutlined, PrinterOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;

const translations = {
  en: {
    invoiceDetails: "Invoice Details",
    invoiceNumber: "Invoice #",
    date: "Date",
    notes: "Notes",
    noNotes: "No additional notes.",
    termsAndConditions: "Terms and Conditions",
    total: "Total:",
    discount: "Discount",
    tax: "Tax:",
    grandTotal: "Grand Total:",
    close: "Close",
    print: "Print",
    item: "Item",
    quantity: "Quantity",
    unitPrice: "Unit Price",
    totalPrice: "Total Price",
    loading: "Loading...",
    switchLanguage: "Switch to Français",
    customerId: "Customer Id:",
    terms: [
      "If a phone falls into water or on the ground, it may cause other malfunctions for which we are not responsible.",
      "Motherboard (PCB) repairs are risky regarding data and device booting.",
      "Before handing your phone to the receptionist, remove your SIM card, memory card, and case. We are not responsible for losses.",
      "Please test your phone and check its condition upon retrieval (screen, frame, back cover).",
      "The customer has 60 days to collect their phone. After this period, we are no longer responsible.",
      "YOU HAVE 7 DAYS TO RETURN FOR SCREEN REPLACEMENTS OR REPAIRS IF THE REPLACED PART IS DEFECTIVE.",
      "NOTE: THIS CONDITION APPLIES ONLY AFTER VERIFICATION BY AN AK EXPERT TECHNICIAN.",
      "ERRORS THAT DISQUALIFY THE CUSTOMER FROM WARRANTY CLAIMS:",
      "- IF THE SCREEN IS BROKEN",
      "- THE PHONE WAS DROPPED IN WATER OR HAS WATER DAMAGE",
      "- THE PHONE WAS DISASSEMBLED BY ANOTHER TECHNICIAN",
      "- THERE ARE SCRATCHES OR CRACKS ON THE SCREEN OR PRESSURE DAMAGE",
      "- SCREEN HAS STAINS OR LINES",
      "- OR THE REPAIR MADE TO THE MOTHERBOARD WAS DAMAGED BY THE CUSTOMER",
      "PLEASE TAKE GOOD CARE OF YOUR PHONE AFTER EACH REPAIR, DEAR CUSTOMER.",
      "WARRANTY CONDITIONS FOR ADAPTED SCREENS AT AK: No warranty for adapted screens. Potential issues include color changes, touch failure, screen freezing, and fingerprint sensor malfunction.",
      "REDMI SCREEN REPLACEMENT CONDITION: Fingerprint sensors on Redmi screens may stop working after replacement.",
      "AK SCREEN WARRANTY CONDITIONS.",
      "AK REPAIR CONDITIONS.",
    ],
    amountInWords: (amount: number) => {
      const integerPart = Math.floor(amount);
      const decimalPart = Math.round((amount - integerPart) * 100);
      const dollars = n2words(integerPart, { lang: "en" });
      const cents =
        decimalPart > 0 ? ` and ${n2words(decimalPart, { lang: "en" })}` : "";
      return `${dollars} ${cents}`;
    },
  },
  fr: {
    invoiceDetails: "Détails de la facture",
    invoiceNumber: "Facture #",
    date: "Date",
    notes: "Notes",
    noNotes: "Aucune note supplémentaire.",
    termsAndConditions: "Conditions générales",
    total: "Total :",
    discount: "Remise",
    tax: "Taxe :",
    grandTotal: "Total général :",
    close: "Fermer",
    print: "Imprimer",
    item: "Article",
    quantity: "Quantité",
    unitPrice: "Prix unitaire",
    totalPrice: "Prix total",
    loading: "Chargement...",
    switchLanguage: "Passer à English",
    customerId: "Identifiant client :",
    terms: [
      "Lorsqu'un téléphone tombe dans l'eau ou par terre, cela peut provoquer d'autres pannes dont nous ne sommes pas responsables.",
      "Les réparations de la carte mère (plaquette) sont risquées en ce qui concerne les données et l'allumage.",
      "Avant de remettre votre téléphone à la réceptionniste, assurez-vous de retirer vos puces, votre carte mémoire et le fourreau. Nous ne sommes pas responsables des pertes.",
      "Nous vous prions de bien tester votre portable et de vérifier l'état au retrait (écran-contour-coque).",
      "Le client dispose de 60 jours pour retirer son téléphone. Passé ce délai, nous ne sommes plus responsables du téléphone.",
      "VOUS DISPOSER DE 7 JOUR POUR NOUS FAIT UN RETOUR POUR TOUT LES REMPLACEMENT D’ECRAN ET DE REPARATION SI LA PIECE REMPLACER EST DEFFAILLANTE.",
      "ATTENTION: CETTE CONDITION NE S'APPLIQUERA QUE PAR LA VÉRIFICATION D'UN TECHNICIEN EXPERT DE AK.",
      "LES ERREURS QUI DISQUALIFIERONT LE CLIENT DE LA CONDITION DE PRISE EN CHARGE:",
      "- SI L'ÉCRAN EST CASSÉ",
      "- LE TELEPHONE EST TOMBE DANS L'EAU OU QU’IL Y A TRACE D’EAU",
      "- LE TELEPHONE A ETE DEMONTER PAR UN AUTRE TECHNICIEN",
      "- S’IL Y A EU DES RAILLURE DES FISSURES SUR L’ECRAN OU DE LA PRESSION",
      "- DES TACHES OU DES TRAITS DANS L’ECRAN",
      "- OU QUE LA RÉPARATION QUI A ÉTÉ FAITE SUR LA PLAQUETTE N'A PAS ÉTÉ ENDOMMAGÉE PAR LE CLIENT.",
      "DONC VEUILLEZ S’IL VOUS PLAIT PRENDRE SOIN DE VOTRE TELEPHONE APRES CHAQUE REPARATION CHERS CLIENT.",
      "CONDITION DE GARANTIE SUR ECRAN ADAPTÉE CHEZ AK: Il n'y a pas de garantie pour les écrans adaptés, car ils peuvent rencontrer des problèmes. La couleur de l'écran peut changer, le tactile peut arrêter de fonctionner après un certain temps, l'écran peut se planter et l’empreinte peut ne plus passer.",
      "CONDITION DE REMPLACEMENT ECRAN REDMI: Il se peut que l’empreintes digitales des écrans Redmi ne fonctionnent plus après le changement de l'écran.",
      "CONDITION DE GARANTIE D’ECRAN CHEZ AK.",
      "LES CONDITIONS DE REPARATION CHEZ AK.",
    ],
    amountInWords: (amount: number) => {
      const integerPart = Math.floor(amount);
      const decimalPart = Math.round((amount - integerPart) * 100);
      const euros = n2words(integerPart, { lang: "fr" });
      const centimes =
        decimalPart > 0 ? ` et ${n2words(decimalPart, { lang: "fr" })}` : "";
      return `${euros} ${centimes}`;
    },
  },
};

type Language = "en" | "fr";

const InvoicePriceDrawer: React.FC<{
  open: boolean;
  onClose: () => void;
  data: any;
}> = ({ open, onClose, data }) => {
  const [language, setLanguage] = useState<Language>("en");
  const contentRef = useRef<HTMLDivElement>(null);
  const company = useSelector((state: any) => state.company);

  const handlePrint: any = useReactToPrint({
    contentRef,
    documentTitle: `Invoice-${data?.invoiceNumber || "invoice"}`,
  });

  if (!data || !data.customer) {
    return <div></div>;
  }

  const t: any = (key: keyof (typeof translations)["en"]) =>
    translations[language][key] as string | ((amount: number) => string);

  const renderInvoiceItemsTable = () => (
    <table className="invoice-price-drawer__invoice-items-table">
      <thead>
        <tr>
          <th>{t("item")}</th>
          <th>{t("quantity")}</th>
          <th>{t("unitPrice")}</th>
          <th>{t("totalPrice")}</th>
        </tr>
      </thead>
      <tbody>
        {data.invoiceItems.map((item: any) => (
          <tr key={item.idInvoiceItem}>
            <td>
              {item.service?.idService
                ? item.service.serviceName
                : `${item.product.brand.brandName} - ${item.product.productName} - ${item.product.productCategory.productCategoryName} - ${item.product.productSubcategory.productCategoryName} - ${item.product.productSku}`}
            </td>
            <td>{item.quantity}</td>
            <td>{item.unitPrice?.toFixed(2)}</td>
            <td>{item.totalPrice?.toFixed(2)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  const renderTotalsTable = () => (
    <table className="invoice-price-drawer__totals-table">
      <tbody>
        <tr>
          <td>{t("total")}</td>
          <td>{data.invoiceSubtotal?.toFixed(2)}</td>
        </tr>
        <tr>
          <td>
            {t("discount")} ({data.discountScheme}):
          </td>
          <td>-{data.totalDiscountAmount?.toFixed(2)}</td>
        </tr>
        <tr>
          <td>{t("tax")}</td>
          <td>{data.taxAmount?.toFixed(2)}</td>
        </tr>
        <tr>
          <td>{t("grandTotal")}</td>
          <td>{data.invoiceTotalAmount?.toFixed(2)}</td>
        </tr>
        <tr>
          <td colSpan={2}>
            <small>
              {typeof t("amountInWords") === "function"
                ? t("amountInWords")(data.invoiceTotalAmount)
                : ""}
            </small>
          </td>
        </tr>
      </tbody>
    </table>
  );

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("invoiceDetails")}
      placement="bottom"
      height="100%"
      style={{ padding: 0 }}
    >
      <div
        ref={contentRef}
        className="invoice-price-drawer printable-container"
      >
        {/* Header Section */}
        <Row justify="space-between">
          <Col>
            <img src={akLogo} alt="Company Logo" />
          </Col>
          <Col>
            <div style={{ textAlign: "right" }}>
              <Title level={4}>
                {t("invoiceNumber")} {data.invoiceNumber}
              </Title>
              <Text>
                {t("date")}: {dayjs(data.invoiceDate).format("YYYY-MM-DD")}
              </Text>
            </div>
          </Col>
        </Row>

        <Divider />

        {/* Company and Customer Info */}
        <Row gutter={[12, 12]} style={{ justifyContent: "space-between" }}>
          <Col span={10}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Text>{company?.companyPhone}</Text>
              <Text>{company?.companyEmail}</Text>
              <Text>{company?.companyAddress}</Text>
            </div>
          </Col>
          <Col span={12}>
            <div
              style={{
                textAlign: "right",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <Text>
                {t("customerId")} {data.customer.idCustomer}
              </Text>
              <Text>{data.customer.customerName}</Text>
              <Text>{data.customer.customerAddress}</Text>
            </div>
          </Col>
        </Row>

        <Divider />

        {/* Invoice Items */}
        {renderInvoiceItemsTable()}
        {renderTotalsTable()}

        <Divider />

        {/* Notes Section */}
        <Row>
          <Col>
            <Title level={5}>{t("notes")}</Title>
            <Text>{data.notes || t("noNotes")}</Text>
          </Col>
        </Row>

        <Divider />

        <div className="page-break"></div>

        {/* Terms and Conditions */}
        <Row>
          <Col>
            <Title level={5}>{t("termsAndConditions")}</Title>
            <ul className="invoice-price-drawer__terms-and-conditions">
              {translations[language].terms.map((term, index) => (
                <li key={index}>{term}</li>
              ))}
            </ul>
          </Col>
        </Row>
      </div>

      {/* Action Buttons */}
      <div
        style={{
          position: "fixed",
          bottom: 15,
          left: "50%",
          transform: "translateX(-50%)",
          background: "white",
          zIndex: 5,
          padding: "10px 20px",
          boxShadow: "0px 4px 12px rgba(0,0,0,0.1)",
          borderRadius: 10,
        }}
      >
        <Button icon={<CloseCircleOutlined />} onClick={onClose}>
          {t("close")}
        </Button>
        <Button
          icon={<PrinterOutlined />}
          onClick={handlePrint}
          style={{ marginLeft: 10 }}
        >
          {t("print")}
        </Button>
        <Button
          onClick={() => setLanguage(language === "en" ? "fr" : "en")}
          style={{ marginLeft: 10 }}
        >
          {language === "en" ? "Français" : "English"}
        </Button>
      </div>
    </Drawer>
  );
};

export default InvoicePriceDrawer;
