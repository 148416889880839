import { QueryClient } from "@tanstack/react-query";
import { Modal, Button, Input, Form, InputNumber, message } from "antd";
import PaymentAccountSelect from "components/common/PaymentAccountSelect";
import {
  useSalesServiceCreateInvoicePayment,
  useSalesServiceGetInvoiceDetailsKey,
} from "api/queries";
import { IGetInvoiceDetailsResponse } from "api/requests";
import UserPaymentAccountSelect from "components/common/UserPaymentAccountSelect";

export interface ICreateInvoicePaymentModalProps {
  open: boolean;
  onClose: () => void;
  queryClient: QueryClient;
  data: IGetInvoiceDetailsResponse;
}

function CreateInvoicePaymentModal({
  open,
  onClose,
  queryClient,
  data,
}: ICreateInvoicePaymentModalProps) {
  const [form] = Form.useForm();

  const remainingAmount = data.invoiceTotalAmount - (data.totalPaidAmount || 0);

  const { mutate: createInvoicePayment, isPending } =
    useSalesServiceCreateInvoicePayment({
      onSuccess: () => {
        message.success("Payment created successfully");
        queryClient.invalidateQueries({
          queryKey: [useSalesServiceGetInvoiceDetailsKey],
        });
        form.resetFields(); // Reset the form after successful submission
        onClose();
      },
      onError: (error: any) => {
        message.error(error?.body?.message || "Failed to create payment");
      },
    });

  const handleSubmit = (values: {
    account: number;
    amount: number;
    referenceNumber: string;
  }) => {
    if (data.idInvoice && values.amount > 0) {
      if (values.amount > remainingAmount) {
        message.error("Payment amount cannot exceed the remaining balance.");
        return;
      }
      createInvoicePayment({
        requestBody: {
          amount: values.amount,
          account: values.account,
          referenceNumber: values.referenceNumber,
        },
        idInvoice: data.idInvoice,
      });
    } else {
      message.error("Invalid payment details");
    }
  };

  const handleClose = () => {
    form.resetFields(); // Reset the form when modal is closed or canceled
    onClose();
  };

  return (
    <Modal
      open={open}
      destroyOnClose={true}
      onCancel={handleClose}
      title="Create Payment"
      footer={[
        <Button key="cancel" onClick={handleClose}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          onClick={() => form.submit()}
          loading={isPending}
        >
          Submit Payment
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          label="Payment Account"
          name="account"
          rules={[
            { required: true, message: "Please select a payment account" },
          ]}
        >
          <UserPaymentAccountSelect isForm={true} />
        </Form.Item>

        <Form.Item
          label={`Amount (Remaining: ${remainingAmount.toFixed(2)})`}
          name="amount"
          rules={[
            { required: true, message: "Please enter the payment amount" },
            {
              validator: (_, value) =>
                value > remainingAmount
                  ? Promise.reject(
                      new Error("Payment amount cannot exceed remaining balance.")
                    )
                  : Promise.resolve(),
            },
          ]}
        >
          <InputNumber min={0.01} precision={2} style={{ width: "100%" }} />
        </Form.Item>

        <Form.Item label="Reference Number" name="referenceNumber">
          <Input placeholder="Optional Reference Number" />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default CreateInvoicePaymentModal;