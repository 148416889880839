import { Tag } from "antd";
import dayjs from "dayjs";

const PurchaseOrderDeliveryStatusTag = ({
  deliveryStatus,
  deliveryDate,
  status,
}: any) => {
  if (deliveryStatus === "DELIVERED") {
    return <Tag color="green">Delivered</Tag>;
  }

  if (deliveryStatus === "CANCELLED") {
    return <Tag color="red">Cancelled</Tag>;
  }

  if (status === "APPROVED" || status === "PARTIALLY_PAID" || status === "FULLY_PAID") {
    //using dayjs to check if the delivery date is in the future then return the status Processing
    if (dayjs(deliveryDate).isAfter(dayjs(), "day")) {
      return <Tag color="blue">Processing</Tag>;
    }

    //using dayjs to check if the delivery date is today then return the status Scheduled For Delivery
    if (dayjs(deliveryDate).isSame(dayjs(), "day")) {
      return <Tag color="gold">Scheduled For Delivery</Tag>;
    }

    //using dayjs to check if the delivery date is in the past then return the status Delayed
    if (dayjs(deliveryDate).isBefore(dayjs(), "day")) {
      return <Tag color="red">Delayed</Tag>;
    }
  }

  return <Tag color="warning">Draft</Tag>;
};

export default PurchaseOrderDeliveryStatusTag;
