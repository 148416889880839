import React, { useEffect, useState } from "react";
import {
  Table,
  Typography,
  Row,
  Col,
  Divider,
  Space,
  Statistic,
  Input,
  Button,
} from "antd";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import useFilters, {
  FilterTemplate,
  FilterType,
  IFilterItem,
} from "hooks/UseFilter";
import {
  useInventoryServiceGetBarcodes,
  useInventoryServiceGetBarcodesKey,
} from "api/queries";
import PrintBarcodesDrawer from "./PrintBarcodesDrawer";
import qs from "query-string";
import { TableActions } from "components/common";

const { Title } = Typography;

const filtersDef: IFilterItem[] = [
  {
    key: "brand",
    label: "Brand",
    type: FilterType.template,
    template: FilterTemplate.BrandSelect,
  },
  {
    key: "productName",
    label: "Product Model Name",
    type: FilterType.Text,
  },
  {
    key: "productCategory",
    label: "Category",
    type: FilterType.template,
    template: FilterTemplate.ProductCategorySelect,
  },
  {
    key: "productSubcategory",
    label: "Sub Category",
    type: FilterType.template,
    template: FilterTemplate.ProductSubcategorySelect,
  },
  {
    key: "productSku",
    label: "SKU",
    type: FilterType.Text,
  },
  {
    key: "productBarcode",
    label: "Barcode",
    type: FilterType.Text,
  },
];

const ProductBarcodes: React.FC = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [sortQuery, setSortQuery] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  // States for row selection and print counts
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [printCounts, setPrintCounts] = useState<Record<string, number>>({});

  const [isBottomSheetVisible, setIsBottomSheetVisible] = useState(false);

  const nav = useNavigate();
  const queryClient = useQueryClient();

  const { filtersQuery, FilterButton, Filters } = useFilters(filtersDef);

  const { data, isLoading, isRefetching }: any = useInventoryServiceGetBarcodes(
    {
      pagination: qs.stringify({
        offset: (page - 1) * limit,
        limit,
      }),
      filters: filtersQuery,
      sort: sortQuery,
      searchQuery,
    },
    [useInventoryServiceGetBarcodesKey]
  );

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: [useInventoryServiceGetBarcodesKey],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, sortQuery, filtersQuery, searchQuery]);

  // Called when the row selection changes.
  const onSelectChange = (
    newSelectedRowKeys: React.Key[],
    newSelectedRows: any[]
  ) => {
    // Determine which rows are newly selected (not in the previous selectedRowKeys)
    const newlySelectedKeys = newSelectedRowKeys.filter(
      (key) => !selectedRowKeys.includes(key)
    );

    // For each newly selected row, increase its print count.
    setPrintCounts((prev) => {
      const updated = { ...prev };
      newSelectedRows.forEach((row) => {
        if (newlySelectedKeys.includes(row.idProduct)) {
          const currentCount = row.totalPrinted || 0;
          updated[row.idProduct] = currentCount + 1;
        }
      });
      return updated;
    });

    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedItems(newSelectedRows);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (pagination && pagination.current && pagination.pageSize) {
      setPage(pagination.current || 1);
      setLimit(pagination.pageSize || 10);
    }
    if (sorter) {
      if (sorter.order) {
        const s: any = {};
        s[sorter.field] = sorter.order === "ascend" ? "asc" : "desc";
        setSortQuery(s);
      } else {
        setSortQuery("");
      }
    }
  };

  const columns = [
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
      render: (brand: any) => brand?.brandName || "-",
      sorter: true,
      width: "auto",
    },
    {
      title: "Product Model Name ",
      dataIndex: "productName",
      key: "productName",
      sorter: true,
      width: "auto",
    },
    {
      title: "Category",
      dataIndex: "productCategory",
      key: "productCategory",
      render: (category: any) => category?.productCategoryName || "-",
      sorter: true,
      width: "auto",
    },
    {
      title: "Sub Category",
      dataIndex: "productSubcategory",
      key: "productSubcategory",
      render: (category: any) => category?.productCategoryName || "-",
      sorter: true,
      width: "auto",
    },
    {
      title: "Barcode",
      dataIndex: "productBarcode",
      key: "productBarcode",
      sorter: true,
      width: "auto",
    },
    {
      title: "SKU",
      dataIndex: "productSku",
      key: "productSku",
      sorter: true,
      width: "auto",
    },
    {
      title: "Variants",
      dataIndex: "productVariantValues",
      key: "productVariantValues",
      render: (value: any) => {
        if (value?.length) {
          return (
            <div>
              {value
                .filter(
                  (v: any) =>
                    v?.productVariantValue?.productVariantType?.name &&
                    v?.productVariantValue?.name
                )
                .map((v: any) => (
                  <p>
                    {v?.productVariantValue?.productVariantType?.name}:{" "}
                    {v?.productVariantValue?.name}
                  </p>
                ))}
            </div>
          );
        }
        return "";
      },
    },
    {
      title: "Quantity",
      dataIndex: "totalQuantity",
      key: "totalQuantity",
      render: (totalStock: any) =>
        !totalStock || totalStock === 0 || totalStock === "0" ? (
          <span style={{ color: "red" }}>Out of stock</span>
        ) : (
          totalStock
        ),
      sorter: true,
    },
    {
      title: "Total Printed",
      dataIndex: "totalPrinted",
      key: "totalPrinted",
      sorter: true,
      render: (_: any, record: any) => {
        const count =
          printCounts[record.idProduct] !== undefined
            ? printCounts[record.idProduct]
            : record.totalPrinted;
        return count;
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (_: any, record: any) => {
        return (
          <TableActions
            onView={() => nav(`/inventory/products/${record.idProduct}`)}
          />
        );
      },
    },
  ];

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5, delay: 0 }}
      style={{ position: "relative", height: "100%" }}
    >
      <Row gutter={16} style={{ marginBottom: 20 }}>
        <Col span={6}>
          <Statistic
            title="Total unique products"
            value={data?.totalUniqueProducts || 0}
            loading={isLoading}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title="Total quantity"
            value={data?.grandTotalQuantity || 0}
            loading={isLoading}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title="Total not printed"
            value={data?.totalUnGenerated || 0}
            loading={isLoading}
          />
        </Col>
        <Col span={6}>
          <Statistic
            title="Total printed"
            value={data?.grandTotalPrinted || 0}
            loading={isLoading}
          />
        </Col>
      </Row>

      <Row>
        <Col
          xs={24}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title level={2} style={{ margin: 0 }}>
            Barcodes
          </Title>
          <Space>
            {FilterButton}
            {/* Open the bottom sheet (Drawer) with the selected items */}
            <Button onClick={() => setIsBottomSheetVisible(true)}>
              Print Bulk ({selectedItems.length})
            </Button>
          </Space>
        </Col>

        <Col xs={24}>{Filters}</Col>

        <Col xs={24}>
          <Divider style={{ opacity: 0.5 }} />
        </Col>

        <Col xs={24} style={{ marginBottom: 15 }}>
          <Input.Search
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search"
            style={{ flex: 1 }}
          />
        </Col>
      </Row>
      <Row gutter={12}>
        <Table
          columns={columns}
          dataSource={data?.items || []}
          loading={isLoading || isRefetching}
          rowKey={(record, index) => `${record.idProduct}-${index}`}
          pagination={{
            current: page,
            pageSize: limit,
            total: data?.total,
            onChange: (page) => setPage(page),
            onShowSizeChange: (_, limit) => setLimit(limit),
            pageSizeOptions: [2, 5, 7, 10, 20, 50, 100],
            showSizeChanger: true,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
          }}
          onChange={handleTableChange}
          scroll={{ x: "max-content" }}
          style={{ width: "100%" }}
          rowSelection={rowSelection}
        />
      </Row>

      <PrintBarcodesDrawer
        open={isBottomSheetVisible}
        onClose={() => setIsBottomSheetVisible(false)}
        selectedItems={selectedItems}
      />
    </motion.div>
  );
};

export default ProductBarcodes;
