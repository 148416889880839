import {
  Card,
  Col,
  Descriptions,
  Image,
  List,
  Row,
  Skeleton,
  Tag,
  Typography,
} from "antd";
import { IReparationCaseDetails } from "api/requests";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { QueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const { Title } = Typography;

interface IRCDetailsSegmentProps {
  data?: IReparationCaseDetails;
  loading?: boolean;
  queryClient?: QueryClient;
}
export function RCDetailsSegment({ data, loading }: IRCDetailsSegmentProps) {
  const nav = useNavigate();

  const company = useSelector((state: any) => state.company);

  const descriptionItems: any[] = [];

  useEffect(() => {}, [data]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5, delay: 0 }}
      key="details"
    >
      <Row>
        <Col xs={24}>
          <Skeleton loading={!!loading} active>
            <Title level={4} style={{ marginBottom: "16px" }}>
              Reparation Case Details
            </Title>
            <Descriptions bordered column={2}>
              <Descriptions.Item label="Reparation Case ID">
                {data?.idReparationCase}
              </Descriptions.Item>
              <Descriptions.Item label="Status">
                {data?.reparationCaseStatus}
              </Descriptions.Item>
              <Descriptions.Item label="First Name">
                {data?.firstName}
              </Descriptions.Item>
              <Descriptions.Item label="Last Name">
                {data?.lastName}
              </Descriptions.Item>
              <Descriptions.Item label="Primary Phone">
                {data?.primaryPhone}
              </Descriptions.Item>
              <Descriptions.Item label="Secondary Phone">
                {data?.secondaryPhone || "N/A"}
              </Descriptions.Item>
              <Descriptions.Item label="Email">
                {data?.email || "N/A"}
              </Descriptions.Item>
              <Descriptions.Item label="Address">
                {data?.address || "N/A"}
              </Descriptions.Item>
              <Descriptions.Item label="Brand">
                {typeof data?.brand === "object"
                  ? data?.brand.brandName
                  : data?.brand}
              </Descriptions.Item>
              <Descriptions.Item label="Model">{data?.model}</Descriptions.Item>
              <Descriptions.Item label="IMEI Number">
                {data?.imeiNumber}
              </Descriptions.Item>
              <Descriptions.Item label="Device Lock Code">
                {data?.deviceLockCode || "N/A"}
              </Descriptions.Item>
              <Descriptions.Item label="Device Pattern">
                {data?.devicePattern || "N/A"}
              </Descriptions.Item>
              <Descriptions.Item label="Device Power Status">
                {data?.devicePowerStatus || "N/A"}
              </Descriptions.Item>
              <Descriptions.Item label="Device Off Reason">
                {data?.deviceOffReason || "N/A"}
              </Descriptions.Item>
              <Descriptions.Item label="Test Fee">
                {data?.testFee !== undefined
                  ? `${company.currencySymbol}${data?.testFee}`
                  : "N/A"}
              </Descriptions.Item>
              <Descriptions.Item label="Test Required Before Repair">
                {data?.testIsRequiredBeforeRepair ? "Yes" : "No"}
              </Descriptions.Item>
              <Descriptions.Item label="Additional Notes" span={2}>
                {data?.additionalNotes || "N/A"}
              </Descriptions.Item>
              {data?.cancellationReason && (
                <Descriptions.Item label="Cancellation Reason" span={2}>
                  {data?.cancellationReason}
                </Descriptions.Item>
              )}
            </Descriptions>
          </Skeleton>
        </Col>

        <Col xs={24} style={{ margin: "1rem 0" }}>
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <Title level={4} style={{ marginBottom: "16px" }}>
                Device Views
              </Title>
            </Col>

            {data?.backViewDownloadUrl && (
              <Col xs={24} sm={12} md={8} lg={6}>
                <Card
                  hoverable
                  title="Back View"
                  style={{ width: "100%", textAlign: "center" }}
                >
                  <Image
                    src={data?.backViewDownloadUrl}
                    alt="Back View"
                    preview={true}
                    style={{ width: "100%", objectFit: "contain" }}
                  />
                </Card>
              </Col>
            )}

            {data?.leftSideDownloadUrl && (
              <Col xs={24} sm={12} md={8} lg={6}>
                <Card
                  hoverable
                  title="Left Side View"
                  style={{ width: "100%", textAlign: "center" }}
                >
                  <Image
                    src={data?.leftSideDownloadUrl}
                    alt="Left Side View"
                    preview={true}
                    style={{ width: "100%", objectFit: "contain" }}
                  />
                </Card>
              </Col>
            )}

            {data?.rightSideDownloadUrl && (
              <Col xs={24} sm={12} md={8} lg={6}>
                <Card
                  hoverable
                  title="Right Side View"
                  style={{ width: "100%", textAlign: "center" }}
                >
                  <Image
                    src={data?.rightSideDownloadUrl}
                    alt="Right Side View"
                    preview={true}
                    style={{ width: "100%", objectFit: "contain" }}
                  />
                </Card>
              </Col>
            )}

            {data?.frontViewDownloadUrl && (
              <Col xs={24} sm={12} md={8} lg={6}>
                <Card
                  hoverable
                  title="Front View"
                  style={{ width: "100%", textAlign: "center" }}
                >
                  <Image
                    src={data?.frontViewDownloadUrl}
                    alt="Front View"
                    preview={true}
                    style={{ width: "100%", objectFit: "contain" }}
                  />
                </Card>
              </Col>
            )}
          </Row>
        </Col>

        {data?.devicePowerStatus === "ON" && (
          <Col xs={24} style={{ margin: "1rem 0" }}>
            <Title level={4} style={{ marginBottom: "16px" }}>
              Reparation Case Checklist
            </Title>
            <List
              itemLayout="horizontal"
              dataSource={data?.reparationCaseChecklistValues}
              bordered
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    title={
                      typeof item.reparationCaseChecklistItem === "object"
                        ? item.reparationCaseChecklistItem.itemTitle
                        : `Checklist Item ID: ${item.reparationCaseChecklistItem}`
                    }
                    description={
                      item.details || "No additional details provided"
                    }
                  />
                  <Tag color={item?.hasIssue ? "red" : "green"}>
                    {item.hasIssue ? "Issue Found" : "No Issue"}
                  </Tag>
                </List.Item>
              )}
            />
          </Col>
        )}
      </Row>
    </motion.div>
  );
}
