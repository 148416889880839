import { useAbility } from "@casl/react";
import { Select, SelectProps } from "antd";
import { useAccountingServiceGetPaymentAccounts } from "api/queries";
import useAuth from "hooks/UseAuth";
import queryString from "qs";
import { useEffect, useState } from "react";
import { AbilityContext } from "utils/abilit";
import { PermissionType, SubjectKey } from "utils/enums";

interface IUserPaymentAccountSelectProps {
  isForm?: boolean;
  onChange?: (value: any) => void;
  value?: string | number | null;
  items?: any[];
  selectProps?: Partial<SelectProps>;
}

const UserPaymentAccountSelectWithForm: React.FC<
  IUserPaymentAccountSelectProps
> = ({ value, onChange, selectProps, ...rest }) => {
  const [filters, setFilters] = useState("");

  const { data, isLoading, isRefetching, refetch } =
    useAccountingServiceGetPaymentAccounts({
      filters,
      pagination: "",
      sort: "",
    });

  const handleFilter = (value: string) => {
    setFilters(
      value
        ? queryString.stringify({
            accountName: {
              $ilike: `${value}%`,
            },
          })
        : ""
    );
  };

  useEffect(() => {
    refetch();
  }, [filters]);

  return (
    <Select
      value={value}
      onChange={(v) => {
        //@ts-ignore
        onChange(v);
      }}
      showSearch
      allowClear
      loading={isLoading || isRefetching}
      placeholder="Select a payment account"
      notFoundContent={false}
      defaultActiveFirstOption={false}
      filterOption={false}
      options={
        data?.items?.map((_v) => ({
          label: _v.accountName,
          value: _v.idAccount,
          key: `${_v.idAccount}-account-select-payment-account`,
        })) ?? []
      }
      onSearch={handleFilter}
      {...selectProps}
    />
  );
};

const UserPaymentAccountSelectNoForm: React.FC<
  IUserPaymentAccountSelectProps
> = ({ value, onChange, selectProps, ...rest }) => {
  const [filters, setFilters] = useState("");

  const { data, isLoading, isRefetching, refetch } =
    useAccountingServiceGetPaymentAccounts({
      filters,
      pagination: "",
      sort: "",
    });

  const handleFilter = (value: string) => {
    setFilters(
      value
        ? queryString.stringify({
            accountName: {
              $ilike: `${value}%`,
            },
          })
        : ""
    );
  };

  useEffect(() => {
    refetch();
  }, [filters]);

  return (
    <Select
      value={value}
      onChange={(v) => {
        //@ts-ignore
        onChange(v);
      }}
      showSearch
      allowClear
      loading={isLoading || isRefetching}
      placeholder="Select a payment account"
      notFoundContent={false}
      defaultActiveFirstOption={false}
      filterOption={false}
      options={
        data?.items?.map((_v) => ({
          label: _v.accountName,
          value: _v.idAccount,
          key: `${_v.idAccount}-account-select-payment-account`,
        })) ?? []
      }
      onSearch={handleFilter}
      {...selectProps}
    />
  );
};

const UserPaymentAccountSelect: React.FC<IUserPaymentAccountSelectProps> = ({
  isForm,
  value: originalValue,
  onChange,
  ...rest
}) => {
  const ability = useAbility(AbilityContext);
  const [userAccount, setUserAccount] = useState<any>(null);
  const [hasAccountReadPermission, setHasAccountReadPermission] =
    useState(false);

  const auth = useAuth();

  useEffect(() => {
    const readAbility = ability.can(PermissionType.READ, SubjectKey.ACCOUNT);
    const userDetails = auth?.userDetails;
    let accountId = null;

    if (userDetails) {
      try {
        const formattedUserDetails = JSON.parse(userDetails);
        accountId = formattedUserDetails.account;
      } catch (e) {
        console.error("Failed to parse user details", e);
      }
    }

    setHasAccountReadPermission(!!readAbility);
    setUserAccount(accountId);
  }, [ability, auth]);

  useEffect(() => {
    if (!hasAccountReadPermission && userAccount !== null) {
      onChange?.(userAccount); // Force update form value
    }
  }, [hasAccountReadPermission, userAccount, onChange]);

  const shouldDisable = !hasAccountReadPermission && userAccount;

  const selectProps: Partial<SelectProps> = {
    disabled: shouldDisable,
    ...(rest.selectProps || {}),
  };

  const value = shouldDisable ? userAccount : originalValue;

  if (isForm) {
    return (
      <UserPaymentAccountSelectWithForm
        {...rest}
        value={value}
        onChange={onChange}
        selectProps={selectProps}
      />
    );
  }

  return (
    <UserPaymentAccountSelectNoForm
      {...rest}
      value={value}
      onChange={onChange}
      selectProps={selectProps}
    />
  );
};

export default UserPaymentAccountSelect;
