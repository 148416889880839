import { Select, SelectProps } from "antd";
import { useInventoryServiceGetProductSubcategory } from "api/queries";
import { IGetProductCategoryOptionItemsResponse } from "api/requests";
import queryString from "qs";
import { useEffect, useState } from "react";

interface ISubcategorySelectProps {
  isForm?: boolean;
  onChange?: (value: any) => void;
  value?: string | number | null;
  items?: IGetProductCategoryOptionItemsResponse[];
  selectProps?: Partial<SelectProps>;
  parentCategoryId?: number | null;
}

const SubcategorySelectWithForm: React.FC<ISubcategorySelectProps> = ({
  isForm,
  onChange,
  value,
  items,
  selectProps,
  parentCategoryId,
}) => {
  const [filters, setFilters] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const { data, isLoading, isRefetching, refetch } =
    useInventoryServiceGetProductSubcategory({
      filters,
      pagination: queryString.stringify({ limit: 1000, offset: 0 }),
      sort: "",
    });

  const handleFilter = () => {
    const filterObject: any = {};

    filterObject.$and = [];

    if (searchQuery) {
      filterObject.$and.push({
        productCategoryName: {
          $ilike: `${searchQuery}%`,
        },
      });
    }

    if (parentCategoryId) {
      filterObject.$and.push({
        parentCategory: parentCategoryId,
      });
    }

    setFilters(
      Object.keys(filterObject).length > 0
        ? queryString.stringify(filterObject)
        : ""
    );
  };

  useEffect(() => {
    refetch();
  }, [filters]);

  useEffect(() => {
    handleFilter();
  }, [parentCategoryId, searchQuery]);

  return (
    <Select
      value={value}
      onChange={(v) => {
        //@ts-ignore
        onChange(v);
      }}
      showSearch
      allowClear
      loading={isLoading || isRefetching}
      placeholder="Select a category"
      notFoundContent={false}
      defaultActiveFirstOption={false}
      defaultValue={null}
      filterOption={false}
      options={
        data?.items?.map((_v) => {
          return {
            label: _v.productCategoryName,
            value: _v.idProductCategory,
            key:
              _v.idProductCategory + "product-category-select-product-category",
          };
        }) ?? []
      }
      onSearch={(v) => setSearchQuery(v)}
      {...selectProps}
    />
  );
};

const SubcategorySelectNoForm: React.FC<ISubcategorySelectProps> = ({
  isForm,
  onChange,
  value,
  items,
  selectProps,
  parentCategoryId,
}) => {
  const [filters, setFilters] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const { data, isLoading, isRefetching, refetch } =
    useInventoryServiceGetProductSubcategory({
      filters,
      pagination: queryString.stringify({ limit: 1000, offset: 0 }),
      sort: "",
    });

  const handleFilter = () => {
    const filterObject: any = {};

    filterObject.$and = [];

    if (searchQuery) {
      filterObject.$and.push({
        productCategoryName: {
          $ilike: `${searchQuery}%`,
        },
      });
    }

    if (parentCategoryId) {
      filterObject.$and.push({
        parentCategory: parentCategoryId,
      });
    }

    setFilters(
      Object.keys(filterObject).length > 0
        ? queryString.stringify(filterObject)
        : ""
    );
  };

  useEffect(() => {
    refetch();
  }, [filters]);

  useEffect(() => {
    handleFilter();
  }, [parentCategoryId, searchQuery]);

  return (
    <Select
      value={value}
      onChange={(v) => {
        //@ts-ignore
        onChange(v);
      }}
      showSearch
      allowClear
      loading={isLoading || isRefetching}
      placeholder="Select a category"
      notFoundContent={false}
      defaultActiveFirstOption={false}
      defaultValue={null}
      filterOption={false}
      options={
        data?.items?.map((_v) => {
          return {
            label: _v.productCategoryName,
            value: _v.idProductCategory,
            key:
              _v.idProductCategory + "product-category-select-product-category",
          };
        }) ?? []
      }
      onSearch={(value) => setSearchQuery(value)}
      {...selectProps}
    />
  );
};

export default function SubcategorySelect(props: ISubcategorySelectProps) {
  if (props.isForm) {
    return <SubcategorySelectWithForm {...props} />;
  }
  return <SubcategorySelectNoForm {...props} />;
}
