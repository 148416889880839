import React, { useState } from "react";
import { Popover, InputNumber, Button, Space, message } from "antd";
import {
  useInventoryServiceGetInventoryStockKey,
  useInventoryServiceQuickAdjustment,
} from "api/queries";
import { useQueryClient } from "@tanstack/react-query";

interface Location {
  IdLocation: number;
  locationName: string;
  quantity: number;
}

interface EditableLocationsCellProps {
  productId: number;
  locations: Location[];
}

const EditableLocationsCell: React.FC<EditableLocationsCellProps> = ({
  productId,
  locations,
}) => {
  // Local state to track whether the cell is in edit mode and the current input values.
  const [editing, setEditing] = useState(false);
  const [editedValues, setEditedValues] = useState<{ [key: number]: number }>(
    () => {
      const initial: { [key: number]: number } = {};
      locations.forEach((loc) => {
        initial[loc.IdLocation] = loc.quantity;
      });
      return initial;
    }
  );
  const queryClient = useQueryClient();

  // Mutation hook to update inventory for multiple locations at once.
  const { mutate: quickUpdateLocationQuantity, isPending } =
    useInventoryServiceQuickAdjustment({
      onSuccess: async () => {
        message.success("Inventory adjusted successfully");
        await queryClient.invalidateQueries({
          queryKey: [useInventoryServiceGetInventoryStockKey],
          type: "all",
        });
        setEditing(false);
      },
      onError: (error: any) => {
        message.error(`Error adjusting inventory: ${error.message}`);
      },
    });

  // Called when Save is clicked.
  const handleSave = () => {
    // Create an array of update objects for locations where the quantity has changed.
    const updates = Object.entries(editedValues)
      .map(([locId, newQuantity]) => {
        const locationId = Number(locId);
        const originalQuantity = locations.find(
          (loc) => loc.IdLocation === locationId
        )?.quantity;
        if (originalQuantity !== newQuantity) {
          return { productId, locationId, targetQuantity: newQuantity };
        }
        return null;
      })
      .filter((update) => update !== null);

    if (updates.length > 0) {
      // Send the entire array of updates in a single request.
      quickUpdateLocationQuantity({
        requestBody: updates,
      });
    } else {
      message.info("No changes detected.");
    }
    setEditing(false);
  };

  // Content to be shown in the popover.
  const popoverContent = (
    <div>
      {locations.map((loc) => (
        <div key={loc.IdLocation} style={{ marginBottom: 8 }}>
          <span style={{ marginRight: 8 }}>{loc.locationName}:</span>
          <InputNumber
            min={0}
            value={editedValues[loc.IdLocation]}
            onChange={(value) =>
              setEditedValues((prev) => ({
                ...prev,
                [loc.IdLocation]: value ?? 0,
              }))
            }
          />
        </div>
      ))}
      <Space>
        <Button size="small" onClick={() => setEditing(false)}>
          Cancel
        </Button>
        <Button
          type="primary"
          size="small"
          onClick={handleSave}
          loading={isPending}
        >
          Save
        </Button>
      </Space>
    </div>
  );

  return (
    <Popover
      content={popoverContent}
      trigger="click"
      open={editing}
      onOpenChange={(visible) => setEditing(visible)}
    >
      <div style={{ cursor: "pointer" }}>
        {locations
          .map((loc) => `${loc.locationName}: ${loc.quantity}`)
          .join(", ")}
      </div>
    </Popover>
  );
};

export default EditableLocationsCell;
/*
-- 1) Function to extract initials (brand, category)
CREATE OR REPLACE FUNCTION extract_initials(input_text text)
  RETURNS text
  LANGUAGE plpgsql
AS
$$
DECLARE
  words       text[];
  result_code text := '';
  i           int := 1;
BEGIN
  IF input_text IS NULL OR btrim(input_text) = '' THEN
    RETURN 'XXX';  -- Fallback for empty input
  END IF;

  words := regexp_split_to_array(btrim(input_text), E'\\s+');

  -- Single-word case
  IF array_length(words, 1) = 1 THEN
    RETURN UPPER(SUBSTRING(words[1] FROM 1 FOR 3));
  END IF;

  -- Multi-word case: Take initials from each word
  LOOP
    EXIT WHEN i > array_length(words, 1) OR length(result_code) >= 3;
    result_code := result_code || SUBSTRING(words[i] FROM 1 FOR 1);
    i := i + 1;
  END LOOP;

  RETURN UPPER(result_code);
END;
$$;


-- 2) Function to extract special name code
CREATE OR REPLACE FUNCTION extract_custom_name_code(name text)
  RETURNS text
  LANGUAGE plpgsql
AS
$$
DECLARE
  sanitized    text;
  words        text[];
  result_code  text := '';
  i            int := 1;
BEGIN
  IF name IS NULL OR btrim(name) = '' THEN
    RETURN 'PN';  -- Fallback for empty name
  END IF;

  -- 1) Remove non-alphanumeric (and non-space) characters:
  sanitized := regexp_replace(btrim(name), '[^a-zA-Z0-9\s]+', '', 'g');
  -- Example: "Oppo A52 (4G), Spare Parts, Main Flex" becomes
  --          "Oppo A52 4G Spare Parts Main Flex"

  -- 2) Split by whitespace:
  words := regexp_split_to_array(sanitized, E'\\s+');

  -- 3) Single-word case:
  IF array_length(words, 1) = 1 THEN
    -- Take up to first 3 chars from that single word
    RETURN UPPER(SUBSTRING(words[1] FROM 1 FOR 3));
  END IF;

  -- 4) Multi-word case: 
  --    Take the first character of each word until we have 3.
  LOOP
    EXIT WHEN i > array_length(words, 1) OR length(result_code) >= 3;
    result_code := result_code || SUBSTRING(words[i] FROM 1 FOR 1);
    i := i + 1;
  END LOOP;

  RETURN UPPER(result_code);
END;
$$;

-- 3) Sequence for product SKU
CREATE SEQUENCE IF NOT EXISTS product_sku_seq START 1;

-- 4) Sequence for product ID Code
CREATE SEQUENCE IF NOT EXISTS product_id_code_seq START 1;


-- 5) Function to set product SKU
CREATE OR REPLACE FUNCTION set_product_sku()
  RETURNS TRIGGER
  LANGUAGE plpgsql
AS
$$
DECLARE
  v_brand_abbr text;
  v_cat_abbr   text;
  v_name_code  text;
  v_seq        text;
  v_prefix     text := '';
BEGIN
  -- Check if it's a product variant
  IF NEW.ref_id_product IS NOT NULL THEN
    v_prefix := 'VAR-';
  END IF;

  -- Extract brand initials
  IF NEW.ref_id_brand IS NOT NULL THEN
    SELECT extract_initials(b.brand_name)
      INTO v_brand_abbr
      FROM brands b
     WHERE b.id_brand = NEW.ref_id_brand;
  ELSE
    v_brand_abbr := 'BRN';
  END IF;

  -- Extract category initials
  IF NEW.ref_id_product_category IS NOT NULL THEN
    SELECT extract_initials(pc.product_category_name)
      INTO v_cat_abbr
      FROM product_categories pc
     WHERE pc.id_product_category = NEW.ref_id_product_category;
  ELSE
    v_cat_abbr := 'CAT';
  END IF;

  -- Extract special name code
  v_name_code := extract_custom_name_code(NEW.product_name);

  -- Generate sequence number
  v_seq := to_char(nextval('product_sku_seq'), 'FM000000');

  -- Final SKU format: [VAR-]brand-cat-name-code-seq
  NEW.product_sku := v_prefix
                     || v_brand_abbr
                     || '-' || v_cat_abbr
                     || '-' || v_name_code
                     || '-' || v_seq;

  RETURN NEW;
END;
$$;


-- 6) Function to set product ID Code
CREATE OR REPLACE FUNCTION set_product_id_code()
  RETURNS TRIGGER
  LANGUAGE plpgsql
AS
$$
DECLARE
  v_brand_abbr text;
  v_cat_abbr   text;
  v_name_code  text;
  v_seq        text;
  v_prefix     text := '';
BEGIN
  -- Check if it's a product variant
  IF NEW.ref_id_product IS NOT NULL THEN
    v_prefix := 'VAR-';
  END IF;

  -- Extract brand initials
  IF NEW.ref_id_brand IS NOT NULL THEN
    SELECT extract_initials(b.brand_name)
      INTO v_brand_abbr
      FROM brands b
     WHERE b.id_brand = NEW.ref_id_brand;
  ELSE
    v_brand_abbr := 'BRN';
  END IF;

  -- Extract category initials
  IF NEW.ref_id_product_category IS NOT NULL THEN
    SELECT extract_initials(pc.product_category_name)
      INTO v_cat_abbr
      FROM product_categories pc
     WHERE pc.id_product_category = NEW.ref_id_product_category;
  ELSE
    v_cat_abbr := 'CAT';
  END IF;

  -- Extract special name code
  v_name_code := extract_custom_name_code(NEW.product_name);

  -- Generate sequence number
  v_seq := to_char(nextval('product_id_code_seq'), 'FM000000');

  -- Final ID Code format: [VAR-]brand-cat-name-code-seq
  NEW.product_id_code := v_prefix
                         || v_brand_abbr
                         || '-' || v_cat_abbr
                         || '-' || v_name_code
                         || '-' || v_seq;

  RETURN NEW;
END;
$$;


-- 7) Trigger to set product SKU before insert
CREATE TRIGGER trg_set_product_sku
BEFORE INSERT ON products
FOR EACH ROW
EXECUTE PROCEDURE set_product_sku();

-- 8) Trigger to set product ID Code before insert
CREATE TRIGGER trg_set_product_id_code
BEFORE INSERT ON products
FOR EACH ROW
EXECUTE PROCEDURE set_product_id_code();


-- 9) Bulk update existing products
WITH updated_products AS (
  SELECT
    p.id_product,
    CASE 
      WHEN p.ref_id_product IS NOT NULL THEN 'VAR-'  -- Add VAR- prefix for variants
      ELSE ''  -- No prefix for non-variants
    END AS prefix,
    COALESCE(extract_initials(b.brand_name), 'BRN') AS brand_abbr,
    COALESCE(extract_initials(pc.product_category_name), 'CAT') AS cat_abbr,
    extract_custom_name_code(p.product_name) AS name_code,
    nextval('product_sku_seq') AS sku_seq_num,        -- Use nextval for SKU sequence
    nextval('product_id_code_seq') AS id_code_seq_num -- Use nextval for ID code sequence
  FROM products p
  LEFT JOIN brands b ON b.id_brand = p.ref_id_brand
  LEFT JOIN product_categories pc ON pc.id_product_category = p.ref_id_product_category
)
UPDATE products p
SET product_sku = updated_products.prefix
                   || updated_products.brand_abbr
                   || '-' || updated_products.cat_abbr
                   || '-' || updated_products.name_code
                   || '-' || to_char(updated_products.sku_seq_num, 'FM000000'),
    product_id_code = updated_products.prefix
                      || updated_products.brand_abbr
                      || '-' || updated_products.cat_abbr
                      || '-' || updated_products.name_code
                      || '-' || to_char(updated_products.id_code_seq_num, 'FM000000')
FROM updated_products
WHERE p.id_product = updated_products.id_product;


-- 10) Down Script (Cleanup)
-- Drop triggers
DROP TRIGGER IF EXISTS trg_set_product_sku ON products;
DROP TRIGGER IF EXISTS trg_set_product_id_code ON products;

-- Drop functions
DROP FUNCTION IF EXISTS set_product_sku();
DROP FUNCTION IF EXISTS set_product_id_code();
DROP FUNCTION IF EXISTS extract_initials(text);
DROP FUNCTION IF EXISTS extract_custom_name_code(text);

-- Drop sequences
DROP SEQUENCE IF EXISTS product_sku_seq;
DROP SEQUENCE IF EXISTS product_id_code_seq;

UPDATE products set product_id_code = null;

SELECT last_value
FROM product_sku_seq;



SELECT last_value
FROM product_id_code_seq;

select * from customers where deleted_date is null;


select p.product_name, l.location_name, vv.* from product_stock_locations_view vv
left join products p on p.id_product = vv.v_id_product
left join locations l on l.id_location = vv.v_id_location;


CREATE OR REPLACE VIEW product_stock_locations_view AS
SELECT
  x.ref_id_product AS v_id_product,
  l.id_location    AS v_id_location,
  l.location_name,
  COALESCE(SUM(x.quantity_change), 0) AS total_quantity
FROM (
  -- Incoming transactions: products added to a location
  SELECT
    it.ref_id_product,
    it.ref_id_location_to AS location_id,
    it.quantity          AS quantity_change
  FROM inventory_transactions it
  WHERE it.ref_id_location_to IS NOT NULL

  UNION ALL

  -- Outgoing transactions: products removed from a location
  SELECT
    it.ref_id_product,
    it.ref_id_location_from AS location_id,
    -it.quantity            AS quantity_change
  FROM inventory_transactions it
  WHERE it.ref_id_location_from IS NOT NULL
) AS x
JOIN locations l 
  ON l.id_location = x.location_id
GROUP BY 
  x.ref_id_product,
  l.id_location,
  l.location_name;


select p.product_name, p.id_product,
(
SELECT 
    SUM(
      CASE 
        WHEN it.transaction_type IN ('RECEIVE', 'PURCHASE', 'RETURN', 'ADJUSTMENT') 
          THEN it.quantity 
        WHEN it.transaction_type NOT IN ('TRANSFER') 
          THEN -it.quantity 
        ELSE 0 
      END
    )
  FROM inventory_transactions it 
  WHERE it.ref_id_product = id_product
) 
as totalQuantity
from products p
order by totalQuantity;

SELECT 
  p.product_name,
  p.id_product,
  COALESCE(SUM(
    CASE 
      WHEN it.transaction_type IN ('RECEIVE', 'PURCHASE', 'RETURN', 'ADJUSTMENT') THEN it.quantity
      WHEN it.transaction_type = 'TRANSFER' THEN 0
      ELSE -it.quantity
    END
  ), 0) AS totalQuantity
FROM products p
LEFT JOIN inventory_transactions it 
  ON it.ref_id_product = p.id_product
GROUP BY 
  p.product_name,
  p.id_product
ORDER BY totalQuantity desc;


CREATE or replace VIEW product_stock_locations_view AS
SELECT
  x.ref_id_product       AS v_id_product,
  l.id_location          AS v_id_location,
  l.location_name        AS location_name,
  SUM(x.quantity_change) AS total_quantity
FROM (
  SELECT
    it.ref_id_product,
    it.ref_id_location_to AS location_id,
    it.quantity AS quantity_change
  FROM inventory_transactions it
  WHERE it.ref_id_location_to IS NOT NULL
  UNION ALL
  SELECT
    it.ref_id_product,
    it.ref_id_location_from AS location_id,
    -it.quantity AS quantity_change
  FROM inventory_transactions it
  WHERE it.ref_id_location_from IS NOT NULL
) AS x
JOIN locations l ON l.id_location = x.location_id
GROUP BY
  x.ref_id_product,
  l.id_location,
  l.location_name;


select * from inventory_transactions it where it.ref_id_product = 183;

*/
