import React, { useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Typography,
  notification,
  Flex,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  useUsersServiceGetUsersKey,
  useUsersServiceResetPassword,
} from "api/queries";
import { useQueryClient } from "@tanstack/react-query";

const { Text } = Typography;

interface ResetUserPasswordModalProps {
  isOpen: boolean;
  onClose: () => void;
  userId: number; // ID of the user whose password is being reset
}

const ResetUserPasswordModal: React.FC<ResetUserPasswordModalProps> = ({
  isOpen,
  onClose,
  userId,
}) => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const [generatedPassword, setGeneratedPassword] = useState<string | null>(
    null
  );

  // Use the shared mutation hook
  const { mutate: resetPassword, isPending } = useUsersServiceResetPassword();

  // Password validation rules
  const validatePassword = (_: any, value: string) => {
    if (!value) {
      return Promise.reject(new Error("Password is required"));
    }
    if (value.includes(" ")) {
      return Promise.reject(new Error("Password cannot contain spaces"));
    }
    if (value.length < 6) {
      return Promise.reject(
        new Error("Password must be at least 6 characters")
      );
    }
    // Strong password regex: At least one uppercase, one lowercase, one number, and one special character
    const strongPasswordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
    if (!strongPasswordRegex.test(value)) {
      return Promise.reject(
        new Error(
          "Password must include uppercase, lowercase, number, and special character"
        )
      );
    }
    return Promise.resolve();
  };

  const handleResetPassword = async () => {
    try {
      const values = await form.validateFields();
      const newPassword = values.password;
      resetPassword(
        { userId, requestBody: { newPassword } },
        {
          onSuccess: async () => {
            await queryClient.invalidateQueries({
              queryKey: [useUsersServiceGetUsersKey],
            });
            setGeneratedPassword(newPassword);
          },
          onError: (error: any) => {
            notification.error({
              description:
                error?.body?.message ||
                error?.response?.data?.message ||
                error?.message ||
                "An unexpected error occurred.",
              type: "error",
              message: "Failed to update password",
            });
          },
        }
      );
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  const handleClose = () => {
    form.resetFields(); // Reset the form on close
    setGeneratedPassword(null); // Clear the generated password
    onClose();
  };

  return (
    <Modal
      title="Reset User Password"
      open={isOpen}
      onCancel={handleClose}
      footer={null}
    >
      {!generatedPassword ? (
        <Form form={form} layout="vertical" onFinish={handleResetPassword}>
          <Form.Item
            label="New Password"
            name="password"
            rules={[
              { validator: validatePassword },
              { required: true, message: "Please enter a new password" },
            ]}
          >
            <Input.Password placeholder="Enter new password" />
          </Form.Item>

          <Form.Item
            label="Confirm Password"
            name="confirmPassword"
            dependencies={["password"]}
            rules={[
              { required: true, message: "Please confirm the password" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Passwords do not match"));
                },
              }),
            ]}
          >
            <Input.Password placeholder="Confirm new password" />
          </Form.Item>

          <Button
            type="primary"
            htmlType="submit"
            block
            loading={isPending} // Use isPending instead of isLoading
            disabled={isPending} // Disable the button while the mutation is pending
          >
            Reset Password
          </Button>
        </Form>
      ) : (
        <div style={{ textAlign: "center" }}>
          <Flex align="center" justify="center" vertical>
            <ExclamationCircleOutlined
              style={{ fontSize: 24, color: "#1890ff" }}
            />
            <Text strong style={{ display: "block", marginTop: 16 }}>
              Generated Password:
            </Text>
            <Text copyable>{generatedPassword}</Text>
            <Button
              type="default"
              onClick={handleClose}
              style={{ marginTop: 16 }}
              disabled={isPending} // Disable the Close button while the mutation is pending
            >
              Close
            </Button>
          </Flex>
        </div>
      )}
    </Modal>
  );
};

export default ResetUserPasswordModal;
