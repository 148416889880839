import React, { useState } from "react";
import { Form, Input, Row, Col, Typography, Divider, Upload } from "antd";
import BrandSelect from "components/common/BrandSelect";

const { Title } = Typography;

export interface DeviceInformationFormProps {
  form: any;
}

const DeviceInformationForm: React.FC<DeviceInformationFormProps> = ({
  form,
}) => {
  const [imageFiles, setImageFiles] = useState<{ [key: string]: File | null }>({
    frontView: null,
    backView: null,
    leftSide: null,
    rightSide: null,
  });

  const handleImageUpload = (file: File, view: string) => {
    setImageFiles((prev) => ({ ...prev, [view]: file }));
  };

  const removeImage = (view: string) => {
    setImageFiles((prev) => ({ ...prev, [view]: null }));
  };

  return (
    <div>
      <Title level={4} style={{ marginBottom: 0 }}>
        Device Information
      </Title>
      <Divider style={{ borderTop: "2px solid #fa8c16" }} />

      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} md={6}>
          <Form.Item
            label="Brand"
            name="brand"
            rules={[{ required: true, message: "Please enter the brand" }]}
          >
            <BrandSelect isForm={true} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Form.Item
            label="Model"
            name="model"
            rules={[{ required: true, message: "Please enter the model" }]}
          >
            <Input placeholder="Model" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Form.Item label="IMEI Number" name="imeiNumber">
            <Input placeholder="IMEI Number" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Form.Item label="Device Lock Code" name="deviceLockCode">
            <Input placeholder="Enter device lock code if any" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Form.Item label="Device Pattern" name="devicePattern">
            <Input placeholder="Describe unlock pattern if any" />
          </Form.Item>
        </Col>
      </Row>

      <Title level={5}>Device Condition Photos</Title>
      <Row gutter={[16, 16]}>
        {["frontView", "backView", "leftSide", "rightSide"].map((view) => (
          <Col xs={24} sm={12} md={6} key={view}>
            <Form.Item name={view}>
              <Upload
                beforeUpload={(file) => {
                  handleImageUpload(file, view);
                  return false;
                }}
                onRemove={() => removeImage(view)}
                fileList={
                  imageFiles[view]
                    ? [
                        {
                          uid: view,
                          name: imageFiles[view]?.name || "",
                          status: "done",
                        },
                      ]
                    : []
                }
                listType="picture-card"
                maxCount={1}
              >
                {imageFiles[view] ? null : (
                  <div>{view.replace(/([A-Z])/g, " $1").trim()}</div>
                )}
              </Upload>
            </Form.Item>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default DeviceInformationForm;
