import { PlusOutlined } from "@ant-design/icons";
import { Button, Divider, Flex, Space, Typography } from "antd";
import { FC, useEffect } from "react";
import { PurchaseOrderItem } from "./PurchaseOrderItem";
import { IProductItem } from "api/requests";

interface IItemSelectionProps {
  selectedItems: any[];
  setSelectedItems: any;
  // Optional props for currency conversion
  selectedCurrency?: any; // if null, then default prices are used
  exchangeRateAmount?: number; // defaults to 1 if not provided
}

export const ItemSelection: FC<IItemSelectionProps> = ({
  selectedItems,
  setSelectedItems,
  selectedCurrency,
  exchangeRateAmount = 1,
}) => {
  // Add a new item only if the previous item is fully set
  const addItem = () => {
    if (selectedItems.some((i) => !i.idProduct)) {
      return;
    }
    setSelectedItems([
      ...selectedItems,
      {
        idProduct: null,
        quantity: 1,
        unitPrice: 0,
        totalPrice: 0,
        productName: "",
        originalUnitPrice: undefined, // to store the default price
      },
    ]);
  };

  const removeItem = (index: number) => {
    setSelectedItems(selectedItems.filter((_, i) => i !== index));
  };

  const handleItemChange = (
    index: number,
    value: any,
    itemDetails: IProductItem
  ) => {
    // Avoid duplicate product selections.
    if (selectedItems.some((i) => i.idProduct === value)) {
      return;
    }
    const item = { ...selectedItems[index] };
    item.idProduct = value;
    item.productName = itemDetails?.productName;

    // Save the original unit price if not already set.
    if (item.originalUnitPrice === undefined) {
      item.originalUnitPrice = Number(itemDetails?.productCost || 0);
    }
    // Calculate the unit price using the current exchange rate (if a currency is selected).
    const rate = selectedCurrency ? exchangeRateAmount : 1;
    const newUnitPrice = item.originalUnitPrice * rate;
    item.unitPrice = Number(newUnitPrice.toFixed(2));
    item.totalPrice = Number((item.quantity * newUnitPrice).toFixed(2));

    const newItems = [...selectedItems];
    newItems[index] = item;
    setSelectedItems(newItems);
  };

  const handleQuantityChange = (index: number, value: number) => {
    const item = { ...selectedItems[index] };
    item.quantity = value;
    item.totalPrice = Number((value * item.unitPrice).toFixed(2));
    const newItems = [...selectedItems];
    newItems[index] = item;
    setSelectedItems(newItems);
  };

  const handleUnitPriceChange = (index: number, value: number) => {
    const item = { ...selectedItems[index] };
    item.unitPrice = value;
    item.totalPrice = Number((item.quantity * value).toFixed(2));
    const newItems = [...selectedItems];
    newItems[index] = item;
    setSelectedItems(newItems);
  };

  const handleClearItem = (index: number) => {
    const item = { ...selectedItems[index] };
    item.idProduct = null;
    item.quantity = 1;
    item.unitPrice = 0;
    item.productName = "";
    item.totalPrice = 0;
    item.originalUnitPrice = undefined;
    const newItems = [...selectedItems];
    newItems[index] = item;
    setSelectedItems(newItems);
  };

  // When the currency or exchange rate changes, update each item's price based on its originalUnitPrice.
  useEffect(() => {
    const rate = selectedCurrency ? exchangeRateAmount : 1;
    setSelectedItems((prevItems: any[]) =>
      prevItems.map((item) => {
        if (item.originalUnitPrice !== undefined) {
          const newUnitPrice = item.originalUnitPrice * rate;
          return {
            ...item,
            unitPrice: Number(newUnitPrice.toFixed(2)),
            totalPrice: Number((newUnitPrice * item.quantity).toFixed(2)),
          };
        }
        return item;
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exchangeRateAmount, selectedCurrency]);

  return (
    <Flex vertical style={{ width: "100%" }}>
      <Space.Compact block>
        <Typography style={{ width: "65%" }}>Item</Typography>
        <Typography style={{ width: "15%" }}>Quantity</Typography>
        <Typography style={{ width: "15%" }}>Unit Price</Typography>
        <Typography style={{ width: "15%" }}>Total Price</Typography>
      </Space.Compact>

      {selectedItems.map((item, index) => (
        <PurchaseOrderItem
          key={`item-in-purchase-order-${index}`}
          item={item}
          onChange={handleItemChange}
          index={index}
          onQuantityChange={handleQuantityChange}
          onUnitPriceChange={handleUnitPriceChange}
          onClearItem={handleClearItem}
          onRemoveItem={removeItem}
        />
      ))}

      <Divider orientation="center">
        <Button icon={<PlusOutlined />} onClick={addItem}>
          Add Item
        </Button>
      </Divider>
    </Flex>
  );
};

export default ItemSelection;
