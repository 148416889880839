import { Button, Card, Flex, Typography } from "antd";
import * as echarts from "echarts";
import { useEffect, useRef } from "react";

type EChartsOption = echarts.EChartsOption;

interface OverviewExpensesPieProps {
  data:
    | {
        expenseCategory: string;
        total: number;
      }[]
    | undefined;
}

const OverviewExpensesPie: React.FC<OverviewExpensesPieProps> = ({ data }) => {
  const chartRef = useRef<any>(null);

  useEffect(() => {
    if (chartRef.current && data?.length) {
      // initialize the chart instance
      const chartInstance = echarts.init(chartRef.current);
      const d =
        data?.map((item) => ({
          name: item.expenseCategory,
          value: item.total,
        })) || [];

      // Define the chart options
      const options: EChartsOption = {
        tooltip: {
          trigger: "item",
        },
        legend: {
          top: "0%",
          left: "center",
        },
        series: [
          {
            name: "Access From",
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            padAngle: 5,
            itemStyle: {
              borderRadius: 10,
            },
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 40,
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: d,
          },
        ],
      };

      // Adding the options to the chart
      chartInstance.setOption(options);
    }
  }, [chartRef, data]);

  return (
    <Card>
      <Flex justify="space-between" align="center">
        <Typography.Title level={4} style={{ marginTop: 0 }}>
          Expenses
        </Typography.Title>
        {/* <Button type="link">View report</Button> */}
      </Flex>

      <Flex justify="center" align="center" style={{ height: "303px" }}>
        <div ref={chartRef} style={{ width: "100%", height: "100%" }}></div>
      </Flex>
    </Card>
  );
};

export default OverviewExpensesPie;
