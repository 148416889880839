import React, { useState } from "react";
import {
  Form,
  Input,
  Button,
  DatePicker,
  Select,
  Row,
  Col,
  Space,
  InputNumber,
  Modal,
  Alert,
  message,
  Divider,
} from "antd";
import { QueryClient } from "@tanstack/react-query";
import QueryString from "qs";
import {
  useAccountingServiceGetTaxes,
  useSalesServiceCreateInvoice,
  useSalesServiceGetInvoicesKey,
} from "api/queries";
import dayjs from "dayjs";
import { ICreateInvoiceInput } from "api/requests";
import PaymentAccountSelect from "components/common/PaymentAccountSelect";
import TaxSelect from "components/common/TaxSelect";
import CreateInvoiceItemsTable from "./CreateInvoiceItemsTable";
import LocationSelect from "components/common/LocationSelect";
import { InvoiceStatus } from "components/common";
// Use the integrated customer select so the user can either select an existing customer
// or fill out the full set of customer details (name, country, address, phone, email, etc.)
import IntegratedCustomerSelect from "./IntegratedCustomerSelect";
import UserPaymentAccountSelect from "components/common/UserPaymentAccountSelect";

const { TextArea } = Input;

interface Item {
  key: number;
  idProduct: string;
  description: string;
  price: number;
  quantity: number;
  availableQuantity: number;
  initialQuantity: number;
  quantityInLocations: {
    idLocation: number;
    locationName: string;
    availableQuantity: number;
    movedQuantity: number;
    initialQuantity: number;
  }[];
}

interface ICreateInvoiceModalProps {
  isOpen: boolean;
  onClose: () => void;
  queryClient: QueryClient;
}

const InvoiceCreation: React.FC<ICreateInvoiceModalProps> = ({
  isOpen,
  onClose,
  queryClient,
}) => {
  const [form] = Form.useForm();
  const [items, setItems] = useState<Item[]>([]);
  const [selectedTax, setSelectedTax] = useState<any | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<any | null>(null);
  // Discount state (type and amount)
  const [discountType, setDiscountType] = useState<string | null>(null);
  const [discountAmount, setDiscountAmount] = useState<number>(0);

  const { data: taxData } = useAccountingServiceGetTaxes({
    filters: "",
    sort: "",
    pagination: QueryString.stringify({ limit: 1000, offset: 0 }),
  });

  const { mutate, isPending, isError, error } = useSalesServiceCreateInvoice({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [useSalesServiceGetInvoicesKey],
      });
      form.resetFields();
      setDiscountAmount(0);
      setDiscountType(null);
      setItems([]);
      setSelectedTax(null);
      setSelectedLocation(null);
      onClose();
    },
    onError: (err: any) => {
      message.error(err?.body?.message || err || "Failed to create invoice");
    },
  });

  // Calculate Subtotal (without discounts)
  const calculateSubtotal = (): number => {
    return items.reduce(
      (total: number, item: Item) => total + item.price * item.quantity,
      0
    );
  };

  // Calculate Total Discount (either fixed or percentage-based)
  const calculateTotalDiscount = (): number => {
    const subtotal = calculateSubtotal();
    if (discountType === "PERCENTAGE") {
      return (subtotal * (discountAmount || 0)) / 100;
    }
    return discountAmount || 0;
  };

  // Calculate Total Tax based on the discounted subtotal
  const calculateTotalTax = (): number => {
    const subtotal = calculateSubtotal() - calculateTotalDiscount();
    const taxRate = selectedTax?.taxRate || 0;
    const taxType = selectedTax?.taxType || "PERCENTAGE";
    if (taxType === "PERCENTAGE") {
      return (subtotal * (taxRate || 0)) / 100;
    }
    return taxRate;
  };

  // Final total amount = subtotal - discount + tax
  const calculateTotal = (): number => {
    return calculateSubtotal() - calculateTotalDiscount() + calculateTotalTax();
  };

  // Handle form submission by constructing the full payload
  const handleSubmit = (values: any) => {
    const invoiceData: ICreateInvoiceInput = {
      ...values,
      invoiceDate: dayjs(values.invoiceDate).toDate(),
      invoiceDueDate: dayjs(values.invoiceDueDate).toDate(),
      invoiceSubtotal: calculateSubtotal(),
      invoiceDiscountTotal: calculateTotalDiscount(),
      totalTax: calculateTotalTax(),
      invoiceTotalAmount: calculateTotal(),
      tax: selectedTax?.idTax || null,
      // Use the full customer object returned from the integrated customer select
      customer: values.customer,
      invoiceStatus: InvoiceStatus.SENT,
      invoiceItems: items.map((item: Item) => ({
        product: item.idProduct,
        quantity: item.quantity,
        unitPrice: item.price,
        totalPrice: item.price * item.quantity,
        movedFromOtherLocations: item.quantityInLocations.map((loc) => ({
          idLocation: loc.idLocation,
          quantityMoved: loc.movedQuantity,
        })),
      })),
    };

    mutate({ requestBody: invoiceData });
  };

  // When the tax is selected/cleared, update the selectedTax state and set tax details in the form
  const handleTaxChange = () => {
    const selectTax = form.getFieldValue("tax");
    if (!selectTax) {
      setSelectedTax(null);
      form.setFieldsValue({
        taxRate: 0,
        taxType: "PERCENTAGE",
      });
      return;
    }

    const tax = taxData?.items?.find((i) => i.idTax === selectTax);
    if (tax) {
      setSelectedTax(tax);
      form.setFieldsValue({
        taxRate: tax.taxRate,
        taxType: tax.taxType,
      });
    } else {
      setSelectedTax(null);
      form.setFieldsValue({
        taxRate: 0,
        taxType: "PERCENTAGE",
      });
    }
  };

  // Handle location changes; note that the selected location is also used by the items table
  const handleLocationChange = () => {
    const selectLocation = form.getFieldValue("location");
    if (!selectLocation) {
      setSelectedLocation(null);
      return;
    }
    setSelectedLocation(selectLocation);
  };

  // When the discount type changes, update state (and reset the discount amount if cleared)
  const handleDiscountTypeChange = (value: string | null) => {
    setDiscountType(value);
    if (!value) {
      setDiscountAmount(0);
    }
  };

  return (
    <Modal
      title="Create Invoice"
      open={isOpen}
      width={1000}
      onCancel={onClose}
      onOk={form.submit}
      okText="Create Invoice"
      cancelText="Cancel"
      destroyOnClose
      afterClose={() => form.resetFields()}
      confirmLoading={isPending}
      cancelButtonProps={{ disabled: isPending }}
    >
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        {isError && (
          <Row gutter={16}>
            <Col span={24}>
              <Alert
                type="error"
                closable
                message={error?.message}
                description={error?.body?.message || error?.message}
              />
            </Col>
          </Row>
        )}

        {/* ===== SECTION 1: Invoice Details ===== */}
        <Divider orientation="left">Invoice Details</Divider>
        {/* Integrated Customer Select */}
        <IntegratedCustomerSelect
          form={form}
          onChange={(customer) => form.setFieldValue("customer", customer)}
        />
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Invoice Number" name="invoiceNumber">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Billing Address" name="billingAddress">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Issue Date"
              name="invoiceDate"
              rules={[
                { required: true, message: "Please select an issue date" },
              ]}
              initialValue={dayjs()}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Due Date"
              name="invoiceDueDate"
              rules={[{ required: true, message: "Please select a due date" }]}
              initialValue={dayjs()}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>

        {/* ===== SECTION 2: Item Details ===== */}
        <Divider orientation="left">Item Details</Divider>
        <Row gutter={16}>
          {/* Location (Stock Location) should be here */}
          <Col span={24}>
            <Form.Item label="Location" name="location">
              <LocationSelect
                isForm={true}
                selectProps={{
                  onSelect: handleLocationChange,
                  disabled: items?.length > 0,
                }}
              />
            </Form.Item>
          </Col>
          {/* Tax Selection: if you want it to be part of the items section */}
        </Row>
        {/* Hidden fields for tax details */}
        <Form.Item name="taxRate" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="taxType" hidden>
          <Input />
        </Form.Item>
        {/* Items Table */}
        <Row gutter={16}>
          <Col span={24}>
            <CreateInvoiceItemsTable
              items={items}
              setItems={setItems}
              selectedLocation={selectedLocation}
            />
          </Col>
        </Row>
        <Space.Compact style={{ width: "100%" }}>
          <Button
            type="dashed"
            onClick={() => {
              setItems([
                ...items,
                {
                  key: items.length,
                  idProduct: "",
                  description: "",
                  price: 0,
                  quantity: 1,
                  availableQuantity: 0,
                  initialQuantity: 0,
                  quantityInLocations: [],
                },
              ]);
            }}
            style={{ width: "100%", marginTop: 16 }}
            disabled={!selectedLocation}
          >
            Add Item
          </Button>
        </Space.Compact>
        {/* Totals can remain at the bottom of the item details */}
        <div style={{ marginTop: 16, textAlign: "right" }}>
          <h3>Subtotal: {calculateSubtotal().toFixed(2)}</h3>
          <h3>Total Discount: {calculateTotalDiscount().toFixed(2)}</h3>
          <h3>Total Tax: {calculateTotalTax().toFixed(2)}</h3>
          <h3>Total: {calculateTotal().toFixed(2)}</h3>
        </div>

        {/* ===== SECTION 3: Payment Details ===== */}
        <Divider orientation="left">Payment Details</Divider>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Account"
              name="account"
              rules={[{ required: true, message: "Please select an account" }]}
            >
              <UserPaymentAccountSelect isForm={true} />
            </Form.Item>
          </Col>
          {/* Discount fields */}
          <Col span={12}>
            <Form.Item label="Discount Type" name="discountType">
              <Select
                allowClear
                options={[
                  { label: "PERCENTAGE", value: "PERCENTAGE" },
                  { label: "FIXED", value: "FIXED" },
                ]}
                onChange={handleDiscountTypeChange}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Discount Amount"
              name="discountAmount"
              rules={[
                {
                  required: !!discountType,
                  message: "Discount amount cannot be 0",
                },
              ]}
            >
              <InputNumber
                min={0}
                value={discountAmount}
                onChange={(value) => setDiscountAmount(value || 0)}
                disabled={!discountType}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Tax" name="tax">
              <TaxSelect
                isForm={true}
                selectProps={{
                  onSelect: handleTaxChange,
                  onClear: handleTaxChange,
                }}
              />
            </Form.Item>
          </Col>
          {/* Amount Paid now appears in the Payment Details section */}
          <Col span={12}>
            <Form.Item
              label="Amount Paid"
              name="amountPaid"
              rules={[
                {
                  message:
                    "Amount paid cannot exceed the total amount " +
                    calculateTotal(),
                  validator(rule, value) {
                    if (value > 0 && value > calculateTotal()) {
                      return Promise.reject(
                        new Error("Amount paid cannot exceed the total amount")
                      );
                    }
                    return Promise.resolve();
                  },
                },
                {
                  type: "number",
                  message: "Please enter a valid amount",
                },
              ]}
            >
              <InputNumber min={0.01} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          {/* Note Field */}
          <Col span={24}>
            <Form.Item label="Note" name="note">
              <TextArea rows={3} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default InvoiceCreation;