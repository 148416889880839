//code for dashboard screen
import React from "react";
import { motion } from "framer-motion";
import { Button, Col, Flex, Row, Segmented, Select, Typography } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { DashboardOverView } from "./Overview";
import DashboardBot from "./DashboardBot";

export default function Dashboard() {
  const [currentView, setCurrentView] = React.useState("overview");
  const [range, setRange] = React.useState<
    "TODAY" | "THIS_WEEK" | "THIS_MONTH" | "THIS_YEAR"
  >("THIS_MONTH");

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5, delay: 0 }}
      style={{ position: "relative", height: "100%" }}
    >
      <Row gutter={[16, 16]} justify={"space-between"}>
        <Col xs={24}>
          <Flex justify="space-between" align="center">
            {/* <Segmented
              onChange={(v) => setCurrentView(v)}
              value={currentView}
              options={[
                { label: "Overview", value: "overview" },
                { label: "Inventory", value: "inventory" },
                { label: "Accounting", value: "accounting" },
                { label: "Contacts", value: "contacts" },
              ]}
            /> */}
            <div></div>

            <span>
              <Select
                defaultValue={"month"}
                onChange={(v: any) => setRange(v)}
                value={range}
                options={[
                  { label: "Today", value: "TODAY" },
                  { label: "This Week", value: "THIS_WEEK" },
                  { label: "This Month", value: "THIS_MONTH" },
                  { label: "This Year", value: "THIS_YEAR" },
                ]}
              />

              {/* <Button
                type="primary"
                icon={<DownloadOutlined />}
                style={{ marginLeft: "1rem" }}
              >
                Export
              </Button> */}
            </span>
          </Flex>
        </Col>

        {currentView === "overview" ? (
          <DashboardOverView range={range} />
        ) : (
          // <div></div>
          <Flex
            justify="center"
            align="center"
            style={{ height: "100%", width: "100%" }}
          >
            <Typography.Title level={5}>Under construction</Typography.Title>
          </Flex>
        )}
      </Row>

      {currentView !== "overview" ? (
        <motion.img
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1.5, delay: 0 }}
          src="/under-construction.gif"
          style={{
            position: "absolute",
            left: 0,
            bottom: 0,
            margin: "-24px",
            borderBottomLeftRadius: 12,
          }}
        />
      ) : null}

      {/* <DashboardBot /> */}
    </motion.div>
  );
}
