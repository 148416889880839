import React from "react";
import { IconArrowsShuffle } from "@tabler/icons-react";
import {
  Alert,
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Switch,
  Typography,
  Tabs,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import {
  useInventoryServiceGetBrands,
  useInventoryServiceGetProductCategories,
  useInventoryServiceGetProductsKey,
  useInventoryServiceGetTotalProducts,
  useInventoryServiceUpdateProduct,
  useInventoryServiceGetProductVariantTypes,
} from "api/queries";
import BrandSelect from "components/common/BrandSelect";
import ParentCategorySelect from "components/common/ParentCategorySelect";
import ProductModelSelect from "components/common/ProductModelSelect";
import SubcategorySelect from "components/common/SubcategorySelect";
import qs from "qs";
import { useEffect, useState } from "react";
import { MeasurementUnit, WeightUnit } from "utils/enums";
import { getInitials } from "utils/helpers";

const { TabPane } = Tabs;
const { Title } = Typography;

interface IEditProductModalProps {
  isOpen: boolean;
  onClose: () => void;
  queryClient: any;
  defaultValues: any;
}

const RandomSuffix = ({ onClick }: any) => {
  return (
    <Button
      style={{
        padding: 0,
        minHeight: 0,
        minWidth: 0,
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
      type="text"
      onClick={onClick}
    >
      <IconArrowsShuffle size={16} />
    </Button>
  );
};

const EditProductModal: React.FC<IEditProductModalProps> = ({
  isOpen,
  onClose,
  queryClient,
  defaultValues,
}) => {
  const [showError, setShowError] = React.useState(false);

  const [form] = Form.useForm();

  const { mutate, isPending, isError, error } =
    useInventoryServiceUpdateProduct({
      onError: (error: any) => {
        console.dir(error);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: [useInventoryServiceGetProductsKey],
          refetchType: "all",
        });
        onClose();
      },
    });

  const { data: totalProductsCount } = useInventoryServiceGetTotalProducts([], {
    refetchInterval: !isOpen ? false : 1000,
  });

  const { data: variantData } = useInventoryServiceGetProductVariantTypes({
    filters: "",
    sort: "",
    pagination: qs.stringify({
      limit: 1000,
      offset: 0,
    }),
  });

  const handleGenerateBarcode = () => {
    //generate numeric barcode
    const barcode = Math.random().toString().slice(2, 14);
    form.setFieldsValue({ productBarcode: barcode });
  };

  useEffect(() => {
    if (isOpen) {
      form.setFieldsValue({
        ...defaultValues,
        productCategory: defaultValues?.productCategory?.idProductCategory || 0,
        brand: defaultValues?.brand?.idBrand || 0,
        modelCompatibility:
          defaultValues?.models?.map((m: any) => m.idModel) || [],
        productSubcategory:
          defaultValues?.productSubcategory?.idProductCategory || null,
      });

      defaultValues?.productVariantValues?.forEach((vItem: any, idx: any) => {
        const val = vItem?.productVariantValue?.idProductVariant;
        form.setFieldValue(
          "extra-" + vItem?.productVariantValue?.productVariantType?.name,
          val
        );
      });
    }

    // if (!isOpen) {
    //   form.resetFields();
    // }
  }, [form, defaultValues]);

  const isVariantSelected = () => {
    const variantIds: any[] = [];
    const values = form.getFieldsValue();

    variantData?.items?.forEach((item) => {
      if ("extra-" + values[item.name]) {
        variantIds.push("extra-" + values[item.name]);
        delete values[item.name];
      }
    });

    return variantIds.length > 0;
  };

  const onFinish = async (values: any) => {
    const variantIds: any[] = [];

    Object.keys(values).forEach((key) => {
      if (key.startsWith("extra-")) {
        variantIds.push(values[key]);
      }
    });

    mutate({
      requestBody: { ...values, variantIds },
      id: defaultValues.idProduct,
    });
  };

  return (
    <Modal
      okButtonProps={{ loading: isPending }}
      cancelButtonProps={{ disabled: isPending }}
      open={isOpen}
      onCancel={() => onClose()}
      onOk={() => form.submit()}
      style={{ maxWidth: "1100px" }}
      width={"100%"}
      destroyOnClose
    >
      <Row>
        <Col
          xs={24}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title level={2} style={{ margin: 0 }}>
            Edit Product
          </Title>
        </Col>

        <Col xs={24}>
          {showError && (
            <Alert
              type="error"
              message="Missing Fields"
              description={form
                .getFieldsError()
                .filter((err) => err.errors[0])
                .map((err) => err.errors[0])
                .join(", ")}
            />
          )}
        </Col>
      </Row>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={() => {
          setShowError(true);
        }}
        onFieldsChange={() => {
          setShowError(false);
        }}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab="General Information" key="1" forceRender>
            <Row gutter={16}>
              <Col xs={8}>
                <Form.Item
                  name="productName"
                  label="Product Model Name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter product model name",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={8}>
                <Form.Item
                  name="brand"
                  label="Brand"
                  rules={[
                    {
                      required: true,
                      message: "Please select brand",
                    },
                  ]}
                >
                  <BrandSelect isForm={true} />
                </Form.Item>
              </Col>

              <Col xs={8}>
                <Form.Item
                  name="modelCompatibility"
                  label="Model Compatibility"
                >
                  <ProductModelSelect
                    isForm={true}
                    selectProps={{ mode: "multiple" }}
                  />
                </Form.Item>
              </Col>

              <Col xs={8}>
                <Form.Item
                  name="productCategory"
                  label="Category"
                  rules={[
                    {
                      required: true,
                      message: "Please select category",
                    },
                  ]}
                >
                  <ParentCategorySelect
                    isForm={true}
                    selectProps={{
                      onSelect: (_v, opt) => {
                        form.setFieldsValue({ productSubcategory: null });
                      },
                    }}
                  />
                </Form.Item>
              </Col>

              <Col xs={8}>
                <Form.Item name="productSubcategory" label="Subcategory">
                  <SubcategorySelect isForm={true} />
                </Form.Item>
              </Col>
            </Row>
          </TabPane>

          <TabPane tab="Technician Details" key="2" forceRender>
            <Row gutter={16}>
              <Col xs={8}>
                <Form.Item
                  name="touchScreenCapability"
                  label="Touch Screen Capability"
                >
                  <Select
                    options={[
                      { label: "With touch", value: "WITH_TOUCH" },
                      { label: "Without touch", value: "WITHOUT_TOUCH" },
                    ]}
                    showSearch
                    allowClear
                    optionFilterProp="label"
                  />
                </Form.Item>
              </Col>

              <Col xs={8}>
                <Form.Item name="productGrade" label="Product Grade">
                  <Select
                    options={[
                      { label: "Service Pack", value: "SERVICE_PACK" },
                      { label: "Original", value: "ORIGINAL" },
                      { label: "Copy A", value: "COPY_A" },
                      { label: "Copy AAA", value: "COPY_AAA" },
                      { label: "Diamond", value: "DIAMOND" },
                      { label: "Platinum", value: "PLATINUM" },
                      { label: "Premium", value: "PREMIUM" },
                    ]}
                    showSearch
                    allowClear
                    optionFilterProp="label"
                  />
                </Form.Item>
              </Col>

              <Col xs={8}>
                <Form.Item name="warrantyInfo" label="Warranty Information">
                  <Select
                    options={[
                      //no warranty, 6 months warranty, 1 year replacement warranty, 2 year warranty
                      { label: "No warranty", value: "NO_WARRANTY" },
                      { label: "6 months warranty", value: "6_MONTH_WARRANTY" },
                      { label: "1 year warranty", value: "1_YEAR_WARRANTY" },
                      { label: "2 year warranty", value: "2_YEAR_WARRANTY" },
                    ]}
                    showSearch
                    allowClear
                    optionFilterProp="label"
                  />
                </Form.Item>
              </Col>

              <Col xs={8}>
                <Form.Item
                  name="regulatoryCompliance"
                  label="Regulatory Compliance"
                >
                  <Select
                    options={[
                      { label: "CE certified", value: "CE_CERTIFIED" },
                      { label: "RoHS compliant", value: "ROHS_COMPLIANT" },
                    ]}
                    showSearch
                    allowClear
                    optionFilterProp="label"
                  />
                </Form.Item>
              </Col>
            </Row>
          </TabPane>

          <TabPane tab="Detailed Specifications" key="3" forceRender>
            <Row gutter={16}>
              <Col xs={12}>
                <Space.Compact block>
                  <Form.Item
                    name="productDimensionUnit"
                    label="Dimension Unit"
                    style={{ width: 250 }}
                  >
                    <Select
                      options={Object.keys(MeasurementUnit).map((key) => ({
                        label: key.toLowerCase().replace("_", " "),
                        value: key,
                      }))}
                      optionRender={(option) => (
                        <span style={{ textTransform: "capitalize" }}>
                          {option.label}
                        </span>
                      )}
                      showSearch
                      allowClear
                      optionFilterProp="label"
                    />
                  </Form.Item>

                  <Form.Item
                    name="productHeight"
                    label="Height"
                    rules={[
                      {
                        min: 0.01,
                        type: "number",
                        message: "Please enter a valid height",
                      },
                    ]}
                    // style={{ width: 100 }}
                  >
                    <InputNumber min={0.01} style={{ width: "100%" }} />
                  </Form.Item>

                  <Form.Item
                    name="productWidth"
                    label="Width"
                    rules={[
                      {
                        min: 0.01,
                        type: "number",
                        message: "Please enter a valid width",
                      },
                    ]}
                    // style={{ width: 100 }}
                  >
                    <InputNumber min={0.01} style={{ width: "100%" }} />
                  </Form.Item>

                  <Form.Item
                    name="productLength"
                    label="Length"
                    rules={[
                      {
                        min: 0.01,
                        type: "number",
                        message: "Please enter a valid length",
                      },
                    ]}
                    // style={{ width: 100 }}
                  >
                    <InputNumber min={0.01} style={{ width: "100%" }} />
                  </Form.Item>
                </Space.Compact>
              </Col>

              <Col xs={12}>
                <Space.Compact style={{ width: "100%" }}>
                  <Form.Item
                    name="productWeightUnit"
                    label="Weight Unit"
                    style={{ width: "100%" }}
                  >
                    <Select
                      options={Object.keys(WeightUnit).map((key) => ({
                        label: key,
                        value: key,
                        key: key + "product-select-weight-unit",
                      }))}
                      style={{ width: "100%" }}
                      showSearch
                      allowClear
                      optionFilterProp="label"
                    />
                  </Form.Item>

                  <Form.Item
                    name="productWeight"
                    label="Weight"
                    style={{ width: "100%" }}
                    rules={[
                      {
                        min: 0.01,
                        type: "number",
                        message: "Please enter a valid weight",
                      },
                    ]}
                  >
                    <InputNumber min={0.01} style={{ width: "100%" }} />
                  </Form.Item>
                </Space.Compact>
              </Col>
            </Row>
          </TabPane>

          <TabPane tab="Pricing Information" key="4" forceRender>
            <Row gutter={16}>
              <Col xs={8}>
                <Form.Item
                  name="productPrice"
                  label="Selling Price"
                  rules={[
                    {
                      min: 0.01,
                      type: "number",
                      message: "Please enter a valid price",
                    },
                    {
                      required: true,
                      message: "Please enter a valid selling price",
                    },
                  ]}
                >
                  <InputNumber min={0.01} style={{ width: "100%" }} />
                </Form.Item>
              </Col>

              <Col xs={8}>
                <Form.Item
                  name="productCost"
                  label="Buying Price"
                  rules={[
                    {
                      min: 0.01,
                      type: "number",
                      message: "Please enter a valid cost",
                    },
                    {
                      required: true,
                      message: "Please enter a valid buying price",
                    },
                  ]}
                >
                  <InputNumber min={0.01} style={{ width: "100%" }} />
                </Form.Item>
              </Col>
            </Row>
          </TabPane>

          <TabPane tab="Inventory Management" key="5" forceRender>
            <Row gutter={16}>
              <Col xs={8}>
                <Form.Item
                  label="Low Stock Quantity Warning"
                  name="productLowStockAlert"
                  rules={[
                    {
                      min: 0,
                      type: "number",
                      message: "Please enter a valid number",
                    },
                  ]}
                >
                  <InputNumber min={0} style={{ width: "100%" }} />
                </Form.Item>
              </Col>

              <Col xs={8}>
                <Form.Item
                  name="productIdealStock"
                  label="Ideal Stock Quantity"
                  rules={[
                    {
                      min: 0,
                      type: "number",
                      message: "Please enter a valid number",
                    },
                  ]}
                >
                  <InputNumber min={0} style={{ width: "100%" }} />
                </Form.Item>
              </Col>

              <Col xs={8}>
                <Form.Item
                  name="productBarcode"
                  label="Barcode"
                  rules={[
                    {
                      required: true,
                      message: "Please enter product barcode",
                    },
                  ]}
                >
                  <Input
                    suffix={<RandomSuffix onClick={handleGenerateBarcode} />}
                  />
                </Form.Item>
              </Col>

              <Col xs={24}>
                <Form.Item name="productDescription" label="Description">
                  <Input.TextArea />
                </Form.Item>
              </Col>
            </Row>
          </TabPane>

          <TabPane tab="Extra Information" key="6" forceRender>
            <Row gutter={16}>
              {variantData?.items?.map((item, idx) => (
                <Col span={12}>
                  <Form.Item
                    name={"extra-" + item.name}
                    label={item.name}
                    key={"extra-" + idx + "-" + item.name}
                    rules={[
                      { required: false },
                      {
                        validator(rule, value, callback) {
                          if (isVariantSelected()) {
                            callback();
                          } else {
                            callback("Please select variant");
                          }
                        },
                      },
                    ]}
                  >
                    <Select
                      key={`select-${item.name}`}
                      options={
                        item?.productVariantValues?.map((value) => ({
                          value: value.idProductVariant,
                          label: value.name,
                        })) || []
                      }
                      showSearch
                      allowClear
                      optionFilterProp="label"
                    />
                  </Form.Item>
                </Col>
              ))}
            </Row>
          </TabPane>
        </Tabs>
      </Form>
    </Modal>
  );
};

export default EditProductModal;
