import { useQueryClient } from "@tanstack/react-query";
import { Modal, Form, Input, InputNumber, Button, notification } from "antd";
import {
  useReparationServiceGetReparationCaseKey,
  useReparationServiceUpdateReparationCaseItem,
} from "api/queries";
import React, { FC } from "react";

export interface EditRCPricingItemModalProps {
  visible: boolean;
  onClose: () => void;
  initialValues: {
    description?: string;
    unitPrice: number;
    service?: string;
    product?: string;
    idReparationCaseItem: number;
    reparationCase: number;
  };
}

const EditRCPricingItemModal: FC<EditRCPricingItemModalProps> = ({
  visible,
  onClose,
  initialValues,
}) => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const { mutate, isPending } = useReparationServiceUpdateReparationCaseItem({
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: [useReparationServiceGetReparationCaseKey],
      });

      notification.success({
        message: "Item updated successfully",
        duration: 2,
      });
      onClose();
    },
    onError: (err: any) => {
      notification.error({
        message: "Failed to update item",
        description: err?.body?.message || err?.message || err,
        duration: 2,
      });
    },
  });

  const handleSubmit = (data: any) => {
    mutate({
      requestBody: {
        description: data?.description,
        unitPrice: data.unitPrice,
        quantity: data.quantity,
      },
      itemId: initialValues.idReparationCaseItem,
      id: initialValues.reparationCase,
    });
  };

  return (
    <Modal
      title="Edit Item"
      open={visible}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose} disabled={isPending}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={form.submit}
          disabled={isPending}
        >
          Save Changes
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={initialValues}
        onFinish={handleSubmit}
      >
        {/* Description Field */}
        <Form.Item name="description" label="Description" required={false}>
          <Input.TextArea placeholder="Enter description" />
        </Form.Item>

        {/* Unit Price Field */}
        <Form.Item
          name="unitPrice"
          label="Unit Price"
          rules={[{ required: true, message: "Please enter a valid price" }]}
        >
          <InputNumber
            min={0}
            precision={2}
            style={{ width: "100%" }}
            placeholder="Enter unit price"
          />
        </Form.Item>

        {/* Quantity Field */}
        <Form.Item
          name="quantity"
          label="Quantity"
          rules={[{ required: true, message: "Please enter the quantity" }]}
        >
          <InputNumber
            min={1}
            style={{ width: "100%" }}
            placeholder="Enter quantity"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditRCPricingItemModal;
