import { Col, Row, Timeline, Typography } from "antd";
import { motion } from "framer-motion";
import dayjs from "dayjs";
import { IReparationCaseDetails } from "api/requests";
import { IconCash, IconCheck, IconX } from "@tabler/icons-react";

const RCHistory = ({ data }: { data?: IReparationCaseDetails }) => {
  const getIcon = (val: string) => {
    switch (val) {
      case "PAYMENT":
        return <IconCash />;
      case "CANCELLED":
        return <IconX />
      case "COMPLETED":
        return <IconCheck />
      default:
        return null;
    }
  }
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5, delay: 0 }}
      key="history"
    >
      <Row>
        <Col xs={24}>
          <Timeline
            mode="left"
            style={{ width: "100%", margin: "2rem 0" }}
            items={data?.reparationCaseHistoryItems?.map((item: any) => ({
              label: item?.title,
              dot: getIcon(item.action),
              children: (
                <>
                  <Typography.Text>
                    {item?.entryDate
                      ? dayjs(item?.entryDate).format("DD/MM/YYYY hh:mm A")
                      : ""}
                  </Typography.Text>
                  <br />
                  <Typography.Text type="secondary">
                    {/* @ts-ignore */}
                    {item?.user?.userFullName}
                  </Typography.Text>
                </>
              ),
            }))}
          />
        </Col>
      </Row>
    </motion.div>
  );
};

export default RCHistory;
