// PrintBarcodesDrawer.tsx
import React, { useRef, useState } from "react";
import { Drawer, Card, Typography, Button } from "antd";
import ReactBarcode from "react-barcode";
import { CloseCircleOutlined, PrinterOutlined } from "@ant-design/icons";
import ConfirmPrintedItems from "./ConfirmPrintedModal";

const { Text } = Typography;

interface ProductVariant {
  [key: string]: string;
}

export interface BarcodeItem {
  idProduct: number;
  brand: string;
  category: string;
  productBarcode: string;
  productCategory: any;
  productId: number;
  productName: string;
  productSKU: string;
  productSubcategory: any;
  subCategory: string;
  totalPrinted: number;
  totalQuantity: number;
  variants: ProductVariant[];
  productVariantValues: any;
}

interface PrintBarcodesDrawerProps {
  open: boolean;
  onClose: () => void;
  selectedItems: BarcodeItem[];
  onPrint?: (items: BarcodeItem[]) => void;
  onPrintComplete?: () => void; // callback after printing is complete
}

const PrintBarcodesDrawer: React.FC<PrintBarcodesDrawerProps> = ({
  open,
  onClose,
  selectedItems,
  onPrint,
  onPrintComplete,
}) => {
  const printRef = useRef<HTMLDivElement>(null);
  const [confirmPrintedBarcodesModalOpen, setConfirmPrintedBarcodesModalOpen] =
    useState(false);

  // Compute a unique key for each item
  const getItemKey = (item: BarcodeItem, index: number): string => {
    return item.idProduct !== undefined
      ? item.idProduct.toString()
      : `fallback-${index}`;
  };

  // Called after printing completes.
  // Shows a modal with a backdrop, mocks an API call delay of 3 seconds,
  // then closes the modal and the drawer.
  const handlePrintComplete = () => {
    setConfirmPrintedBarcodesModalOpen(true);
  };

  const handleCloseConfirmPrintedBarcodesModal = () => {
    setConfirmPrintedBarcodesModalOpen(false);
    onClose();
  };

  const handlePrint = () => {
    if (onPrint) {
      onPrint(selectedItems);
    } else if (printRef.current) {
      const printContents = printRef.current.innerHTML;
      const printWindow = window.open("", "_blank", "width=600,height=600");
      if (printWindow) {
        const printHtml = `
          <html>
            <head>
              <title>Print Barcodes</title>
              <style>
                /* Set page size for barcode printing */
                @page {
                  size: 58mm 39mm;
                  margin: 0;
                }
                @media print {
                  html, body {
                    margin: 0;
                    padding: 0;
                    width: 100%;
                    height: 100%;
                    transform: scale(0.99);
                  }
                  .printable-item {
                    width: 58mm !important;
                    height: 39mm !important;
                    margin: 0 !important;
                    padding: 0 !important;
                    box-sizing: border-box;
                    overflow: hidden;
                    page-break-after: always;
                    page-break-inside: avoid;
                  }
                  /* Override Ant Design Card styles for printing */
                  .ant-card {
                    margin: 0 !important;
                    padding: 0 !important;
                    border: none !important;
                    box-shadow: none !important;
                  }
                }
              </style>
            </head>
            <body>
              ${printContents}
            </body>
          </html>
        `;
        printWindow.document.open();
        // Cast to avoid deprecated signature warning.
        (printWindow.document.write as (s: string) => void)(printHtml);
        printWindow.document.close();

        // Attach the afterprint event to trigger the callback once printing completes.
        printWindow.onafterprint = () => {
          printWindow.close();
          handlePrintComplete();
        };

        printWindow.focus();
        setTimeout(() => {
          printWindow.print();
        }, 500);
      }
    }
  };

  // Create a flattened array of Card elements based on the quantity field.
  const renderedCards = selectedItems
    .map((item, index) => {
      let copies = 1;

      if (
        item?.totalQuantity &&
        item?.totalPrinted > -1 &&
        item?.totalQuantity > item?.totalPrinted
      ) {
        copies = item.totalQuantity - item.totalPrinted;
      }

      return Array.from({ length: copies }, (_, copyIndex) => (
        <Card
          key={`${getItemKey(item, index)}-${copyIndex}`}
          style={{
            width: "100%",
            maxWidth: "58mm",
            margin: "5px auto", // center in preview
          }}
          className="printable-item"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Text>{item.productName}</Text>
            <Text style={{ fontSize: ".6rem", opacity: 0.7 }}>
              {item.productCategory.productCategoryName} |{" "}
              {item.productSubcategory.productCategoryName}
            </Text>
            {item.productVariantValues &&
              item.productVariantValues.length > 0 && (
                <div
                  style={{
                    fontSize: ".6rem",
                    opacity: 0.7,
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {item.productVariantValues
                    .filter(
                      (v: any) =>
                        v?.productVariantValue?.productVariantType?.name &&
                        v?.productVariantValue?.name
                    )
                    .map((variant: any, variantIndex: any) => (
                      <div
                        key={`${variantIndex}-som`}
                        style={{ display: "flex", flexWrap: "wrap" }}
                        dangerouslySetInnerHTML={{
                          __html: `<span style="display:inline;margin:1px;">${variant?.productVariantValue?.productVariantType?.name}: ${variant?.productVariantValue?.name}</span>`,
                        }}
                      ></div>
                    ))}
                </div>
              )}
            <ReactBarcode value={item.productBarcode} height={80} />
          </div>
        </Card>
      ));
    })
    .flat(); // Explicitly flatten the array of arrays

  return (
    <>
      <Drawer
        title="Print Selected Items"
        placement="bottom"
        height="100%"
        onClose={onClose}
        open={open}
      >
        {/* Preview content */}
        <div
          ref={printRef}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          {renderedCards}
        </div>
        {/* Action buttons */}
        <div
          style={{
            position: "fixed",
            bottom: "15px",
            left: "40%",
            background: "white",
            zIndex: 5,
            padding: "10px 20px",
            boxShadow: "0px 4px 12px rgba(0,0,0,0.1)",
            borderRadius: "10px",
          }}
        >
          <Button
            icon={<CloseCircleOutlined />}
            style={{ margin: "0 10px" }}
            onClick={onClose}
          />
          <Button
            icon={<PrinterOutlined />}
            style={{ margin: "0 10px" }}
            onClick={handlePrint}
          />
        </div>
      </Drawer>
      {/* Modal with backdrop during the mock API call */}
      <ConfirmPrintedItems
        open={confirmPrintedBarcodesModalOpen}
        onClose={handleCloseConfirmPrintedBarcodesModal}
        products={selectedItems}
      />
    </>
  );
};

export default PrintBarcodesDrawer;
