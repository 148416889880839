import { useQueryClient } from "@tanstack/react-query";
import {
  Alert,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Switch,
} from "antd";
import { useEffect, useState } from "react";
import {
  useUsersServiceGetRoles,
  useUsersServiceGetUsersKey,
} from "api/queries";
import { useUsersServiceUpdateUser } from "api/queries";
import QueryString from "qs";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import PaymentAccountSelect from "components/common/PaymentAccountSelect";

export default function EditUserDialog(props: any) {
  const [error, setError] = useState<string | null>(null);
  const queryClient = useQueryClient();
  const [formRef] = Form.useForm();

  const {
    mutate: updateUserMutation,
    isPending,
    isError,
  } = useUsersServiceUpdateUser({
    onError: (error: any) => {
      setError(error?.body?.message || error?.message || error);
    },
    onSuccess: async (data) => {
      setError(null);
      await queryClient.invalidateQueries({
        queryKey: [useUsersServiceGetUsersKey],
        refetchType: "all",
      });
      props.onClose();
      formRef.resetFields();
    },
  });

  const {
    data: roleData,
    isLoading,
    isRefetching,
  } = useUsersServiceGetRoles({
    filters: "",
    pagination: QueryString.stringify({ limit: 100, offset: 0 }),
    sort: "",
  });

  // Phone input state
  const [phone, setPhone] = useState<string>("");
  const [country, setCountry] = useState<string>("us"); // Default country

  // Ensure phone number always includes "+"
  const formatPhoneNumber = (phoneNumber: string) => {
    if (!phoneNumber) return ""; // Allow empty input without breaking the component
    return phoneNumber.startsWith("+") ? phoneNumber : `+${phoneNumber}`;
  };

  useEffect(() => {
    if (props.defaultValues) {
      let formattedPhone = formatPhoneNumber(
        props.defaultValues.userPhone || ""
      );
      setPhone(formattedPhone);

      formRef.setFieldsValue({
        userName: props.defaultValues.userName,
        userFullName: props.defaultValues.userFullName,
        userEmail: props.defaultValues.userEmail,
        userPassword: props.defaultValues.userPassword,
        userPhone: formattedPhone, // Ensures proper format
        userAddress: props.defaultValues.userAddress,
        isActive: props.defaultValues.isActive,
        role: props.defaultValues.role.idRole,
        salary: props.defaultValues.salary,
        accountId: props.defaultValues.account,
      });
    } else {
      formRef.resetFields();
    }
  }, [props.isOpen]);

  // Prevent full reset when using backspace
  const handlePhoneChange = (value: string, countryData: any) => {
    if (value === "" || value === "+") {
      setPhone(""); // Allow user to fully clear input
    } else {
      setPhone(value);
    }
    setCountry(countryData?.countryCode || "us");
  };

  const handleSubmit = (values: any) => {
    updateUserMutation({
      userId: props.defaultValues.idUser,
      requestBody: {
        userName: values.userName || props.defaultValues.userName,
        userFullName: values.userFullName || props.defaultValues.userFullName,
        userEmail: values.userEmail || props.defaultValues.userEmail,
        userPassword: values.userPassword || props.defaultValues.userPassword,
        userPhone: phone || "",
        userAddress: values.userAddress || props.defaultValues.userAddress,
        isActive: values.isActive ?? props.defaultValues.isActive,
        userRole: values.role || props.defaultValues.role.idRole,
        accountId: values?.accountId || null,
        salary: values?.salary || 0,
      },
    });
  };

  return (
    <Modal
      open={props.isOpen}
      onCancel={props.onClose}
      title="Edit User"
      onOk={formRef.submit}
      okButtonProps={{ loading: isPending }}
      cancelButtonProps={{ disabled: isPending }}
      destroyOnClose
    >
      <Form
        form={formRef}
        layout="vertical"
        onFinish={handleSubmit}
        name="edit-user-form"
      >
        <Row gutter={16}>
          {isError && (
            <Col xs={24}>
              <Alert
                type="error"
                closable
                message="Error"
                description={error}
              />
            </Col>
          )}

          <Col xs={24} md={12}>
            <Form.Item
              name="userFullName"
              label="Full Name"
              rules={[{ required: true, message: "Full name is required" }]}
            >
              <Input placeholder="Enter full name" />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              name="userName"
              label="Username"
              rules={[{ required: true, message: "User name is required" }]}
            >
              <Input placeholder="Enter username" />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              name="userEmail"
              label="Email"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Valid email is required",
                },
              ]}
            >
              <Input placeholder="Enter email" />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              label="Phone Number"
              rules={[{ required: true, message: "Phone number is required" }]}
            >
              <PhoneInput
                country={country}
                enableSearch
                value={phone} // Controlled input
                onChange={handlePhoneChange}
                inputStyle={{ width: "100%" }}
                placeholder="Enter phone number"
                countryCodeEditable={false}
                isValid={(inputNumber: string) => {
                  return inputNumber.length >= 7 || inputNumber === "";
                }}
              />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item
              name="userAddress"
              label="Address"
              rules={[{ required: true, message: "Address is required" }]}
            >
              <Input.TextArea placeholder="Enter address" rows={2} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              name="isActive"
              label="Active Status"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              name="role"
              label="Role"
              rules={[{ required: true, message: "Role is required" }]}
            >
              <Select
                loading={isLoading || isRefetching}
                options={roleData?.items?.map((role) => ({
                  value: role.idRole,
                  label: role.roleName,
                }))}
                placeholder="Select a role"
                allowClear
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              label="Account"
              name="accountId"
              required={false}
              validateTrigger="onChange"
            >
              <PaymentAccountSelect isForm />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            {/*salary*/}
            <Form.Item
              hasFeedback
              label="Salary"
              name="salary"
              validateTrigger="onChange"
              required={false}
            >
              <InputNumber placeholder="Salary" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
