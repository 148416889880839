import {
  Button,
  Card,
  Col,
  Descriptions,
  List,
  Row,
  Skeleton,
  Table,
  Typography,
} from "antd";
import { IGetPurchaseOrderDetailsResponse } from "api/requests";
import dayjs from "dayjs";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import PurchaseOrderStatusTag from "./PurchaseOrderStatusTag";
import PurchaseOrderDeliveryStatusTag from "./PurchaseOrderDeliveryStatusTag";
import { QueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { useEffect } from "react";

interface IPurchaseOrderDetailsSegmentProps {
  data?: IGetPurchaseOrderDetailsResponse;
  loading?: boolean;
  queryClient?: QueryClient;
}
export default function PurchaseOrderDetailsSegment({
  data,
  loading,
}: IPurchaseOrderDetailsSegmentProps) {
  const nav = useNavigate();

  const company = useSelector((state: any) => state.company);

  const descriptionItems = [
    {
      label: "Purchase Order Subject",
      children: data?.subject || "-",
    },
    {
      label: "Status",
      children: <PurchaseOrderStatusTag status={data?.status} />,
    },
    {
      label: "Delivery Status",
      children: (() => {
        return (
          <PurchaseOrderDeliveryStatusTag
            deliveryStatus={data?.deliveryStatus}
            deliveryDate={data?.purchaseOrderDeliveryDate}
            status={data?.status}
          />
        );
      })(),
    },
    {
      label: "Purchase Order Number",
      children: data?.purchaseOrderNumber || "-",
    },
    {
      label: "Purchase Order Date",
      children: data?.purchaseOrderDate
        ? dayjs(data?.purchaseOrderDate).format("DD/MM/YYYY HH:mm")
        : "-",
    },
    {
      label: "Purchase Order Delivery Date",
      children: data?.purchaseOrderDeliveryDate
        ? dayjs(data?.purchaseOrderDeliveryDate).format("DD/MM/YYYY HH:mm")
        : "-",
    },
    {
      label: "Purchase Order Notes",
      children: data?.purchaseOrderNotes || "-",
    },
    {
      label: "Vendor Name",
      children: data?.vendor?.vendorName || "-",
    },
    {
      label: "Vendor Email",
      children: data?.vendor?.email || "-",
    },
    {
      label: "Vendor Phone",
      children: data?.vendor?.phone || "-",
    },
    {
      label: "Subtotal",
      children: `${data?.subtotal || 0} ${company.currencySymbol}`,
    },
    {
      label: "Discount",
      children: `${data?.discountTotal || 0} ${company.currencySymbol}`,
    },
    {
      label: "Tax",
      children: `${data?.taxTotal || 0} ${company.currencySymbol}`,
    },
    {
      label: "Shipping",
      children: `${data?.shippingCharge || 0} ${company.currencySymbol}`,
    },
    {
      label: "Total",
      children: `${data?.totalAmount || 0} ${company.currencySymbol}`,
    },
  ];

  useEffect(() => {
    if (data?.exchangeRateAmount != 1) {
      descriptionItems.push({
        label: "Exchange Rate",
        children: `1${company.currencySymbol} = ${data?.exchangeRateAmount} ${
          data?.currency?.currencySymbol || company.currencySymbol
        }`,
      });

      descriptionItems.push({
        label: "Total in PO Currency",
        children: `${(data?.totalAmount || 0) * (data?.exchangeRateAmount || 1)}
         ${data?.currency?.currencySymbol || company.currencySymbol}`,
      });
    }
  }, [data]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5, delay: 0 }}
    >
      <Row>
        <Col xs={24}>
          <Skeleton loading={!!loading} active>
            <Descriptions
              style={{ width: "100%", margin: "2rem 0" }}
              layout="vertical"
              items={descriptionItems}
            />
          </Skeleton>
        </Col>

        <Col xs={24} style={{ margin: "0.5rem 0" }}>
          <Typography.Title level={4} style={{ marginBottom: "0.5rem" }}>
            Purchase Order Items
          </Typography.Title>
          <Card size="small" bordered={false} style={{ padding: "0.5rem" }}>
            <Table
              size="small"
              loading={!!loading}
              dataSource={data?.purchaseOrderItems}
              pagination={false}
              rowKey={(record: any, index: any) => index}
              rowClassName={(record, index) => (index % 2 === 0 ? "zebra" : "")}
              columns={[
                {
                  title: "Product",
                  dataIndex: ["product", "productName"],
                  key: "productName",
                  render: (text: any) => (
                    <Typography.Text strong>{text}</Typography.Text>
                  ),
                },
                {
                  title: "Details",
                  key: "details",
                  render: (_: any, item: any) => (
                    <Typography.Text type="secondary">
                      {item.quantity} x {item.unitPrice}{" "}
                      {company.currencySymbol}
                    </Typography.Text>
                  ),
                },
                {
                  title: "Total",
                  key: "totalPrice",
                  render: (_: any, item: any) => (
                    <Typography.Text>
                      {item.totalPrice} {company.currencySymbol}
                    </Typography.Text>
                  ),
                },
                {
                  title: "Action",
                  key: "action",
                  render: (_: any, item: any) => (
                    <Button
                      type="link"
                      onClick={() =>
                        nav(`/inventory/products/${item.product?.idProduct}`)
                      }
                    >
                      View
                    </Button>
                  ),
                },
              ]}
            />
          </Card>
        </Col>
      </Row>
    </motion.div>
  );
}
