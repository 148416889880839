import React, { useState } from "react";
import { Popover, InputNumber, Button, Space, message } from "antd";
import { useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import {
  useInventoryServiceGetInventoryStockKey,
  useInventoryServiceQuickUpdateProduct,
} from "api/queries";

interface EditableProductCostCellProps {
  productId: number;
  productCost: number;
}

const EditableProductCostCell: React.FC<EditableProductCostCellProps> = ({
  productId,
  productCost,
}) => {
  const [editing, setEditing] = useState(false);
  const [cost, setCost] = useState<number>(productCost);
  const queryClient = useQueryClient();
  const company = useSelector((state: any) => state.company);

  const { mutate, isPending } = useInventoryServiceQuickUpdateProduct({
    onSuccess: async () => {
      message.success("Product cost updated successfully");
      await queryClient.invalidateQueries({
        queryKey: [useInventoryServiceGetInventoryStockKey],
      });
      setEditing(false);
    },
    onError: (error: any) => {
      message.error(`Error updating product cost: ${error.message}`);
    },
  });

  const handleSave = () => {
    if (cost === productCost) {
      message.info("No changes detected.");
      setEditing(false);
      return;
    }
    mutate({
      idProduct: productId,
      requestBody: {
        productCost: cost,
      },
    });
  };

  const popoverContent = (
    <div style={{ width: "250px" }}>
      <Space direction="vertical" size="small" style={{ width: "100%" }}>
        <InputNumber
          min={0}
          value={cost}
          onChange={(value) => setCost(value ?? 0)}
          // No formatter so that the currency symbol is not displayed in the input
          parser={(value) => {
            if (!value) return 0;
            const parsed = parseFloat(value.replace(/(,*)/g, ""));
            return isNaN(parsed) ? 0 : parsed;
          }}
          style={{ width: "100%" }}
        />
        <Space>
          <Button size="small" onClick={() => setEditing(false)}>
            Cancel
          </Button>
          <Button
            size="small"
            type="primary"
            onClick={handleSave}
            loading={isPending}
          >
            Save
          </Button>
        </Space>
      </Space>
    </div>
  );

  return (
    <Popover
      content={popoverContent}
      trigger="click"
      open={editing}
      onOpenChange={(visible) => setEditing(visible)}
    >
      <div style={{ cursor: "pointer" }}>
        {`${company.currencySymbol} ${(productCost || 0).toFixed(2)}`}
      </div>
    </Popover>
  );
};

export default EditableProductCostCell;
