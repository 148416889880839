import { useQueryClient } from "@tanstack/react-query";
import {
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Switch,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import {
  useInventoryServiceCreateService,
  useInventoryServiceGetServiceCategory,
  useInventoryServiceGetServicesKey,
} from "api/queries";
import AccountSelect from "components/common/AccountSelect";
import queryString from "query-string";

export const CreateServiceModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  const queryClient = useQueryClient();

  const { mutate, isPending } = useInventoryServiceCreateService({
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [useInventoryServiceGetServicesKey],
        refetchType: "all",
      });
      form.resetFields();
      onClose();
    },
    onError: (error: any) => {
      notification.error({
        message: "Failed to create service",
        description:
          error?.body?.message ||
          error?.response?.data?.message ||
          error?.message ||
          "An unexpected error occurred.",
      });
    },
  });

  const { data: serviceCategoryData } = useInventoryServiceGetServiceCategory({
    filters: "",
    pagination: queryString.stringify({ limit: 100, offset: 0 }),
    sort: "",
  });

  const [form] = Form.useForm();

  const handleSubmit = async (values: any) => {
    await mutate({
      requestBody: {
        serviceName: values.serviceName,
        description: values.description,
        serviceCategory: values.serviceCategory,
        isActive: values.isActive,
        defaultAccount: values.account,
        unitPrice: values.unitPrice,
      },
    });
  };

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      title="Create Service"
      okText="Create"
      cancelText="Cancel"
      onOk={() => form.submit()}
      okButtonProps={{ form: "createServiceForm", loading: isPending }}
      cancelButtonProps={{ disabled: isPending }}
    >
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Row gutter={16}>
          <Col xs={12}>
            <Form.Item
              label="Service Name"
              name="serviceName"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={12}>
            <Form.Item
              label="Unit price"
              name="unitPrice"
              rules={[{ required: true }]}
            >
              <Input type="number" />
            </Form.Item>
          </Col>

          <Col xs={12}>
            <Form.Item
              label="Service Category"
              name="serviceCategory"
              rules={[{ required: true }]}
            >
              <Select
                options={
                  serviceCategoryData?.items
                    ? serviceCategoryData?.items?.map((d) => ({
                        label: d.serviceCategoryName,
                        value: d.idServiceCategory,
                      }))
                    : []
                }
              />
            </Form.Item>
          </Col>

          <Col xs={12}>
            <Form.Item
              label="Account"
              name="account"
              rules={[{ required: true }]}
            >
              <AccountSelect />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item
              label="Service Description"
              name="description"
              rules={[{ required: true }]}
            >
              <TextArea />
            </Form.Item>
          </Col>

          <Col xs={12}>
            <Form.Item
              label="Is Active"
              name="isActive"
              valuePropName="checked"
            >
              <Switch defaultChecked={true} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
