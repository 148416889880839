import React from "react";
import { Modal, Form, Input, Button, message, notification } from "antd";
import { useAccountingServiceGetAccountsKey, useAccountingServiceGetChartOfAccounts, useAccountingServiceGetChartOfAccountsKey, useAccountingServiceUpdateAccount } from "api/queries";
import { useQueryClient } from "@tanstack/react-query";

interface EditAccountModalProps {
  isOpen: boolean;
  onClose: () => void;
  accountName: string;
  accountId: string;
}

const EditAccountModal: React.FC<EditAccountModalProps> = ({
  isOpen,
  onClose,
  accountName,
  accountId,
}) => {
  const [form] = Form.useForm();

  const { mutate: updateAccountMutation, isPending } =
    useAccountingServiceUpdateAccount();

  const queryClient = useQueryClient();

  const handleUpdateAccount = async () => {
    try {
      const values = await form.validateFields();
      updateAccountMutation(
        {
          accountId,
          requestBody: {
            accountName: values.accountName,
          },
        },
        {
          onSuccess: async () => {
            await queryClient.invalidateQueries({
              queryKey: [useAccountingServiceGetChartOfAccountsKey],
            })
            message.success("Account updated successfully");
            onClose();
          },
          onError: (error: any) => {
            notification.error({
              description:
                error?.body?.message ||
                error?.response?.data?.message ||
                error?.message ||
                "An unexpected error occurred.",
              type: "error",
              message: "Failed to update account",
            });
          },
        }
      );
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  return (
    <Modal
      title="Edit Account"
      open={isOpen}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleUpdateAccount}
          loading={isPending}
        >
          Save Changes
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" initialValues={{ accountName }}>
        <Form.Item
          label="Account Name"
          name="accountName"
          rules={[{ required: true, message: "Please enter the account name" }]}
        >
          <Input placeholder="Enter account name" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditAccountModal;
