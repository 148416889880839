import React, { useEffect, useState } from "react";
import {
  Select,
  Row,
  Col,
  Form,
  Input,
  Button,
  Card,
  Spin,
  Collapse,
} from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import queryString from "qs";
import CountrySelect from "components/common/CountrySelect";
import { useCustomerServiceGetCustomers } from "api/queries";
import { ICustomer } from "api/requests";
import { FormInstance } from "antd";

export interface ExtendedCustomer extends ICustomer {
  customerName: string;
  country?: string;
  address?: string;
  phone?: string;
  email?: string;
  website?: string;
  description?: string;
}

interface IntegratedCustomerSelectProps {
  /** Parent form instance. This makes the inline customer fields part of the parent form. */
  form: FormInstance;
  /** The currently selected customer value */
  value?: ExtendedCustomer;
  /** Callback when the customer is updated or selected */
  onChange?: (customer: ExtendedCustomer) => void;
  /** Additional props to pass to the underlying Select component */
  selectProps?: Partial<React.ComponentProps<typeof Select>>;
}

const IntegratedCustomerSelect: React.FC<IntegratedCustomerSelectProps> = ({
  form,
  value,
  onChange,
  selectProps,
}) => {
  // Local state for search/filter and new customer mode.
  const [searchText, setSearchText] = useState("");
  const [filters, setFilters] = useState("");
  const [isNewCustomer, setIsNewCustomer] = useState(false);
  const [selectedCustomer, setSelectedCustomer] =
    useState<ExtendedCustomer | null>(value || null);
  const [collapseActiveKeys, setCollapseActiveKeys] = useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);

  // Query customers from your API based on filters.
  const { data, isLoading, refetch } = useCustomerServiceGetCustomers({
    filters,
    pagination: "",
    sort: "",
  });

  // Update filters when search text changes.
  const handleSearch = (value: string) => {
    setSearchText(value);
    setFilters(
      value
        ? queryString.stringify({
            $or: [
              { customerName: { $ilike: `${value}%` } },
              { phone: { $ilike: `${value}%` } },
            ],
          })
        : ""
    );
  };

  useEffect(() => {
    refetch();
  }, [filters, refetch]);

  // Build options from fetched customer data and add an "Add New Customer" option.
  const options: {
    value: string | number;
    label: React.ReactNode;
    key: string;
  }[] = (data?.items ?? []).map((customer: ICustomer) => ({
    value: customer.idCustomer,
    label: customer.customerName,
    key: customer.idCustomer + "-customer-select",
  }));

  if (searchText) {
    options.push({
      value: "add_new",
      label: <span style={{ fontStyle: "italic" }}>Add New Customer</span>,
      key: "add_new",
    });
  }

  // Handle selection from the dropdown.
  const handleSelect = (selectedValue: any) => {
    if (selectedValue === "add_new") {
      setIsNewCustomer(true);
      setSelectedCustomer(null);
      setCollapseActiveKeys(["1"]); // open accordion
      const newCustomer = {
        idCustomer: 0,
        customerName: "",
        country: "",
        address: "",
        phone: "",
        email: "",
        website: "",
        description: "",
      };
      form.setFieldsValue({ customer: newCustomer });
      onChange && onChange(newCustomer);
    } else {
      const customer = (data?.items ?? []).find(
        (c: ICustomer) => c.idCustomer === selectedValue
      );
      if (customer) {
        const extendedCustomer: ExtendedCustomer = {
          ...customer,
          idCustomer: customer.idCustomer,
          customerName: customer.customerName,
          country: (customer as any)?.country || "",
          address: (customer as any)?.address || "",
          phone: (customer as any)?.phone || "",
          email: (customer as any)?.email || "",
          website: (customer as any)?.website || "",
          description: (customer as any)?.description || "",
        };
        setSelectedCustomer(extendedCustomer);
        setIsNewCustomer(false);
        setCollapseActiveKeys(["1"]); // open accordion
        onChange && onChange(extendedCustomer);
        form.setFieldsValue({ customer: extendedCustomer });
      }
    }
  };

  // Reset helper.
  const handleReset = () => {
    setSearchText("");
    setFilters("");
    setSelectedCustomer(null);
    setIsNewCustomer(false);
    setCollapseActiveKeys([]); // collapse details
    form.resetFields();
    setError(null);
    onChange &&
      onChange({
        idCustomer: 0,
        customerName: "",
        country: "",
        address: "",
        phone: "",
        email: "",
        website: "",
        description: "",
      });
  };

  // Determine if extra details should be displayed
  const showExtraDetails =
    isNewCustomer || (selectedCustomer && !isNewCustomer);

  return (
    <Card bordered style={{ marginBottom: 16, padding: "8px" }}>
      <Row gutter={[16, 16]}>
        {/* Customer Search and Select */}
        <Col span={24}>
          <Form.Item label="Customer">
            <Select
              value={selectedCustomer ? selectedCustomer.idCustomer : undefined}
              onChange={handleSelect}
              showSearch
              allowClear
              loading={isLoading}
              placeholder="Search customer by name"
              notFoundContent={
                isLoading ? <Spin size="small" /> : "No customers found"
              }
              filterOption={false}
              options={options}
              onSearch={handleSearch}
              style={{ width: "100%" }}
              {...selectProps}
            />
          </Form.Item>
        </Col>

        {/* Extra details in a collapsible accordion */}
        {showExtraDetails && (
          <Col span={24}>
            <Collapse
              activeKey={collapseActiveKeys}
              onChange={(keys) =>
                setCollapseActiveKeys(typeof keys === "string" ? [keys] : keys)
              }
              ghost
            >
              <Collapse.Panel header="Customer Details" key="1">
                {/* Hidden field for idCustomer */}
                <Form.Item
                  name={["customer", "idCustomer"]}
                  initialValue={0}
                  hidden
                >
                  <Input />
                </Form.Item>
                <Row gutter={16}>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      label="Customer Name"
                      name={["customer", "customerName"]}
                      rules={[
                        {
                          required: true,
                          message: "Please enter a customer name",
                        },
                      ]}
                    >
                      <Input placeholder="Customer name" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Form.Item label="Country" name={["customer", "country"]}>
                      <CountrySelect
                        withForm={true}
                        onChange={(val: string) => {
                          const current = form.getFieldValue("customer") || {};
                          form.setFieldsValue({
                            customer: { ...current, country: val },
                          });
                          onChange &&
                            onChange({
                              ...current,
                              country: val,
                              idCustomer: current.idCustomer || 0,
                            });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      label="Address"
                      name={["customer", "address"]}
                      required={false}
                    >
                      <Input placeholder="Address" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      label="Phone"
                      name={["customer", "phone"]}
                      required={true}
                    >
                      <Input placeholder="Phone" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      label="Email"
                      name={["customer", "email"]}
                      rules={[
                        {
                          type: "email",
                          message: "The input is not valid E-mail",
                        },
                      ]}
                      required={false}
                    >
                      <Input placeholder="Email" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Form.Item label="Website" name={["customer", "website"]}>
                      <Input placeholder="Website" />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="Description"
                      name={["customer", "description"]}
                    >
                      <Input.TextArea rows={3} placeholder="Description" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify="end">
                  <Col>
                    <Button icon={<ReloadOutlined />} onClick={handleReset}>
                      Reset
                    </Button>
                  </Col>
                </Row>
              </Collapse.Panel>
            </Collapse>
          </Col>
        )}
      </Row>
    </Card>
  );
};

export default IntegratedCustomerSelect;
