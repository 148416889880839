import {
  useReparationServiceGetReparationCase,
  useReparationServiceGetReparationCaseKey,
} from "api/queries";
import { useParams } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { Col, Divider, Row, Segmented, Typography } from "antd";
import RCViewHeaderOptions from "./RCViewHeaderOptions";
import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { RCDetailsSegment } from "./RCDetailsSegment";
import RCHistory from "./RCHistory";
import { RCReviewRemarks } from "./RCReviewRemarks";
import RCPricing from "./RCPricing";

export default function ReparationCaseDetails() {
  const [currentOption, setCurrentOption] = useState<
    | "details"
    | "history"
    | "review-remarks"
    | "deliver-notes"
    | "payments"
    | "receipts"
    | "pricing"
  >("details");

  const params = useParams();
  const queryClient = useQueryClient();

  const { data, isLoading } = useReparationServiceGetReparationCase(
    {
      id: +(params?.id || "0"),
    },
    [useReparationServiceGetReparationCaseKey]
  );

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5, delay: 0 }}
    >
      <Row>
        <Col xs={12}>
          <Typography.Title level={2} style={{ margin: 0 }}>
            {data?.data?.idReparationCase
              ? "REP" + data?.data?.idReparationCase.toString().padStart(5, "0")
              : ""}
          </Typography.Title>
        </Col>

        {data?.data?.reparationCaseStatus && (
          <RCViewHeaderOptions
            status={data?.data?.reparationCaseStatus as any}
            queryClient={queryClient}
            caseId={data?.data?.idReparationCase}
            data={data?.data}
          />
        )}

        <Col xs={24}>
          <Divider style={{ opacity: 0.5 }} />
        </Col>

        <Col xs={24}>
          <Segmented
            options={[
              { label: "Details", value: "details" },
              { label: "History", value: "history" },
              { label: "Remarks", value: "review-remarks" },
              { label: "Pricing", value: "pricing" },
            ]}
            value={currentOption}
            onChange={(value: any) => setCurrentOption(value)}
          />
        </Col>

        <Col xs={24}>
          <AnimatePresence>
            {currentOption === "details" && (
              <RCDetailsSegment data={data?.data} loading={isLoading} />
            )}
            {currentOption === "history" && <RCHistory data={data?.data} />}

            {currentOption === "review-remarks" && (
              <RCReviewRemarks data={data?.data} />
            )}

            {currentOption === "pricing" && <RCPricing data={data?.data} />}
          </AnimatePresence>
        </Col>
      </Row>
    </motion.div>
  );
}
