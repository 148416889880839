import { QueryClient } from "@tanstack/react-query";
import {
  Col,
  Dropdown,
  notification,
  Space,
  Button,
  Form,
  Modal,
  Row,
} from "antd";
import { useReparationServiceUpdateCaseStatus } from "api/queries";
import { useReparationServiceGetReparationCaseKey } from "api/queries";
import { IReparationCaseDetails } from "api/requests";
import { FC, useState } from "react";
import { RecordRCPayment } from "./RecordRCPayment";
import { CreateRemarkModal } from "./CreateRemarkModal";
import LocationSelect from "components/common/LocationSelect";
import PaymentAccountSelect from "components/common/PaymentAccountSelect";
import { CancelRCModal } from "./CancelRCModal";
import InvoicePriceDrawer from "pages/Sales/Invoice/InvoiceDetails/InvoicePrintDrawer";
import UserPaymentAccountSelect from "components/common/UserPaymentAccountSelect";

enum ReparationCaseStatus {
  PENDING = "PENDING",
  CHECKING = "CHECKING",
  REVISED = "REVISED",
  PRICED = "PRICED",
  REPAIRING = "REPAIRING",
  COMPLETED = "COMPLETED",
  PAID = "PAID",
  CANCELLED = "CANCELLED",
}

interface IRCViewHeaderOptionsProps {
  status: ReparationCaseStatus;
  queryClient: QueryClient;
  caseId: number;
  data: IReparationCaseDetails;
}

const RCViewHeaderOptions: FC<IRCViewHeaderOptionsProps> = ({
  status,
  queryClient,
  caseId,
  data,
}) => {
  const [paymentModalVisible, setPaymentModalVisible] = useState(false);
  const [paymentAmount, setPaymentAmount] = useState("");
  const [remarkModalVisible, setRemarkModalVisible] = useState(false);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [printDrawerOpen, setPrintDrawerOpen] = useState(false);

  // New states for repair modal
  const [repairModalVisible, setRepairModalVisible] = useState(false);
  const [form] = Form.useForm();

  const { mutate, isPending } = useReparationServiceUpdateCaseStatus({
    onSuccess: () => {
      notification.success({ message: "Status updated successfully" });
      queryClient.invalidateQueries({
        queryKey: [useReparationServiceGetReparationCaseKey],
      });
    },
    onError: (error: any) => {
      notification.error({
        message: "Update failed",
        description:
          error?.body?.message ||
          error?.response?.data?.message ||
          error?.message ||
          "An unexpected error occurred.",
      });
    },
  });

  const statusConfig: Partial<
    Record<
      ReparationCaseStatus,
      {
        buttonText: string;
        nextStatus: ReparationCaseStatus;
      }
    >
  > = {
    [ReparationCaseStatus.PENDING]: {
      buttonText: "Start Checking",
      nextStatus: ReparationCaseStatus.CHECKING,
    },
    [ReparationCaseStatus.CHECKING]: {
      buttonText: "Complete Checking",
      nextStatus: ReparationCaseStatus.REVISED,
    },
    [ReparationCaseStatus.REVISED]: {
      buttonText: "Start Repairing",
      nextStatus: ReparationCaseStatus.REPAIRING,
    },
    [ReparationCaseStatus.REPAIRING]: {
      buttonText: "Complete Repair",
      nextStatus: ReparationCaseStatus.COMPLETED,
    },
    [ReparationCaseStatus.COMPLETED]: {
      buttonText: "Record Payment",
      nextStatus: ReparationCaseStatus.PAID,
    },
  };

  const handleStatusChange = (nextStatus: ReparationCaseStatus) => {
    if (nextStatus === ReparationCaseStatus.PAID) {
      setPaymentModalVisible(true);
    } else if (nextStatus === ReparationCaseStatus.REVISED) {
      setRemarkModalVisible(true);
    } else if (nextStatus === ReparationCaseStatus.REPAIRING) {
      setRepairModalVisible(true);
    } else {
      mutate({
        id: caseId,
        requestBody: {
          status: nextStatus,
        },
      });
    }
  };

  const handlePaymentSubmit = () => {
    notification.info({
      message: "Payment logged",
      description: "Payment details recorded (API integration pending)",
    });
    setPaymentModalVisible(false);
    setPaymentAmount("");
  };

  // Update menu items
  const menuItems = [
    {
      key: "make-payment",
      label: "Make Payment",
      disabled: ![
        ReparationCaseStatus.REPAIRING,
        ReparationCaseStatus.COMPLETED,
      ].includes(status),
      onClick: () => handleStatusChange(ReparationCaseStatus.PAID),
    },
    {
      key: "cancel-case",
      label: "Cancel Case",
      disabled: [
        ReparationCaseStatus.PAID,
        ReparationCaseStatus.CANCELLED,
      ].includes(status),
      onClick: () => setCancelModalVisible(true),
    },
  ];

  const handleOpenPrintDrawer = () => {
    setPrintDrawerOpen(true);
  };

  const handleClosePrintDrawer = () => {
    setPrintDrawerOpen(false);
  };

  const handleCancelCase = (reason: string) => {
    mutate({
      id: caseId,
      requestBody: {
        status: ReparationCaseStatus.CANCELLED,
        cancellationReason: reason,
      },
    });
    setCancelModalVisible(false);
  };

  return (
    <>
      <Col xs={12}>
        <Space align="center" style={{ justifyContent: "end", width: "100%" }}>
          <Dropdown.Button
            menu={{ items: menuItems }}
            type="default"
            onClick={handleOpenPrintDrawer}
            //@ts-ignore
            disabled={!data.invoice}
          >
            Print
          </Dropdown.Button>
          {statusConfig[status] &&
            status !== ReparationCaseStatus.PAID &&
            status !== ReparationCaseStatus.CANCELLED && (
              <Button
                type="primary"
                loading={isPending}
                onClick={() =>
                  handleStatusChange(statusConfig[status]!.nextStatus)
                }
              >
                {statusConfig[status]?.buttonText || "No Action"}
              </Button>
            )}
        </Space>
      </Col>

      {/* Payment Modal */}
      <RecordRCPayment
        open={paymentModalVisible}
        onCancel={() => setPaymentModalVisible(false)}
        onOk={handlePaymentSubmit}
        onClose={() => setPaymentModalVisible(false)}
        paidAmount={data.invoice?.totalPaidAmount || 0}
        invoiceTotal={data.invoice?.invoiceTotalAmount || 0}
        remainingAmount={(data.invoice?.invoiceTotalAmount || 0) - (data.invoice?.totalPaidAmount || 0)}
        caseId={caseId}
      />

      {/* Remark Modal */}
      <CreateRemarkModal
        visible={remarkModalVisible}
        onClose={() => setRemarkModalVisible(false)}
        caseId={caseId.toString()}
      />

      {/* New Repair Modal */}
      <Modal
        title="Start Repairing"
        open={repairModalVisible}
        onCancel={() => {
          setRepairModalVisible(false);
          form.resetFields();
        }}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              mutate({
                id: caseId,
                requestBody: {
                  status: ReparationCaseStatus.REPAIRING,
                  accountId: values.account,
                  locationId: values.location,
                },
              });
              setRepairModalVisible(false);
            })
            .catch((error) => {
              console.error("Validation failed:", error);
            });
        }}
        confirmLoading={isPending}
      >
        <Form form={form} layout="vertical">
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label="Account"
                name="account"
                rules={[
                  { required: true, message: "Please select an account" },
                ]}
              >
                <UserPaymentAccountSelect
                  isForm={true}
                  selectProps={{ style: { width: "100%" } }}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Location"
                name="location"
                rules={[
                  { required: true, message: "Please select a location" },
                ]}
              >
                <LocationSelect
                  isForm={true}
                  selectProps={
                    {
                      // Add any additional props needed for location select
                    }
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      <CancelRCModal
        visible={cancelModalVisible}
        onCancel={() => setCancelModalVisible(false)}
        onConfirm={handleCancelCase}
      />

      <InvoicePriceDrawer
        open={printDrawerOpen}
        onClose={handleClosePrintDrawer}
        //@ts-ignore
        data={data.invoice as any}
      />
    </>
  );
};

export default RCViewHeaderOptions;
