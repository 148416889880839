import { QueryClient } from "@tanstack/react-query";
import { Button, Col, Dropdown, message, notification, Space } from "antd";
import {
  useInventoryServiceApprovePurchaseOrder,
  useInventoryServiceGetPurchaseOrderKey,
} from "api/queries";
import {
  IPurchaseOrder,
  PurchaseOrderDeliveryStatus,
  PurchaseOrderStatus,
} from "api/requests";
import { FC, useState } from "react";
import CreatePaymentModal from "./CreatePaymentModal";
import UpdateDeliveryStatusModal from "./UpdateDeliveryStatusModal";
import CreateDeliveryNoteModal from "./CreateDeliveryNoteModal";

interface IPOViewHeaderOptionsProps {
  status: PurchaseOrderStatus;
  deliveryStatus?: PurchaseOrderDeliveryStatus;
  queryClient: QueryClient;
  purchaseOrderId: number;
  data: IPurchaseOrder;
}

const POViewHeaderOptions: FC<IPOViewHeaderOptionsProps> = ({
  status,
  deliveryStatus,
  queryClient,
  purchaseOrderId,
  data,
}) => {
  const [createPaymentModalOpen, setCreatePaymentModalOpen] = useState(false);
  const [updateDeliveryStatusModalOpen, setUpdateDeliveryStatusModalOpen] =
    useState(false);
  const [CreateDeliveryNoteModalOpen, setCreateDeliveryNoteModalOpen] =
    useState(false);

  const { mutate: approvePurchaseOrder, isPending: approvePending } =
    useInventoryServiceApprovePurchaseOrder({
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [useInventoryServiceGetPurchaseOrderKey],
        });
        message.success("Purchase order approved successfully");
      },
      onError: (error: any) => {
        notification.error({
          message: "Failed to approve purchase order",
          description:
            error?.body?.message ||
            error?.response?.data?.message ||
            error?.message,
        });
      },
    });

  const handleApprove = () => {
    approvePurchaseOrder({
      purchaseOrderId,
    });
  };

  const handleCreatePayment = () => {
    setCreatePaymentModalOpen(true);
  };

  const handleCloseCreatePaymentModal = () => {
    setCreatePaymentModalOpen(false);
  };

  const handleUpdateDeliveryStatus = () => {
    setUpdateDeliveryStatusModalOpen(true);
  };

  const handleCloseUpdateDeliveryStatusModal = () => {
    setUpdateDeliveryStatusModalOpen(false);
  };

  const handleCreateDeliveryNoteModalOpen = () => {
    setCreateDeliveryNoteModalOpen(true);
  };

  const handleCloseCreateDeliveryNoteModal = () => {
    setCreateDeliveryNoteModalOpen(false);
  };

  return (
    <Col xs={12}>
      <Space
        align="center"
        direction="horizontal"
        // size="middle"
        style={{ justifyContent: "end", width: "100%" }}
      >
        <Dropdown.Button
          menu={{
            items: [
              {
                key: "create-delivery-note-key",
                label: "Create Delivery Note",
                onClick: handleCreateDeliveryNoteModalOpen,
                disabled:
                  !!data?.deliveryNote?.idDeliveryNote ||
                  !(data?.status === "APPROVED" ||
                    data?.status === "ON_ROUTE" ||
                    data?.status === "PARTIALLY_PAID" ||
                    data?.status === "FULLY_PAID" || data?.status !== "CANCELLED"),
              },
              // {
              //   key: "create-receipt-key",
              //   label: "Create Receipt",
              //   onClick: () => console.log("create receipt"),
              // },
              // {
              //   key: "duplicate-purchase-order-key",
              //   label: "Duplicate Purchase Order",
              //   onClick: () => console.log("duplicate purchase order"),
              // },
              {
                key: "update-delivery-status-key",
                label: "Update Delivery Status",
                onClick: handleUpdateDeliveryStatus,
                disabled: deliveryStatus === "DELIVERED",
              },
            ],
          }}
          type="default"
        >
          Print
        </Dropdown.Button>

        {status === "DRAFT" && (
          <Button
            loading={approvePending}
            type="primary"
            onClick={handleApprove}
          >
            Approve
          </Button>
        )}

        {(status === "APPROVED" ||
          status === "ON_ROUTE" ||
          status === "PARTIALLY_PAID") && (
          <Button type="primary" onClick={handleCreatePayment}>
            Create Payment
          </Button>
        )}
      </Space>

      <CreatePaymentModal
        isOpen={createPaymentModalOpen}
        onClose={handleCloseCreatePaymentModal}
        queryClient={queryClient}
        data={data}
      />

      <UpdateDeliveryStatusModal
        isOpen={updateDeliveryStatusModalOpen}
        onClose={handleCloseUpdateDeliveryStatusModal}
        queryClient={queryClient}
        data={data}
      />

      <CreateDeliveryNoteModal
        isOpen={CreateDeliveryNoteModalOpen}
        onClose={handleCloseCreateDeliveryNoteModal}
        queryClient={queryClient}
        data={data}
      />
    </Col>
  );
};

export default POViewHeaderOptions;
