import {
  useInventoryServiceGetPurchaseOrder,
  useInventoryServiceGetPurchaseOrderKey,
} from "api/queries";
import { useParams } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { Col, Divider, Row, Segmented, Typography } from "antd";
import POViewHeaderOptions from "./POViewHeaderOptions";
import { useState } from "react";
import PurchaseOrderDetailsSegment from "./PODetails";
import POHistory from "./POHistory";
import { useQueryClient } from "@tanstack/react-query";
import PurchaseOrderPayment from "./PurhcaseOrderPayment";
import DeliveryNoteView from "./PODeliveryNoteView";

export default function PurchaseOrderDetails() {
  const [currentOption, setCurrentOption] = useState<
    "details" | "history" | "deliver-notes" | "payments" | "receipts"
  >("details");

  const params = useParams();
  const queryClient = useQueryClient();

  const { data, isLoading } = useInventoryServiceGetPurchaseOrder(
    {
      id: +(params?.id || "0"),
    },
    [useInventoryServiceGetPurchaseOrderKey]
  );

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5, delay: 0 }}
    >
      <Row>
        <Col xs={12}>
          <Typography.Title level={2} style={{ margin: 0 }}>
            {data?.purchaseOrderNumber}
          </Typography.Title>
        </Col>

        {data?.status && (
          <POViewHeaderOptions
            status={data?.status}
            deliveryStatus={data?.deliveryStatus}
            queryClient={queryClient}
            purchaseOrderId={data?.idPurchaseOrder}
            data={data}
          />
        )}

        <Col xs={24}>
          <Divider style={{ opacity: 0.5 }} />
        </Col>

        <Col xs={24}>
          <Segmented
            options={[
              { label: "Details", value: "details" },
              { label: "History", value: "history" },
              { label: "Delivery Notes", value: "deliver-notes" },
              { label: "Payments", value: "payments" },
              // { label: "Receipts", value: "receipts" },
            ]}
            value={currentOption}
            onChange={(value: any) => setCurrentOption(value)}
          />
        </Col>

        <Col xs={24}>
          <AnimatePresence>
            {currentOption === "details" && (
              <PurchaseOrderDetailsSegment data={data} loading={isLoading} />
            )}
            {currentOption === "deliver-notes" && (
              <DeliveryNoteView deliveryNote={data?.deliveryNote} />
            )}
            {/* {currentOption === "receipts" && <PurchaseOrderDetailsSegment />} */}
            {currentOption === "payments" && <PurchaseOrderPayment purchaseOrderId={data?.idPurchaseOrder} />}
            {currentOption === "history" && <POHistory data={data} />}
          </AnimatePresence>
        </Col>
      </Row>
    </motion.div>
  );
}
