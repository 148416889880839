import {
  Button,
  Col,
  Input,
  Row,
  Select,
  Form,
  Space,
  DatePicker,
  Slider,
  Switch,
} from "antd";
import ProductCategorySelect from "components/common/ProductCategorySelect";
import { useState } from "react";
import { motion } from "framer-motion";
import dayjs from "dayjs";
import { useInventoryServiceGetProductVariantTypes } from "api/queries";
import BrandSelect from "components/common/BrandSelect";
import AccountSelect from "components/common/AccountSelect";
import SubcategorySelect from "components/common/SubcategorySelect";
import ProductModelSelect from "components/common/ProductModelSelect";
import LocationSelect from "components/common/LocationSelect";
import CustomerSelect from "components/common/CustomerSelect";
export enum FilterType {
  Select = "select",
  MultiSelect = "multi-select",
  Date = "date",
  Text = "text",
  DateRange = "date-range",
  Number = "number",
  Boolean = "boolean",
  Slider = "slider",
  template = "template",
  radio = "radio",
}

export enum FilterTemplate {
  CustomerSelect = "CustomerSelect",
  InspectionTypeSelect = "InspectionTypeSelect",
  InspectionStatusSelect = "InspectionStatusSelect",
  InspectionDateRange = "InspectionDateRange",
  ProductCategorySelect = "ProductCategorySelect",
  ProductSubcategorySelect = "ProductSubcategorySelect",
  RoleSelect = "RoleSelect",
  ProductVariantSelect = "ProductVariantSelect",
  AccountSelect = "AccountSelect",
  BrandSelect = "BrandSelect",
  ProductModelCompatibilitySelect = "ProductModelCompatibilitySelect",
  LocationSelect = "LocationSelect",
}

export interface IFilterItem {
  key: string;
  label: string;
  type: FilterType;
  template?: FilterTemplate;
  options?: { key: string; label: string; value: any }[];
  defaultValue?: any;
  onChange?: (key: string, value: any | any[]) => void;
  value?: any;
  sliderMin?: number;
  sliderMax?: number;
  sliderStep?: number;
}

export default function useFilters(input: IFilterItem[]) {
  const [filtersQuery, setFiltersQuery] = useState<any>({});
  const [showFilters, setShowFilters] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(
    null
  );

  const [form] = Form.useForm();

  const { data: variantData } = useInventoryServiceGetProductVariantTypes({
    filters: "",
    sort: "",
    pagination: "",
  });

  const renderFilterButton = () => {
    return (
      <Button type="primary" onClick={() => setShowFilters(!showFilters)}>
        Filter
      </Button>
    );
  };

  const handleFilterSubmit = (values: any) => {
    const newVals: Record<string, any> = {};
    const extraVals: any[] = [];
    const updatedFiltersQuery: Record<string, any> = {};

    // Extract values and separate extra- ones
    Object.keys(values).forEach((key: string) => {
      const val = values[key];

      if (val !== undefined) {
        if (!key.match(/extra-/)?.length) {
          newVals[key] = val;
        } else {
          extraVals.push(val);
        }
      }

      if (val === undefined) {
        delete newVals[key];
        delete filtersQuery[key];
      }
    });

    // Build up the filters query in one go
    Object.keys(newVals).forEach((key) => {
      const value = newVals[key];
      const selectedItem = input.find((i) => i.key === key);
      if (!selectedItem) return;

      switch (selectedItem.type) {
        case FilterType.template:
          switch (selectedItem.template) {
            case FilterTemplate.LocationSelect:
              updatedFiltersQuery[key] = {
                IdLocation: value,
                quantity: { $gt: 0 },
              };
              break;
            default:
              updatedFiltersQuery[key] = value;
              break;
          }
          break;

        case FilterType.Text:
          updatedFiltersQuery[key] = { $ilike: `%${value}%` };
          break;

        case FilterType.Select:
          updatedFiltersQuery[key] = value;
          break;

        case FilterType.MultiSelect:
          updatedFiltersQuery[key] = { $in: value };
          break;

        case FilterType.DateRange:
          updatedFiltersQuery[key] = {
            $gte: dayjs(value[0]).startOf("day").toDate(),
            $lte: dayjs(value[1]).endOf("day").toDate(),
          };
          break;

        case FilterType.Slider:
          updatedFiltersQuery[key] = {
            $gte: value[0],
            $lte: value[1],
          };
          break;

        case FilterType.Boolean:
          updatedFiltersQuery[key] = value;
          break;

        default:
          break;
      }
    });

    // Handle extra values if present
    if (extraVals.length > 0) {
      updatedFiltersQuery.productVariantValues = {
        productVariantValue: {
          $in: extraVals,
        },
      };
    }

    // ✅ Final setState call - only once
    setFiltersQuery({
      ...filtersQuery,
      ...updatedFiltersQuery,
    });
  };

  // const handleFilterSubmit = (values: any) => {
  //   const newVals: any = {};
  //   const extraVals: any[] = [];

  //   console.log({
  //     filtersQuery,
  //     values,
  //     newVals,
  //   });

  //   Object.keys(values).forEach((key: string) => {
  //     if (values[key] !== undefined && !key.match(/extra-/)?.length) {
  //       newVals[key] = values[key];
  //     }

  //     if (values[key] !== undefined && !!key.match(/extra-/)?.length) {
  //       extraVals.push(values[key]);
  //     }

  //     if (values[key] === undefined) {
  //       if (Object.hasOwn(newVals, key)) {
  //         delete newVals[key];
  //       }
  //       if (Object.hasOwn(filtersQuery, key)) {
  //         delete filtersQuery[key];
  //       }
  //     }
  //   });

  //   Object.keys(newVals).forEach((key) => {
  //     if (values[key] || values[key] === false) {
  //       const selectedItem = input.find((i) => i.key === key);
  //       if (selectedItem) {
  //         switch (selectedItem.type) {
  //           case FilterType.template:
  //             switch (selectedItem.template) {
  //               case FilterTemplate.ProductCategorySelect:
  //                 setFiltersQuery({
  //                   ...filtersQuery,
  //                   [key]: values[key],
  //                 });
  //                 break;
  //               case FilterTemplate.ProductModelCompatibilitySelect:
  //                 setFiltersQuery({
  //                   ...filtersQuery,
  //                   [key]: values[key],
  //                 });
  //                 break;
  //               case FilterTemplate.LocationSelect:
  //                 setFiltersQuery({
  //                   ...filtersQuery,
  //                   [key]: {
  //                     IdLocation: values[key],
  //                     quantity: { $gt: 0 },
  //                   },
  //                 });
  //                 break;
  //               case FilterTemplate.ProductSubcategorySelect:
  //                 setFiltersQuery({
  //                   ...filtersQuery,
  //                   [key]: values[key],
  //                 });
  //                 break;
  //               case FilterTemplate.ProductVariantSelect:
  //                 setFiltersQuery({
  //                   ...filtersQuery,
  //                   [key]: values[key],
  //                 });
  //                 break;
  //               case FilterTemplate.AccountSelect:
  //                 setFiltersQuery({
  //                   ...filtersQuery,
  //                   [key]: values[key],
  //                 });
  //                 break;
  //               case FilterTemplate.BrandSelect:
  //                 console.log("here", values);
  //                 setFiltersQuery({
  //                   ...filtersQuery,
  //                   [key]: values[key],
  //                 });
  //                 break;
  //               case FilterTemplate.CustomerSelect:
  //                 setFiltersQuery({
  //                   ...filtersQuery,
  //                   [key]: values[key],
  //                 });
  //                 break;
  //               default:
  //                 break;
  //             }
  //             break;
  //           case FilterType.Text:
  //             setFiltersQuery({
  //               ...filtersQuery,
  //               [key]: {
  //                 $ilike: `%${values[key]}%`,
  //               },
  //             });
  //             break;
  //           case FilterType.Select:
  //             setFiltersQuery({
  //               ...filtersQuery,
  //               [key]: values[key],
  //             });
  //             break;
  //           case FilterType.MultiSelect:
  //             setFiltersQuery({
  //               ...filtersQuery,
  //               [key]: {
  //                 $in: values[key],
  //               },
  //             });
  //             break;
  //           case FilterType.DateRange:
  //             setFiltersQuery({
  //               ...filtersQuery,
  //               [key]: {
  //                 $gte: dayjs(values[key][0]).startOf("day").toDate(),
  //                 $lte: dayjs(values[key][1]).endOf("day").toDate(),
  //               },
  //             });
  //             break;
  //           case FilterType.Slider:
  //             setFiltersQuery({
  //               ...filtersQuery,
  //               [key]: {
  //                 $gte: values[key][0],
  //                 $lte: values[key][1],
  //               },
  //             });
  //             break;
  //           case FilterType.Boolean:
  //             setFiltersQuery({
  //               ...filtersQuery,
  //               [key]: values[key],
  //             });
  //             break;
  //           default:
  //             break;
  //         }
  //       }
  //     }
  //   });

  //   if (extraVals.length > 0) {
  //     setFiltersQuery({
  //       ...filtersQuery,
  //       productVariantValues: {
  //         productVariantValue: {
  //           $in: extraVals,
  //         },
  //       },
  //     });
  //   }
  // };

  const handleReset = () => {
    form.resetFields();
    setFiltersQuery({});
    setSelectedCategoryId(null);
  };

  const renderFilterItems = () => {
    return (
      <motion.div
        initial={{ opacity: 0, height: 0 }}
        animate={{
          opacity: showFilters ? 1 : 0,
          height: showFilters ? "auto" : 0,
        }}
        transition={{ duration: 0.5 }}
        style={{ overflow: "hidden", marginTop: "1rem" }}
      >
        <Row
          gutter={12}
          style={{
            // maxHeight: showFilters ? "auto" : "0",
            // overflow: "hidden",
            // transition: "all 0.5s ease-in-out",
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
        >
          <Col xs={24}>
            <Form form={form} layout="vertical" onFinish={handleFilterSubmit}>
              <Row gutter={12}>
                {input.map((item) => {
                  switch (item.type) {
                    case FilterType.Text:
                      return (
                        <Col key={item.key} xs={8} sm={8} md={8} lg={8} xl={6}>
                          <Form.Item name={item.key} label={item.label}>
                            <Input />
                          </Form.Item>
                        </Col>
                      );
                    case FilterType.Boolean:
                      return (
                        <Col key={item.key} xs={8} sm={8} md={8} lg={8} xl={6}>
                          <Form.Item name={item.key} label={item.label}>
                            <Switch
                              checkedChildren="Yes"
                              unCheckedChildren="No"
                            />
                          </Form.Item>
                        </Col>
                      );
                    case FilterType.MultiSelect:
                      return (
                        <Col key={item.key} xs={8} sm={8} md={8} lg={8} xl={6}>
                          <Form.Item name={item.key} label={item.label}>
                            <Select options={item.options} mode="multiple" />
                          </Form.Item>
                        </Col>
                      );
                    case FilterType.Select:
                      return (
                        <Col key={item.key} xs={8} sm={8} md={8} lg={8} xl={6}>
                          <Form.Item name={item.key} label={item.label}>
                            <Select options={item.options} />
                          </Form.Item>
                        </Col>
                      );
                    case FilterType.DateRange:
                      return (
                        <Col key={item.key} xs={8} sm={8} md={8} lg={8} xl={6}>
                          <Form.Item name={item.key} label={item.label}>
                            <DatePicker.RangePicker
                              style={{ width: "100%" }}
                              // onChange={(dates, dateStrings) =>
                              //   item.onChange(dates as [Dayjs, Dayjs] | null)
                              // }
                            />
                          </Form.Item>
                        </Col>
                      );
                    case FilterType.Slider:
                      return (
                        <Col key={item.key} xs={8} sm={8} md={8} lg={8} xl={6}>
                          <Form.Item name={item.key} label={item.label}>
                            <Slider
                              // style={{ width: "100%" }}
                              // onChange={(dates, dateStrings) =>
                              //   item.onChange(dates as [Dayjs, Dayjs] | null)
                              // }
                              range
                              max={item.sliderMax || 10000}
                              min={item.sliderMin || 0}
                              step={item.sliderStep || 1}
                            />
                          </Form.Item>
                        </Col>
                      );
                    case FilterType.template:
                      switch (item.template) {
                        case FilterTemplate.ProductCategorySelect:
                          return (
                            <Col
                              key={item.key}
                              xs={8}
                              sm={8}
                              md={8}
                              lg={8}
                              xl={6}
                            >
                              <Form.Item name={item.key} label={item.label}>
                                <ProductCategorySelect
                                  isForm={true}
                                  onChange={(value) => {
                                    // Set the selected category ID when a category is selected
                                    setSelectedCategoryId(value);
                                    if (item.onChange) {
                                      item.onChange(item.key, value);
                                    }
                                  }}
                                  selectProps={{
                                    onClear: () => {
                                      setSelectedCategoryId(null);
                                      if (item.onChange) {
                                        item.onChange(item.key, null);
                                      }
                                    },
                                  }}
                                  value={item.value}
                                />
                              </Form.Item>
                            </Col>
                          );
                        case FilterTemplate.ProductModelCompatibilitySelect:
                          return (
                            <Col
                              key={item.key}
                              xs={8}
                              sm={8}
                              md={8}
                              lg={8}
                              xl={6}
                            >
                              <Form.Item label={item.label} name={item.key}>
                                <ProductModelSelect
                                  isForm={true}
                                  selectProps={{
                                    mode: "multiple",
                                    onClear: () => {
                                      if (item.onChange) {
                                        item.onChange(item.key, null);
                                      }
                                    },
                                  }}
                                />
                              </Form.Item>
                            </Col>
                          );
                        case FilterTemplate.LocationSelect:
                          return (
                            <Col
                              key={item.key}
                              xs={8}
                              sm={8}
                              md={8}
                              lg={8}
                              xl={6}
                            >
                              <Form.Item label={item.label} name={item.key}>
                                <LocationSelect isForm={true} />
                              </Form.Item>
                            </Col>
                          );
                        case FilterTemplate.ProductSubcategorySelect:
                          return (
                            <Col
                              key={item.key}
                              xs={8}
                              sm={8}
                              md={8}
                              lg={8}
                              xl={6}
                            >
                              <Form.Item name={item.key} label={item.label}>
                                <SubcategorySelect
                                  isForm={true}
                                  onChange={(value) => {
                                    if (item.onChange) {
                                      item.onChange(item.key, value);
                                    }
                                  }}
                                  value={item.value}
                                  parentCategoryId={selectedCategoryId}
                                  selectProps={{
                                    onClear: () => {
                                      if (item.onChange) {
                                        item.onChange(item.key, null);
                                      }
                                    },
                                  }}
                                />
                              </Form.Item>
                            </Col>
                          );
                        case FilterTemplate.ProductVariantSelect:
                          return variantData?.items?.map((item) => (
                            <Col
                              key={item.name}
                              xs={8}
                              sm={8}
                              md={8}
                              lg={8}
                              xl={6}
                            >
                              <Form.Item
                                name={"extra-" + item.name}
                                label={item.name}
                                key={item.name}
                              >
                                <Select
                                  key={`select-${item.name}`}
                                  allowClear
                                  options={
                                    item?.productVariantValues?.map(
                                      (value) => ({
                                        value: value.idProductVariant,
                                        label: value.name,
                                      })
                                    ) || []
                                  }
                                />
                              </Form.Item>
                            </Col>
                          ));
                        case FilterTemplate.AccountSelect:
                          return (
                            <Col
                              key={item.key}
                              xs={8}
                              sm={8}
                              md={8}
                              lg={8}
                              xl={6}
                            >
                              <Form.Item
                                name={item.key}
                                label={item.label}
                                key={item.key}
                              >
                                <AccountSelect
                                  isForm={true}
                                  onChange={(value) => {
                                    if (item.onChange) {
                                      item.onChange(item.key, value);
                                    }
                                  }}
                                  value={item.value}
                                />
                              </Form.Item>
                            </Col>
                          );
                        case FilterTemplate.BrandSelect:
                          return (
                            <Col
                              key={item.key}
                              xs={8}
                              sm={8}
                              md={8}
                              lg={8}
                              xl={6}
                            >
                              <Form.Item
                                name={item.key}
                                label={item.label}
                                key={item.key}
                              >
                                <BrandSelect
                                  isForm={true}
                                  onChange={(value) => {
                                    if (item.onChange) {
                                      item.onChange(item.key, value);
                                    }
                                  }}
                                  value={item.value}
                                />
                              </Form.Item>
                            </Col>
                          );
                        case FilterTemplate.CustomerSelect:
                          return (
                            <Col
                              key={item.key}
                              xs={8}
                              sm={8}
                              md={8}
                              lg={8}
                              xl={6}
                            >
                              <Form.Item
                                name={item.key}
                                label={item.label}
                                key={item.key}
                              >
                                <CustomerSelect
                                  isForm={true}
                                  onChange={(value) => {
                                    if (item.onChange) {
                                      item.onChange(item.key, value);
                                    }
                                  }}
                                  value={item.value}
                                />
                              </Form.Item>
                            </Col>
                          );
                        default:
                          return <></>;
                      }
                    default:
                      return <></>;
                  }
                })}
              </Row>
            </Form>
          </Col>

          <Col xs={24}>
            <Space>
              <Button type="primary" onClick={form.submit}>
                Apply
              </Button>
              <Button onClick={handleReset}>Reset</Button>
            </Space>
          </Col>
        </Row>
      </motion.div>
    );
  };

  return {
    filtersQuery,
    FilterButton: renderFilterButton(),
    Filters: renderFilterItems(),
  };
}
