import { Modal, Form, Input } from "antd";

interface CancelRCModalProps {
  visible: boolean;
  onCancel: () => void;
  onConfirm: (reason: string) => void;
}

export const CancelRCModal: React.FC<CancelRCModalProps> = ({
  visible,
  onCancel,
  onConfirm,
}) => {
  const [form] = Form.useForm();

  const handleOk = () => {
    form.validateFields().then((values) => {
      onConfirm(values.reason);
      form.resetFields();
    });
  };

  return (
    <Modal
      title="Cancel Case"
      open={visible}
      onCancel={onCancel}
      onOk={handleOk}
      okText="Confirm Cancellation"
      cancelText="Back"
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="Cancellation Reason"
          name="reason"
          rules={[
            { required: true, message: "Please enter cancellation reason" },
            { max: 255, message: "Reason must be under 255 characters" },
          ]}
        >
          <Input.TextArea rows={4} placeholder="Enter cancellation reason..." />
        </Form.Item>
      </Form>
    </Modal>
  );
};
