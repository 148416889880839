import { Button, Col, message, Row, Space, Table, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { KeyOutlined, PlusCircleOutlined } from "@ant-design/icons";
import CreateUserDialog from "./CreateUser";
import { motion } from "framer-motion";
import {
  useUsersServiceDeleteUser,
  useUsersServiceGetUsers,
  useUsersServiceGetUsersKey,
} from "api/queries";
import { TableActions } from "components/common";
import QueryString from "qs";
import EditUserDialog from "./EditUser";
import { useAbility } from "@casl/react";
import { AbilityContext } from "utils/abilit";
import { SubjectKey, PermissionType } from "utils/enums";
import useFilters, { FilterTemplate, FilterType } from "hooks/UseFilter";
import { useQueryClient } from "@tanstack/react-query";
import ResetUserPasswordModal from "./ResetUserPasswordModal";

export default function UserListing() {
  const [editUserDialogOpen, setEditUserDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [sortQuery, setSortQuery] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [createUserModalOpen, setCreateUserModalOpen] = useState(false);
  const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);
  const [selectedUserForPasswordReset, setSelectedUserForPasswordReset] =
    useState<any>(null);
  const queryClient = useQueryClient();

  // Use CASL ability context to check permissions
  const ability = useAbility(AbilityContext);

  const filtersDef: any[] = [
    {
      key: "userName",
      label: "User Name",
      type: FilterType.Text,
    },
    {
      key: "userFullName",
      label: "User Full Name",
      type: FilterType.Text,
    },
    {
      key: "role",
      label: "Role",
      type: FilterType.template,
      template: FilterTemplate.RoleSelect,
    },
    {
      key: "isActive",
      label: "Show Inactive Users",
      type: FilterType.Select,
      options: [
        { key: "true", label: "Yes", value: false },
        { key: "false", label: "No", value: true },
      ],
    },
  ];

  const { filtersQuery, FilterButton, Filters } = useFilters(filtersDef);

  const { data, isLoading, isRefetching, refetch } = useUsersServiceGetUsers(
    {
      filters: filtersQuery,
      pagination: QueryString.stringify({
        offset: (page - 1) * limit,
        limit,
      }),
      sort: sortQuery,
    },
    [useUsersServiceGetUsersKey]
  );

  const { mutate: deleteUserMutation, isPending: isDeletePending } =
    useUsersServiceDeleteUser({
      onSuccess: () => {
        message.success("User deleted successfully");
        refetch();
      },
      onError: (err: any) => {
        message.error(err?.body?.message || err?.message || err);
      },
    });

  const handleOpenEditUserModal = (user: any) => {
    setEditUserDialogOpen(true);
    setSelectedUser(user);
  };

  const handleCloseEditUserModal = () => {
    setEditUserDialogOpen(false);
  };

  const handleOpenResetPasswordModal = (user: any) => {
    setSelectedUserForPasswordReset(user);
    setResetPasswordModalOpen(true);
  };

  const handleCloseResetPasswordModal = () => {
    setSelectedUserForPasswordReset(null);
    setResetPasswordModalOpen(false);
  };

  const handleDeleteUser = async (id: string) => {
    await deleteUserMutation({
      userId: +id,
    });
    refetch();
  };

  const columns: ColumnsType = [
    {
      title: "User Name",
      dataIndex: "userName",
      key: "userName",
      width: "auto",
      sorter: true,
    },
    {
      title: "User Full Name",
      dataIndex: "userFullName",
      key: "userFullName",
      width: "auto",
      sorter: true,
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (text, record) => {
        // TODO: remove after role is implemented
        //@ts-ignore
        return record.role?.roleName;
      },
      width: "auto",
      sorter: true,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_r, record) => (
        <TableActions
          onEdit={
            ability.can(PermissionType.UPDATE, SubjectKey.USER)
              ? () => handleOpenEditUserModal(record)
              : undefined
          }
          onDeletePopConfirm={
            ability.can(PermissionType.DELETE, SubjectKey.USER)
              ? () => handleDeleteUser(record.idUser)
              : undefined
          }
          onDeletePopConfirmMessage="Are you sure?"
          onDeletePopConfirmDescription="This user will be deleted permanently"
          onDeleteLoading={isDeletePending}
          otherActions={[
            {
              icon: <KeyOutlined />,
              onClick: () => handleOpenResetPasswordModal(record),
              display: ability.can(PermissionType.UPDATE, SubjectKey.USER),
            },
          ]}
        />
      ),
      width: "auto",
    },
  ];

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (pagination && pagination.current && pagination.pageSize) {
      setPage(pagination.current || 1);
      setLimit(pagination.pageSize || 10);
    }

    if (sorter) {
      if (sorter.order) {
        const s: any = {};
        s[sorter.field] = sorter.order === "ascend" ? "asc" : "desc";
        setSortQuery(s);
      } else {
        setSortQuery("");
      }
    }
  };

  const handleCreateUserModalClose = (success?: boolean) => {
    setCreateUserModalOpen(false);
  };

  const handleCreateUserModalOpen = () => {
    if (ability.can(PermissionType.CREATE, SubjectKey.USER)) {
      setCreateUserModalOpen(true);
    } else {
      message.warning("You do not have permission to create users.");
    }
  };

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: [useUsersServiceGetUsersKey],
    });
  }, [page, limit, sortQuery, filtersQuery]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5, delay: 0 }}
      style={{ position: "relative", height: "100%" }}
    >
      <Row gutter={16} style={{ marginBottom: 20 }}>
        <Col
          xs={24}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography.Title level={2} style={{ margin: 0 }}>
            Users
          </Typography.Title>
          <Space>
            {FilterButton}
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              onClick={handleCreateUserModalOpen}
              disabled={!ability.can(PermissionType.CREATE, SubjectKey.USER)}
            >
              Add new
            </Button>
          </Space>
        </Col>

        <Col xs={24}>{Filters}</Col>

        <Col xs={24}>
          <Table
            columns={columns}
            dataSource={data?.items || []}
            loading={isLoading || isRefetching}
            rowKey={(item) => item.idProduct}
            key={"product-list"}
            pagination={{
              total: data?.total || 0,
              showSizeChanger: true,
              showQuickJumper: true,
              pageSizeOptions: ["10", "20", "30", "40", "50"],
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
            onChange={handleTableChange}
            scroll={{ x: "max-content" }}
          />
        </Col>
      </Row>
      {ability.can(PermissionType.CREATE, SubjectKey.USER) && (
        <CreateUserDialog
          isOpen={createUserModalOpen}
          onClose={handleCreateUserModalClose}
          queryClient={queryClient}
        />
      )}
      <EditUserDialog
        isOpen={editUserDialogOpen}
        onClose={handleCloseEditUserModal}
        queryClient={queryClient}
        defaultValues={selectedUser}
      />

      <ResetUserPasswordModal
        isOpen={resetPasswordModalOpen}
        onClose={handleCloseResetPasswordModal}
        userId={selectedUserForPasswordReset?.idUser}
      />
    </motion.div>
  );
}
