import React, { useEffect } from "react";
import {
  Modal,
  Form,
  InputNumber,
  List,
  Typography,
  Row,
  Col,
  Tag,
  notification,
} from "antd";
import {
  useInventoryServiceGetBarcodesKey,
  useInventoryServiceRecordPrintedBarcodes,
} from "api/queries";
import { useQueryClient } from "@tanstack/react-query";

const { Title, Text } = Typography;
const { Item } = Form;

interface ProductVariant {
  [key: string]: string;
}

interface Product {
  brand: any;
  category: string;
  productBarcode: string;
  productCategory: any;
  productId: number;
  productName: string;
  productSKU: string;
  productSubcategory: any;
  subCategory: string;
  totalPrinted: number;
  totalQuantity: number;
  variants: ProductVariant[];
}

interface ConfirmPrintedItemsProps {
  products: Product[];
  open: boolean;
  onClose: () => void;
}

const ConfirmPrintedItems: React.FC<ConfirmPrintedItemsProps> = ({
  products,
  open,
  onClose,
}) => {
  const { mutate, isPending } = useInventoryServiceRecordPrintedBarcodes();
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const getUnprintedCount = (
    totalPrinted: number | null | undefined,
    totalQuantity: number | null | undefined
  ) => {
    let a = totalQuantity || 0;
    let b = totalPrinted || 0;

    return a - b;
  };

  const initialValues = products.reduce((acc, product) => {
    acc[product.productBarcode] = getUnprintedCount(
      product.totalPrinted,
      product.totalQuantity
    );
    return acc;
  }, {} as Record<string, number>);

  const handleOk = (values: any) => {
    const formData = products.map((product) => ({
      barcode: product.productBarcode,
      quantityPrinted: values[product.productBarcode],
    }));

    mutate(
      { requestBody: formData },
      {
        onSuccess: () => {
          notification.success({
            description: "Successfully printed items",
            type: "success",
            message: "Printed Items",
          });
          queryClient.invalidateQueries({
            queryKey: [useInventoryServiceGetBarcodesKey],
          });
          onClose();
        },
        onError: (err: any) => {
          notification.error({
            description:
              err?.body?.message ||
              err?.response?.data?.message ||
              err?.message,
            type: "error",
            message: "Failed to create expense",
          });
        },
      }
    );
  };

  useEffect(() => {
    if (!open) {
      form.resetFields();
    } else {
      form.setFieldsValue(initialValues);
    }
  }, [open]);

  return (
    <Modal
      title={<Title level={3}>Confirm Printed Items</Title>}
      open={open}
      onOk={form.submit}
      okText="Submit"
      closable={false}
      width={1000}
      okButtonProps={{ loading: isPending }}
      cancelButtonProps={{ style: { display: "none" } }}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleOk}
        initialValues={initialValues}
        disabled={isPending}
      >
        <List
          dataSource={products}
          renderItem={(product: any) => (
            <List.Item key={product.idProduct}>
              <Row style={{ width: "100%" }}>
                <Col span={18}>
                  <List.Item.Meta
                    title={
                      <Text strong>
                        {product.brand.brandName} - {product.productName}
                      </Text>
                    }
                    description={
                      <>
                        <Text type="secondary">
                          {product.productCategory.productCategoryName} -{" "}
                          {product.productSubcategory.productCategoryName} -{" "}
                          {product.productBarcode}
                        </Text>
                        {Object.keys(product.productVariantValues).length >
                          0 && (
                          <div style={{ marginTop: 8 }}>
                            {Object.entries(product.productVariantValues)
                              .filter(
                                (v: any) =>
                                  v?.productVariantValue?.productVariantType
                                    ?.name && v?.productVariantValue?.name
                              )
                              .map((v: any, idx: number) => {
                                return (
                                  <Tag key={"var-" + idx}>
                                    {
                                      v?.productVariantValue?.productVariantType
                                        ?.name
                                    }
                                    : {v?.productVariantValue?.name}
                                  </Tag>
                                );
                              })}
                          </div>
                        )}
                      </>
                    }
                  />
                </Col>
                <Col
                  span={6}
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <Item
                    name={product.productBarcode}
                    style={{ marginBottom: 0 }}
                    rules={[
                      { required: true, message: "Please input quantity" },
                      {
                        type: "number",
                        max: getUnprintedCount(
                          product.totalPrinted,
                          product.totalQuantity
                        ),
                        message: `Quantity cannot exceed ${getUnprintedCount(
                          product.totalPrinted,
                          product.totalQuantity
                        )}`,
                      },
                    ]}
                  >
                    <InputNumber
                      min={0}
                      max={getUnprintedCount(
                        product.totalPrinted,
                        product.totalQuantity
                      )}
                      style={{ width: "100px", marginBottom: 0 }}
                    />
                  </Item>
                  <Text type="secondary">
                    <span style={{ marginLeft: "5px", marginRight: "5px" }}>
                      /
                    </span>
                    {getUnprintedCount(
                      product.totalPrinted,
                      product.totalQuantity
                    )}
                  </Text>
                </Col>
              </Row>
            </List.Item>
          )}
        />
      </Form>
    </Modal>
  );
};

export default ConfirmPrintedItems;
